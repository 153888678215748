import { MFA_SMS_CODE_LENGTH } from "constants/mfa";
import { ESMSWidgetSteps } from "components/MultiFactorAuthorization/components/sms/types";
import { MFAWidgetType } from "components/MultiFactorAuthorization/types";

export const translations = {
  "en-US": {
    common: {
      remove_factor_modal: {
        title: "Are you sure?",
        message:
          "You are about to remove 2FA Method. Removing this method will reduce the security of your account.",
        confirm_button: "Remove 2FA Method",
      },
      verification_modal: {
        title: "Enter Verification Code",
        message: "Please enter one-time code sent to {{phone}}",
        confirm_button: "Verify Code",
      },
      add_factor_sucess_modal: {
        title: "Success!",
        message: "You succesfully activated two-factor authentication by SMS/Text Message",
      },
      add_factor_sucess_modal_set_default: {
        title: "Success!",
        message:
          "You succesfully activated two-factor authentication by SMS/Text Message. Do you want to set it as default?",
        confirm_button: "Set as Default",
      },
    },
    sms: {
      steps: {
        [ESMSWidgetSteps.ENTER_PHONE]: {
          title: "SMS/Text Message",
          description: "Please enter your phone number and we will send you a temporary code.",
          phone_input: {
            label: "Your Phone Number",
            placeholder: "Your Phone Number",
          },
          confirm_button: {
            [MFAWidgetType.SETUP]: "Confirm Phone Number",
            [MFAWidgetType.SETTINGS]: "Continue",
          },
        },
        [ESMSWidgetSteps.VERIFY_CODE]: {
          title: "SMS/Text Message",
          description: "Please verify the code sent to your phone",
          phone_number_label: "Phone Number:",
          code_verification_input: {
            label: "Confirm Verification Code",
            placeholder: `Enter ${MFA_SMS_CODE_LENGTH}-digit code here`,
            errors: {
              required: "Verification code is required",
              wrong_length: `Verification code must be ${MFA_SMS_CODE_LENGTH} symbols long`,
            },
          },
          confirm_button: {
            [MFAWidgetType.SETUP]: "Confirm Code",
            [MFAWidgetType.SETTINGS]: "Confirm Code",
          },
          resend_code_label_default: "Didn't receive the code? <1>Resend</1>",
          resend_code_label_counting:
            "Didn't receive the code? <1>Resend in {{remainingSeconds}} seconds</1>",
        },
        [ESMSWidgetSteps.SUCCESS]: {
          title: "Success!",
          description: "You succesfully activated two-factor authentication by SMS/Text Message",
          description_set_default:
            "You succesfully activated two-factor authentication by SMS/Text Message. Do you want to set it as default?",
          set_default_button: "Set as Default",
        },
      },
    },
    app: {},
  },
};

export default translations;

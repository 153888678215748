import { FC } from "react";
import { Trans } from "react-i18next";
import { DownloadIcon, EmptyInboxIcon } from "assets/svg";
import moment from "moment";

import { Loader } from "uikit";

import { MonthlyStatementResponse } from "utils/swagger_react_query";

import {
  Container,
  DownloadButton,
  HeaderCellText,
  HeaderRow,
  List,
  ListHeader,
  NewMark,
  Row,
  RowCell,
  RowCellText,
  StyledEmptyList,
  StyledLoaderContainer,
} from "./styles";

interface IProps {
  data: MonthlyStatementResponse[];
  isLoading?: boolean;
  onDownloadClick: (id: string) => void;
}

const NEW_MARK_DISPLAY_DAYS = 7;

export const StatementsList: FC<IProps> = ({ data, isLoading, onDownloadClick }) => {
  const translationPrefix = `banking_pages.shared.monthly_statements_page`;

  const isStatementNew = (statement: MonthlyStatementResponse) => {
    const statementDate = moment(statement.createdAt);
    const currentDate = moment();
    const diffDays = currentDate.diff(statementDate, "days");
    return diffDays <= NEW_MARK_DISPLAY_DAYS;
  };

  const renderNewMark = () => (
    <NewMark>
      <Trans i18nKey={`${translationPrefix}.new_mark`} />
    </NewMark>
  );

  const renderLoader = () => (
    <StyledLoaderContainer>
      <Loader />
    </StyledLoaderContainer>
  );

  const renderEmptyList = () => (
    <StyledEmptyList
      listName={"monthly_statements"}
      icon={<EmptyInboxIcon />}
      title={<Trans i18nKey={`${translationPrefix}.empty_state.title`} />}
      message={<Trans i18nKey={`${translationPrefix}.empty_state.message`} />}
    />
  );

  const renderList = () => (
    <List>
      {data.map((statement) => (
        <Row key={statement.monthlyStatementId}>
          <RowCell>
            <RowCellText>{statement.name}</RowCellText>
            {isStatementNew(statement) && renderNewMark()}
          </RowCell>
          <RowCell>
            <DownloadButton onClick={() => onDownloadClick(statement.monthlyStatementId)}>
              <Trans i18nKey={`buttons.download`} />
              <DownloadIcon />
            </DownloadButton>
          </RowCell>
        </Row>
      ))}
    </List>
  );

  const renderContent = () => {
    if (isLoading) {
      return renderLoader();
    }
    if (!data.length) {
      return renderEmptyList();
    }
    return renderList();
  };

  return (
    <Container>
      <ListHeader>
        <HeaderRow>
          <RowCell>
            <HeaderCellText>
              <Trans i18nKey={`${translationPrefix}.columns.statement_period`} />
            </HeaderCellText>
          </RowCell>
        </HeaderRow>
      </ListHeader>

      {renderContent()}
    </Container>
  );
};

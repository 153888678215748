export const MAX_INPUT_LENGTH = 100;
export const MAX_INPUT_LENGTH_LONG = 255;
export const MAX_TEXTAREA_LENGTH = 2000;
export const BONUS_MAX_PERIOD_MONTHS = 120;
export const BONUS_MAX_GOAL_QUANTITY = 999;
export const BONUS_MAX_MILESTONES_QUANTITY = 100;
export const BONUS_MIN_GOAL_AMOUNT = 0.01;
export const MAX_NUMBER_AMOUNT = 999999999.99;
export const MAX_PERCENTAGE_AMOUNT = 100;
export const SSN_LENGTH = 9;
export const SSN_LENGTH_WITH_MASK = 11;
export const EIN_LENGTH = 9;
export const EIN_LENGTH_WITH_MASK = 10;
export const ZIP_CODE_LENGTH = 5;

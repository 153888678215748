import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { ArrowSplit, CloseRoundIconAlt, DollarIconCircled, EditIcon, PieChart } from "assets/svg";
import { useFormikContext } from "formik";
import { cloneDeep, isEqual, omitBy } from "lodash";
import routes from "routes/routes";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { AppEvents } from "services/events";
import { IMappedBankAccount } from "types/BankAccounts";
import { PayDistributionRuleType, PayDistributionType } from "types/BETypes";
import SelectOption from "types/SelectOption";
import { MAX_PERCENTAGE_AMOUNT } from "constants/form";
import { getFieldError, getFormattedNumber, NumberType, showErrorModal } from "helpers";
import { isKycAccepted } from "helpers/shared/kyc";
import useAuth from "hooks/useAuth";
import { IEmployeeBankAccountsPageLocationState } from "pages/Settings/employee/EmployeeBankAccountsPage";
import { BankAccountSelect } from "components/BankAccountComponents";
import { ExternalBankAccountInfoRowColumn } from "components/BankAccountComponents/BankAccountInfoRows/types";
import { convertFormDataToServerPayDistributionData } from "components/PayDistribution/helpers";

import {
  FormLabel,
  FormLabelLarge,
  MoneyInput,
  PercentInput,
  Select,
  Switch,
  TitleWrapper,
} from "uikit";
import Modal, { EButtonsAlign, EButtonsFlex, EModalTypes } from "uikit/Modal";

import {
  mutationUsersControllerUpdateDefaultPayDistribution,
  UpdateDefaultPayDistributionReqDto,
} from "utils/swagger_react_query";

import { BackButton, ConfirmPayDistributionButton } from "../../styles";
import { EWidgetType } from "../../types";
import useCommonModals from "../../useCommonModals";
import {
  MAX_MONETARY_AMOUNT,
  MAX_SPLIT_PAYMENTS_BANK_ACCOUNTS,
  MIN_MONETARY_AMOUNT,
  MIN_PERCENTAGE_AMOUNT,
} from "./constants";
import { IEarlyPayChangeAccountModal, IWidgetPropsInner } from "./types";
import { BankItemType, FormType } from "./validationSchema";
import {
  BACItem,
  BACItemDeleteButton,
  BACItemEarlyPayContainerDisabled,
  BACItemEarlyPayContainerEnabled,
  BACItemInputsContainer,
  BACItemTopButtons,
  BACItemValueLabel,
  BACItemValueLabelContainer,
  BankInputContainer,
  DistributionRuleTypeInputContainer,
  DistributionValueInputContainer,
  EarlyPayChangeAccountModalBankAccountsSelectContainer,
  EarlyPayContainer,
  EarlyPayLabel,
  EarlyPayLabelContainer,
  EarlyPayLabelDescription,
  EarlyPaySwitchContainer,
  EditCancelButton,
  EditItemsButton,
  EditSaveButton,
  SplitButton,
  SplitButtonContainer,
  StyledExternalBankAccountInfoRow,
} from "./styles";

//----------------------------------------------------------------Bank Accounts Form-------------------------------------------------------------------------//

export const useBankAccountsForm = (props: IWidgetPropsInner) => {
  //Constants and external hooks
  const translationPrefix = `components.pay_distribution.bank_accounts_form`;
  const {
    initialFormData,
    bankAccounts,
    widgetType,
    onSaveSuccess,
    onSaveError,
    onBack,
    onNoAccountsModalConfirm,
    onBankAccountAdd,
    onConfirmPayDistribution,
  } = props;

  const currentUser = useAppSelector(userMetadataSelector);
  const firstPayDistributionMethod = currentUser?.defaultPayDistribution?.[0];
  const { t } = useTranslation();
  const { showNoAccountsModal, showNavigationWarningModal } = useCommonModals();
  const {
    showPayDistributionSettingsSaveSuccessModal,
    showPayDistributionOnboardingSaveSuccessModal,
  } = useCommonModals();
  const { getCurrentUser } = useAuth();
  const navigate = useNavigate();

  const formikContext = useFormikContext<FormType>();
  const { values, setValues, setFieldValue, handleBlur, validateForm, validateOnChange } =
    formikContext;

  const splitIsDisabled =
    (values.payDistributionRules?.length || 0) >= MAX_SPLIT_PAYMENTS_BANK_ACCOUNTS;

  const payDistributionRuleTypeOptions: SelectOption<PayDistributionRuleType>[] = [
    {
      label: t(`${translationPrefix}.pay_rule_options.${PayDistributionRuleType.PERCENTAGE}`),
      value: PayDistributionRuleType.PERCENTAGE,
    },
    {
      label: t(`${translationPrefix}.pay_rule_options.${PayDistributionRuleType.FIXED_AMOUNT}`),
      value: PayDistributionRuleType.FIXED_AMOUNT,
    },
  ];

  const payDistributionRuleTypeOptionsForEarlyPayCompanyAccount: SelectOption<PayDistributionRuleType>[] =
    [
      {
        label: t(`${translationPrefix}.pay_rule_options.${PayDistributionRuleType.PERCENTAGE}`),
        value: PayDistributionRuleType.PERCENTAGE,
      },
    ];

  //States
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const checkOverride = useRef<boolean>(false); //This ducttape helps us avoid routing checks when redirecting after setup
  const [showAddBankAccountModal, setShowAddBankAccountModal] = useState<boolean>(false);
  const [dataBeforeEdit, setDataBeforeEdit] = useState<FormType | undefined>(undefined);
  const [earlyPayChangeAccountModalData, setEarlyPayChangeAccountModalData] =
    useState<IEarlyPayChangeAccountModal>({
      isOpen: false,
      bankAccount: undefined,
      callback: undefined,
    });

  const [availableBankAccounts, setAvailableBankAccounts] = useState<
    IMappedBankAccount[] | undefined
  >(undefined);

  const lastSavedData = initialFormData;

  const redirectToBankAccountsList = (openAccountAddModalOnRedirect = false) => {
    const state: IEmployeeBankAccountsPageLocationState = {
      shouldOpenPlaidModalOnMount: true,
    };
    navigate(routes.EMPLOYEE_BANK_ACCOUNTS, {
      state: openAccountAddModalOnRedirect ? state : undefined,
    });
  };

  const redirectToBankingKyc = () => {
    const state: IEmployeeBankAccountsPageLocationState = {
      shouldOpenCompanyAccountModalOnMount: true,
    };
    navigate(routes.EMPLOYEE_BANK_ACCOUNTS, { state });
  };

  const redirectToSettings = () => {
    navigate(routes.EMPLOYEE_SETTINGS);
  };

  const getBankAccountTitleTranslationString = (index: number) => {
    //If this is the last one
    if (values.payDistributionRules?.length === 1) {
      return `payment_order_full`;
    }
    //If this is the only one
    else if (values.payDistributionRules?.length === index + 1) {
      return `payment_order_remaining`;
    } else {
      return `payment_order.${index}`;
    }
  };

  const getBankAccountTitle = (index: number) => {
    const translationString = getBankAccountTitleTranslationString(index);
    return <Trans i18nKey={`${translationPrefix}.${translationString}`} />;
  };

  const convertBankAccountToFormBankAccount = (item: IMappedBankAccount): BankItemType => {
    return {
      id: item.bankAccountId || "",
      ruleType: PayDistributionRuleType.PERCENTAGE,
      type: item._accountType,
      amount: undefined,
    };
  };

  const openEditMode = () => {
    setDataBeforeEdit(values);
    setIsInEditMode(true);
  };

  const showEarlyPayActivateModal = (callback?: () => void) => {
    const _translationPrefix = `${translationPrefix}.early_pay.modals.activate_early_pay`;

    AppEvents.emit("SetGlobalModal", {
      className: "early-pay-modal-activate",
      isOpen: true,
      type: EModalTypes.WARNING,
      title: <Trans i18nKey={`${_translationPrefix}.title`} />,
      message: <Trans i18nKey={`${_translationPrefix}.message`} />,
      mainButton: {
        text: <Trans i18nKey={`common.modal.continue`} />,
        onClick: callback,
        dataTestId: "early-pay-modal-activate-confirm-button",
        autoWidth: true,
      },
      secondaryButton: {
        text: <Trans i18nKey={`common.modal.cancel`} />,
        dataTestId: "early-pay-modal-activate-cancel-button",
        autoWidth: true,
      },
      buttonsFlex: EButtonsFlex.ROW_REVERSE,
      dataTestId: `bank-accounts-form-early-pay-activate-modal`,
    });
  };

  const showEarlyPayRemoveCompanyAccountDuplicatesModal = () => {
    const _translationPrefix = `${translationPrefix}.early_pay.modals.resolve_company_account_duplicates`;

    AppEvents.emit("SetGlobalModal", {
      className: "early-pay-modal-resolve-company-account-duplicates",
      isOpen: true,
      type: EModalTypes.WARNING,
      title: <Trans i18nKey={`${_translationPrefix}.title`} />,
      message: <Trans i18nKey={`${_translationPrefix}.message`} />,
      mainButton: {
        text: <Trans i18nKey={`common.modal.done`} />,
        dataTestId: "early-pay-modal-resolve-company-account-duplicates-confirm-button",
      },
      buttonsFlex: EButtonsFlex.ROW,
      buttonsAlign: EButtonsAlign.CENTER,
      dataTestId: `bank-accounts-form-early-pay-remove-company-account-duplicates-modal`,
    });
  };

  const renderEarlyPayChangeBankAccountModal = () => {
    //Need to use render here, because AppEvents don't support rerendering
    const _translationPrefix = `${translationPrefix}.early_pay.modals.change_bank_account`;
    const _bankAccounts = (bankAccounts || []).filter(
      (item) => (item as IMappedBankAccount)._accountType !== PayDistributionType.PARTNER_ACCOUNT,
    );

    return (
      <Modal
        className={"early-pay-modal-change-bank-account"}
        isOpen={earlyPayChangeAccountModalData.isOpen}
        type={EModalTypes.WARNING}
        title={<Trans i18nKey={`${_translationPrefix}.title`} />}
        message={
          <>
            <Trans i18nKey={`${_translationPrefix}.message`} />
            <EarlyPayChangeAccountModalBankAccountsSelectContainer>
              <FormLabel>
                <Trans i18nKey={`${_translationPrefix}.select_label`} />
              </FormLabel>
              <BankAccountSelect
                dataTestId={"early-pay-modal-change-bank-account-select"}
                bankAccountsList={_bankAccounts || []}
                onChange={(data) => {
                  const item = convertBankAccountToFormBankAccount(data);
                  setEarlyPayChangeAccountModalData({
                    ...earlyPayChangeAccountModalData,
                    bankAccount: item,
                  });
                }}
                selectedBankAccount={_bankAccounts.find(
                  (item) => item.bankAccountId === earlyPayChangeAccountModalData.bankAccount?.id,
                )}
                showAddAccountButton={false}
              />
            </EarlyPayChangeAccountModalBankAccountsSelectContainer>
          </>
        }
        mainButton={{
          text: <Trans i18nKey={`common.modal.continue`} />,
          onClick: () => {
            if (!earlyPayChangeAccountModalData.bankAccount) return;
            earlyPayChangeAccountModalData.callback?.(
              cloneDeep(earlyPayChangeAccountModalData.bankAccount),
            );
          },
          dataTestId: "early-pay-modal-change-bank-account-confirm-button",
          disabled: !earlyPayChangeAccountModalData.bankAccount,
          autoWidth: true,
        }}
        secondaryButton={{
          text: <Trans i18nKey={`common.modal.cancel`} />,
          dataTestId: "early-pay-modal-change-bank-account-cancel-button",
          autoWidth: true,
        }}
        buttonsFlex={EButtonsFlex.ROW_REVERSE}
        onClose={() => {
          setEarlyPayChangeAccountModalData({
            isOpen: false,
            bankAccount: undefined,
            callback: undefined,
          });
        }}
        dataTestId={`bank-accounts-form-early-pay-change-bank-account-modal`}
      />
    );
  };

  const getSwapAccountsWithFirst = (index: number, bankAccountData?: Partial<BankItemType>) => {
    const array = cloneDeep(values.payDistributionRules || []);
    const firstItem = cloneDeep(values.payDistributionRules?.[0]);
    const swappingItem = cloneDeep(values.payDistributionRules?.[index]);
    const isLastItemSwapped = index === array.length - 1;

    if (!swappingItem || !firstItem || index < 0) {
      return;
    }

    array[0] = {
      ...swappingItem,
      amount: isLastItemSwapped ? 0 : swappingItem.amount,
      ruleType: isLastItemSwapped ? PayDistributionRuleType.PERCENTAGE : swappingItem.ruleType,
      ...(bankAccountData || {}),
    };

    array[index] = {
      ...firstItem,
      ruleType: isLastItemSwapped ? PayDistributionRuleType.REMAINDER : firstItem.ruleType,
      amount: isLastItemSwapped ? undefined : firstItem.amount,
    };

    return array;
  };

  const handleSwapEarlyPayAccountWithFirstAccount = (
    index: number,
    bankAccountData?: Partial<BankItemType>,
  ) => {
    const result = getSwapAccountsWithFirst(index, {
      ...(bankAccountData || {}),
      amount: 0,
      ruleType: PayDistributionRuleType.PERCENTAGE,
    });

    if (result?.[0]) {
      //
      result[0] = {
        ...result[0],
        ruleType: PayDistributionRuleType.PERCENTAGE,
        amount: 0,
      };
    }

    setValues({
      ...values,
      payDistributionRules: result,
      isEarlyPayEnabled: true,
    });
  };

  const handleSelectExternalAccountInsteadOfEarlyPay = (
    bankAccount: BankItemType,
    index: number,
  ) => {
    if (!bankAccount) return;
    const result = getSwapAccountsWithFirst(index, {
      id: bankAccount.id,
      amount: 0,
      ruleType: PayDistributionRuleType.PERCENTAGE,
    });

    setValues({
      ...values,
      payDistributionRules: result,
      isEarlyPayEnabled: false,
    });
  };

  const toggleEarlyPay = (index: number) => {
    const currentValue = !!values.isEarlyPayEnabled;
    //Switching off
    if (currentValue) {
      setFieldValue("isEarlyPayEnabled", !currentValue);
    } else {
      //Switching on
      const companyAccountsCount = (values.payDistributionRules || []).filter(
        (item) => item.type === PayDistributionType.PARTNER_ACCOUNT,
      ).length;

      if (companyAccountsCount >= 2) {
        //If we have duplicates of the company account
        showEarlyPayRemoveCompanyAccountDuplicatesModal();
      } else if (index !== 0) {
        //If company account with toggled early pay is not the first in list
        showEarlyPayActivateModal(() => handleSwapEarlyPayAccountWithFirstAccount(index));
      } else {
        const companyAccountElement = values.payDistributionRules?.[0];
        if (
          companyAccountElement?.type === PayDistributionType.PARTNER_ACCOUNT &&
          (values.payDistributionRules?.length || 1) > 1
        ) {
          const _payDistributionRules = cloneDeep(values.payDistributionRules);
          if (_payDistributionRules?.[0]) {
            _payDistributionRules[0] = {
              ..._payDistributionRules[0],
              ruleType: PayDistributionRuleType.PERCENTAGE,
              amount: 0,
            };
          }
          setFieldValue("payDistributionRules", _payDistributionRules);
        }
        setFieldValue("isEarlyPayEnabled", !currentValue);
      }
    }
  };

  const handleAddSplitPayment = () => {
    if (splitIsDisabled) return;

    if ((values.payDistributionRules?.length || 0) >= (bankAccounts?.length || 0)) {
      showNoAccountsModal(onNoAccountsModalConfirm || redirectToBankAccountsList);
      return;
    }

    if (!isInEditMode) openEditMode();

    const newItem = {
      id: "",
      ruleType: PayDistributionRuleType.REMAINDER,
      type: PayDistributionType.EXTERNAL_ACCOUNT,
      amount: undefined,
    };

    const oldArray = JSON.parse(JSON.stringify(values.payDistributionRules || []));
    const lastElementInOldArray = oldArray?.[oldArray.length - 1];
    if (lastElementInOldArray) {
      oldArray[oldArray.length - 1] = {
        ...lastElementInOldArray,
        ruleType: PayDistributionRuleType.PERCENTAGE,
      };
    }

    setValues({
      ...values,
      payDistributionRules: [...oldArray, newItem],
    });
  };

  const handleBankAccountChange = (data: IMappedBankAccount, index: number) => {
    const oldData = values.payDistributionRules?.[index];
    const newItem = convertBankAccountToFormBankAccount(data);

    if (oldData?.id === newItem.id) return;

    const companyAccount = values.payDistributionRules?.find(
      (item) => item.type === PayDistributionType.PARTNER_ACCOUNT,
    );

    const isAddingCompanyAccountWithEarlyPay =
      newItem.type === PayDistributionType.PARTNER_ACCOUNT && !!values.isEarlyPayEnabled;

    if (isAddingCompanyAccountWithEarlyPay) {
      if (!companyAccount && index !== 0) {
        showEarlyPayActivateModal(() => handleSwapEarlyPayAccountWithFirstAccount(index, newItem));
        return;
      }
    }

    if (
      newItem.type === PayDistributionType.PARTNER_ACCOUNT &&
      !!companyAccount &&
      !!values.isEarlyPayEnabled
    ) {
      setEarlyPayChangeAccountModalData({
        ...earlyPayChangeAccountModalData,
        isOpen: true,
        callback: (bankAccount) => {
          handleSelectExternalAccountInsteadOfEarlyPay(bankAccount, index);
        },
      });
      return;
    }

    const isTheOnlyItemInArray = values.payDistributionRules?.length === 1;
    const ruleAndAmount =
      isAddingCompanyAccountWithEarlyPay && index === 0
        ? {
            ruleType: isTheOnlyItemInArray
              ? PayDistributionRuleType.REMAINDER
              : PayDistributionRuleType.PERCENTAGE,
            amount: isTheOnlyItemInArray ? undefined : 0,
          }
        : {
            ruleType: oldData?.ruleType,
            amount: oldData?.amount,
          };

    setFieldValue(
      `payDistributionRules.${index}`,
      {
        ...newItem,
        ...ruleAndAmount,
      },
      validateOnChange,
    );
  };

  const handleBankAccountAdd = () => {
    if (onBankAccountAdd) {
      onBankAccountAdd?.();
    } else if (isKycAccepted(currentUser)) {
      redirectToBankAccountsList(true);
    } else {
      setShowAddBankAccountModal(true);
    }
  };

  const handleBankAccountAddModalContinue = (type: PayDistributionType) => {
    if (type === PayDistributionType.PARTNER_ACCOUNT) {
      redirectToBankingKyc();
    }
    if (type === PayDistributionType.EXTERNAL_ACCOUNT) {
      redirectToBankAccountsList(true);
    }
  };

  const handleBankAccountAddModalCancel = () => {
    setShowAddBankAccountModal(false);
  };

  const handleBankAccountRuleChange = (value: PayDistributionRuleType, index: number) => {
    setFieldValue(`payDistributionRules.${index}.ruleType`, value, validateOnChange);
    setFieldValue(`payDistributionRules.${index}.amount`, undefined, validateOnChange);
  };

  const handleBankAccountAmountChange = (value: string, index: number) => {
    setFieldValue(
      `payDistributionRules.${index}.amount`,
      Math.min(parseInt(value.toString().replace(/\D/, "") || "0", 10), MAX_MONETARY_AMOUNT),
      validateOnChange,
    );
  };

  const handleBankAccountPercentAmountChange = (value: string, index: number) => {
    setFieldValue(
      `payDistributionRules.${index}.amount`,
      Math.min(parseInt(value.toString().replace(/\D/, "") || "0", 10), MAX_PERCENTAGE_AMOUNT),
      validateOnChange,
    );
  };

  const handleBankAccountDelete = (index: number) => {
    const newArray = (values.payDistributionRules || []).filter((item, _index) => _index !== index);
    if (newArray.length) {
      newArray[newArray.length - 1] = {
        ...newArray[newArray.length - 1],
        amount: undefined,
        ruleType: PayDistributionRuleType.REMAINDER,
      };
    }
    setFieldValue(`payDistributionRules`, newArray, validateOnChange);
  };

  // View/Edit buttons
  const handleEditClick = () => {
    openEditMode();
  };

  const handleEditCancelClick = () => {
    setIsInEditMode(false);
    if (dataBeforeEdit) {
      setValues(dataBeforeEdit);
    }
  };

  const handleEditSaveClick = () => {
    validateForm(values).then((errors) => {
      const errorList = Object.values(errors);
      const hasErrors = !!errorList.length;

      if (!hasErrors) {
        setIsInEditMode(false);
        onSaveSuccess?.();
      } else {
        onSaveError?.();
      }
    });
  };

  const handleBack = async () => {
    if (onBack) {
      if (isDataUnsaved()) {
        showNavigationWarningModal(onBack);
      } else {
        onBack();
      }
    } else {
      //TODO: Got some problem with navigate(-1) and RouterPrompt, investigate later
      redirectToSettings();
      return;
    }
  };

  const refetchUserData = async () => {
    setLoading(true);
    const user = await getCurrentUser();
    setLoading(false);
    return user;
  };

  const handleSubmit = async () => {
    if (isInEditMode) return;

    try {
      setLoading(true);
      const data = convertFormDataToServerPayDistributionData(values.payDistributionRules || []);
      const payload: UpdateDefaultPayDistributionReqDto = {
        payDistributionRules: data,
      };
      if (data.find((item) => item.type === PayDistributionType.PARTNER_ACCOUNT)) {
        payload.isEarlyPayEnabled = !!values?.isEarlyPayEnabled;
      } else {
        payload.isEarlyPayEnabled = undefined;
      }

      await mutationUsersControllerUpdateDefaultPayDistribution()(payload);

      if (widgetType === EWidgetType.ONBOARDING) {
        checkOverride.current = true;
        showPayDistributionOnboardingSaveSuccessModal(async () => {
          await refetchUserData();
          onConfirmPayDistribution?.(values);
          navigate(routes.EMPLOYEE_PAY);
        });
      }
      if (widgetType === EWidgetType.SETTINGS) {
        await refetchUserData();
        onConfirmPayDistribution?.(values);
        showPayDistributionSettingsSaveSuccessModal();
      }
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  //

  const renderViewModeSectionButtons = () => {
    return (
      <>
        <EditItemsButton onClick={handleEditClick} data-testid="bank-accounts-form-edit-button">
          <EditIcon />
          <Trans i18nKey={`buttons.edit`} />
        </EditItemsButton>
      </>
    );
  };

  const renderEditModeSectionButtons = () => {
    return (
      <>
        <EditCancelButton
          onClick={handleEditCancelClick}
          data-testid="bank-accounts-form-cancel-button"
        >
          <Trans i18nKey={`buttons.cancel`} />
        </EditCancelButton>
        <EditSaveButton onClick={handleEditSaveClick} data-testid="bank-accounts-form-save-button">
          <Trans i18nKey={`buttons.save`} />
        </EditSaveButton>
      </>
    );
  };

  const renderSectionButtons = () => {
    return <>{isInEditMode ? renderEditModeSectionButtons() : renderViewModeSectionButtons()}</>;
  };

  const renderBankItemActionColumn = (data?: BankItemType) => {
    let text;
    let icon;
    switch (data?.ruleType) {
      case PayDistributionRuleType.PERCENTAGE:
        text = getFormattedNumber(data.amount || 0, NumberType.PERCENTAGE);
        icon = <PieChart />;
        break;
      case PayDistributionRuleType.FIXED_AMOUNT:
        text = data?.amount || 0;
        icon = <DollarIconCircled />;
        break;
      case PayDistributionRuleType.REMAINDER:
        text = <Trans i18nKey={`${translationPrefix}.remaining_label`} />;
        icon = <PieChart />;
        break;
      default:
        text = data?.amount || 0;
        icon = <PieChart />;
        break;
    }

    if (values?.payDistributionRules?.length === 1) {
      text = getFormattedNumber(100, NumberType.PERCENTAGE);
      icon = <PieChart />;
    }

    return (
      <BACItemValueLabelContainer>
        {isInEditMode ? (
          <></>
        ) : (
          <>
            {data?.type === PayDistributionType.PARTNER_ACCOUNT && (
              <>
                {values.isEarlyPayEnabled ? (
                  <BACItemEarlyPayContainerEnabled>
                    <Trans i18nKey={`${translationPrefix}.early_pay.account_card_enabled`} />
                  </BACItemEarlyPayContainerEnabled>
                ) : (
                  <BACItemEarlyPayContainerDisabled>
                    <Trans i18nKey={`${translationPrefix}.early_pay.account_card_disabled`} />
                  </BACItemEarlyPayContainerDisabled>
                )}
              </>
            )}
            <BACItemValueLabel>
              {icon}
              {text}
            </BACItemValueLabel>
          </>
        )}
      </BACItemValueLabelContainer>
    );
  };

  const renderBankAccountItem = (item: BankItemType, index: number) => {
    const selectedBankAccount = availableBankAccounts?.find(
      (_item) => _item.bankAccountId === item.id,
    );

    const ruleTypeOptions =
      item.type === PayDistributionType.PARTNER_ACCOUNT && !!values.isEarlyPayEnabled
        ? payDistributionRuleTypeOptionsForEarlyPayCompanyAccount
        : payDistributionRuleTypeOptions;

    const showDeleteButton = !!isInEditMode && !!index;

    const amountInputCommonProps = {
      name: `payDistributionRules.${index}.amount`,
      value: (values?.payDistributionRules?.[index]?.amount || "").toString(),
      error: getFieldError(`payDistributionRules.${index}.amount`, formikContext, {
        minPercentageAmount: MIN_PERCENTAGE_AMOUNT,
        maxPercentageAmount: MAX_PERCENTAGE_AMOUNT,
        minMonetaryAmount: MIN_MONETARY_AMOUNT,
        maxMonetaryAmount: MAX_MONETARY_AMOUNT,
      }),
      placeholder: "",
      onBlur: handleBlur,
    };

    let earlyPayId = "";
    if (item?.type === PayDistributionType.PARTNER_ACCOUNT) {
      earlyPayId = values.isEarlyPayEnabled
        ? "bac-item-early-pay-enabled"
        : "bac-item-early-pay-disabled";
    }

    return (
      <BACItem
        key={`${item.id}-${index}`}
        data-testid={`bac-item-${item.id} ${getBankAccountTitleTranslationString(index)} ${earlyPayId}`}
      >
        <TitleWrapper>
          <FormLabelLarge data-testid={`bank-accounts-form-title`}>
            {getBankAccountTitle(index)}
          </FormLabelLarge>
          {showDeleteButton && (
            <BACItemTopButtons>
              <BACItemDeleteButton
                onClick={() => handleBankAccountDelete(index)}
                data-testid="bank-accounts-form-delete-account-button"
              >
                <CloseRoundIconAlt />
              </BACItemDeleteButton>
            </BACItemTopButtons>
          )}
        </TitleWrapper>
        {!!isInEditMode && (
          <>
            <BACItemInputsContainer>
              <BankInputContainer>
                <FormLabel>
                  <Trans i18nKey={`${translationPrefix}.account_selection_label`} />
                </FormLabel>
                <BankAccountSelect
                  name={`payDistributionRules.${index}.id`}
                  bankAccountsList={bankAccounts || []}
                  onAddAccount={handleBankAccountAdd}
                  onChange={(data) => {
                    handleBankAccountChange(data, index);
                  }}
                  selectedBankAccount={selectedBankAccount}
                  error={getFieldError(`payDistributionRules.${index}.id`, formikContext)}
                />
              </BankInputContainer>

              {item.ruleType !== PayDistributionRuleType.REMAINDER ? (
                <>
                  <DistributionRuleTypeInputContainer>
                    <FormLabel></FormLabel>
                    <Select<SelectOption<PayDistributionRuleType>>
                      name={`payDistributionRules.${index}.ruleType`}
                      options={ruleTypeOptions}
                      onChange={(option) => {
                        handleBankAccountRuleChange(
                          (option as SelectOption<PayDistributionRuleType>).value,
                          index,
                        );
                      }}
                      value={ruleTypeOptions.find(
                        (item) => item.value === values?.payDistributionRules?.[index]?.ruleType,
                      )}
                      error={getFieldError(`payDistributionRules.${index}.ruleType`, formikContext)}
                    />
                  </DistributionRuleTypeInputContainer>

                  <DistributionValueInputContainer>
                    <FormLabel></FormLabel>
                    {values?.payDistributionRules?.[index]?.ruleType ===
                    PayDistributionRuleType.PERCENTAGE ? (
                      <PercentInput
                        {...amountInputCommonProps}
                        onChange={(val) => {
                          handleBankAccountPercentAmountChange(val as string, index);
                        }}
                        decimalSeparator={""} //Allowing only ints here
                        maxLength={MAX_PERCENTAGE_AMOUNT.toString().length}
                      />
                    ) : (
                      <MoneyInput
                        {...amountInputCommonProps}
                        onChange={(val) => {
                          handleBankAccountAmountChange(val as string, index);
                        }}
                        maxLength={parseInt(MAX_MONETARY_AMOUNT.toString(), 10).toString().length}
                      />
                    )}
                  </DistributionValueInputContainer>
                </>
              ) : (
                <>
                  {/* Basically, a ducttape to always align inputs correctly*/}
                  <DistributionRuleTypeInputContainer></DistributionRuleTypeInputContainer>
                  <DistributionValueInputContainer></DistributionValueInputContainer>
                </>
              )}
            </BACItemInputsContainer>

            {item.type === PayDistributionType.PARTNER_ACCOUNT && (
              <EarlyPayContainer>
                <EarlyPaySwitchContainer>
                  <Switch
                    checked={!!values.isEarlyPayEnabled}
                    onChange={() => {
                      toggleEarlyPay(index);
                    }}
                    data-testid={"early-pay-switcher"}
                  >
                    <EarlyPayLabelContainer>
                      <EarlyPayLabel
                        id={`bank_item_early_pay_tooltip_${index}`}
                        tooltipContent={
                          <Trans i18nKey={`${translationPrefix}.early_pay.tooltip`} />
                        }
                      >
                        <Trans i18nKey={`${translationPrefix}.early_pay.label`} />
                      </EarlyPayLabel>
                      <EarlyPayLabelDescription>
                        <Trans i18nKey={`${translationPrefix}.early_pay.description`} />
                      </EarlyPayLabelDescription>
                    </EarlyPayLabelContainer>
                  </Switch>
                </EarlyPaySwitchContainer>
              </EarlyPayContainer>
            )}
          </>
        )}

        {!!selectedBankAccount && (
          <StyledExternalBankAccountInfoRow
            bankAccountInfo={selectedBankAccount}
            displayedColumns={[
              ExternalBankAccountInfoRowColumn.NAME,
              ExternalBankAccountInfoRowColumn.ACCOUNT_NUMBER,
              ExternalBankAccountInfoRowColumn.TYPE,
              ExternalBankAccountInfoRowColumn.STATUS,
            ]}
            actionsColumn={renderBankItemActionColumn(item)}
          />
        )}
      </BACItem>
    );
  };

  const renderBankAccountItems = () => {
    return (
      <>{values.payDistributionRules?.map((item, index) => renderBankAccountItem(item, index))}</>
    );
  };

  const renderAdditionalButtons = () => {
    return (
      <SplitButtonContainer>
        <SplitButton
          onClick={handleAddSplitPayment}
          className={`${splitIsDisabled ? "disabled" : ""}`}
          data-testid="bank-accounts-form-split-button"
        >
          <ArrowSplit />
          <Trans i18nKey={`${translationPrefix}.split_pay_button`} />
        </SplitButton>
      </SplitButtonContainer>
    );
  };

  const renderBackButton = () => {
    return (
      <BackButton onClick={handleBack} data-testid="bank-account-form-back-button">
        <Trans i18nKey={`buttons.back`} />
      </BackButton>
    );
  };

  const renderSubmitButton = () => {
    return (
      <>
        {!!confirmButtonIsVisible && (
          <ConfirmPayDistributionButton
            onClick={handleSubmit}
            disabled={!!isInEditMode}
            data-testid="bank-account-form-confirm-pay-distribution-button"
          >
            <Trans i18nKey={`${translationPrefix}.submit_buttons.${widgetType}`} />
          </ConfirmPayDistributionButton>
        )}
      </>
    );
  };

  const omitUndefinedFields = (array: Array<any>) => {
    return array.map((arrItem) => {
      return omitBy(
        arrItem,
        (item, key) =>
          item === undefined ||
          (key === "id" && arrItem.type === PayDistributionType.PARTNER_ACCOUNT),
      );
    });
  };

  const isDataEqual = () => {
    const _lastSavedData = {
      ...lastSavedData,
      payDistributionRules: omitUndefinedFields(lastSavedData?.payDistributionRules || []),
    };

    const _values = {
      ...values,
      payDistributionRules: omitUndefinedFields(values.payDistributionRules || []),
    };
    const result = isEqual(_lastSavedData, _values);
    return result;
  };

  const isDataUnsaved = (): boolean => {
    if (checkOverride.current) return false;
    return !!isInEditMode || !isDataEqual();
  };

  const confirmButtonIsVisible =
    (firstPayDistributionMethod?.type !== PayDistributionType.EXTERNAL_ACCOUNT &&
      firstPayDistributionMethod?.type !== PayDistributionType.PARTNER_ACCOUNT) ||
    !isDataEqual();

  useEffect(() => {
    setAvailableBankAccounts(bankAccounts || []);
  }, [bankAccounts]);

  return {
    isLoading,
    values,
    currentUser,
    isInEditMode,
    confirmButtonIsVisible,
    showAddBankAccountModal,
    checkOverride,
    renderSectionButtons,
    renderBankAccountItems,
    renderAdditionalButtons,
    renderEarlyPayChangeBankAccountModal,
    renderBackButton,
    renderSubmitButton,
    isDataUnsaved,
    handleBankAccountAddModalContinue,
    handleBankAccountAddModalCancel,
  };
};

export default useBankAccountsForm;

import { Trans } from "react-i18next";
import { t } from "i18next";

import { UserRole } from "types/BETypes";

import { Step } from "uikit/StepIndicator/types";

export enum COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS {
  KYC_VERIFICATION = 1,
  EARLY_PAY = 2,
  SHIPPING_DETAILS = 3,
  MFA_CONFIGURATION = 4,
}
export const COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS_AMOUNT =
  Object.keys(COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS).length / 2;

export enum COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS {
  BUSINESS_INFO = 1,
  COMPANY_STRUCTURE = 2,
  SHIPPING_DETAILS = 3,
  MFA_CONFIGURATION = 4,
}
export const COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS_AMOUNT =
  Object.keys(COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS).length / 2;

export const FAILED_KYC_USER_DATA_LOCAL_STORAGE_KEY = "failedKycUserData";

export const getStepIndicators = (
  role: UserRole,
  translationPrefix: string,
  isMfaCompleted: boolean,
): Step[] => {
  const rolePrefix = role === UserRole.EMPLOYEE ? "employee" : "admin";
  const _prefixWithRole = `${translationPrefix}.${rolePrefix}`;
  const stepsByRole =
    role === UserRole.EMPLOYEE
      ? COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS
      : COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS;

  const stepsIndices = Object.values(stepsByRole).filter((value) => typeof value === "number");
  const steps = stepsIndices.map((stepIndex, elIndex) => {
    const step: Step = {
      index: stepIndex,
      label: t(`${_prefixWithRole}.${elIndex}.title`) as string,
    };
    if (
      stepIndex === COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS.MFA_CONFIGURATION &&
      isMfaCompleted
    ) {
      step.isCompleted = true;
    }

    return step;
  });

  return steps;
};

export const promoStepsDescriptionsList = (translationPrefix: string, role: UserRole) => {
  const amountOfSteps =
    role === UserRole.EMPLOYEE
      ? COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS_AMOUNT
      : COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS_AMOUNT;

  const steps = Array.from({ length: amountOfSteps }, (value, index) => {
    return {
      index: index + 1,
      title: <Trans i18nKey={`${translationPrefix}.${index}.title`} />,
      description: <Trans i18nKey={`${translationPrefix}.${index}.description`} />,
    };
  });

  return steps;
};

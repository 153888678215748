import { FC } from "react";

import BankAccountsPage from "pages/Settings/shared/BankAccountsPage";

import { useAdminBankAccountsPage } from "./useAdminBankAccountsPage";

const AdminBankAccountsPage: FC = () => {
  const { metadata, pageData, actions } = useAdminBankAccountsPage();

  return (
    <BankAccountsPage
      isLoading={metadata.isLoading}
      bankAccountSectionProps={{
        bankAccounts: pageData.bankAccounts || [],
        onConnectBankAccount: actions.openModal,
        onUpdateDefaultBankAccount: actions.handleSetDefaultBankAccount,
        onRemoveBankAccount: actions.handleRemoveBankAccount,
        onVerifyBankAccount: actions.handleVerifyBankAccount,
      }}
      handleBackBtnClick={() => actions.handleBackBtnClick()}
    />
  );
};

export default AdminBankAccountsPage;

import { useEffect, useState } from "react";
import { FormikProps } from "formik";

import { getErrorMessageFromResponse } from "helpers/shared/errors";

import {
  mutationBusinessBankAccountsControllerCreateBusinessBankAccount,
  queryCompaniesControllerBusinessInfo,
} from "utils/swagger_react_query";

import { BusinessAccountCompanyInfoFormType } from "./components";
import {
  convertBusinessFormDataToBusinessAccountData,
  getBusinessInfoFormDataInitValues,
} from "./helpers";
import { IUseBusinessInformationParams } from "./types";

type IHandleSubmitParams = Pick<
  FormikProps<BusinessAccountCompanyInfoFormType>,
  "values" | "validateForm"
>;

export const useBusinessInformation = ({ onSubmitSuccess }: IUseBusinessInformationParams) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [initValues, setInitValues] = useState<BusinessAccountCompanyInfoFormType | undefined>(
    undefined,
  );

  useEffect(() => {
    fetchBusinessInfoInitValues();
  }, []);

  const fetchBusinessInfoInitValues = async () => {
    try {
      setIsLoading(true);
      const response = await queryCompaniesControllerBusinessInfo();
      const serializedResponse = getBusinessInfoFormDataInitValues(response);

      setInitValues(serializedResponse);
    } catch (error) {
      setErrorMessage(getErrorMessageFromResponse(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async ({ values, validateForm }: IHandleSubmitParams) => {
    try {
      setIsSubmitting(true);
      const payload = convertBusinessFormDataToBusinessAccountData(values);

      await mutationBusinessBankAccountsControllerCreateBusinessBankAccount()(payload);

      onSubmitSuccess?.();
    } catch (error) {
      setErrorMessage(getErrorMessageFromResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    errorMessage,
    initValues,
    handleSubmit,
    isLoading,
  };
};

import { useMemo, useState } from "react";
import { FormikProps } from "formik";
import { isEmpty } from "lodash";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { UserRole } from "types/BETypes";
import { CardForm } from "types/CardShipping";
import { getErrorMessageFromResponse } from "helpers/shared/errors";
import { convertBusinessStructurePersonToUserResponseDto } from "helpers/shared/userData";
import useAuth from "hooks/useAuth";

import {
  mutationBankCardsControllerIssueBankCard,
  UserResponseDto,
} from "utils/swagger_react_query";

import { getPotentialCardholdersList } from "../CompanyBankAccountCreationModal/helpers";
import { CompanyCardShippingDetailsFormType } from "./components/CompanyCardShippingDetailsForm";
import {
  convertShippingFormDataToReqPhysicalCardPayload,
  convertShippingFormDataToReqVirtualCardPayload,
  getSelectedCardholderIssueCards,
  userDetailsToShippingDetailsFormInitialValues,
} from "./helpers";
import { IUseCompanyCardShippingDetailsProps } from "./types";

type IHandleSubmitParams = Pick<
  FormikProps<CompanyCardShippingDetailsFormType>,
  "values" | "validateForm"
>;

export const useCompanyCardShippingDetails = ({
  onSubmitSuccess,
  defaultSelectedCardType,
  businessOwnersStructure,
  bankCardsList,
}: IUseCompanyCardShippingDetailsProps) => {
  const currentUser = useAppSelector(userMetadataSelector);
  const { getCurrentUser } = useAuth();
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [selectedCardType, setSelectedCardType] = useState<CardForm[]>(
    defaultSelectedCardType || [CardForm.PHYSICAL],
  );
  const [selectedCardholderData, setSelectedCardholderData] = useState<Partial<UserResponseDto>>();

  const getInitValues = () => {
    if (currentUser?.lastActiveRole === UserRole.EMPLOYEE) {
      return userDetailsToShippingDetailsFormInitialValues(currentUser);
    }

    return userDetailsToShippingDetailsFormInitialValues(selectedCardholderData);
  };

  const handleSetCardType = (cardType: CardForm) => {
    const alreadySelected = selectedCardType.includes(cardType);
    if (alreadySelected) {
      setSelectedCardType(selectedCardType.filter((type) => type !== cardType));
    } else {
      setSelectedCardType([...selectedCardType, cardType]);
    }
  };

  const handleSetCardholderData = (data: Partial<UserResponseDto>) => {
    const isPhysicalCardAlreadyIssued = getSelectedCardholderIssueCards(
      data.userId || "",
      bankCardsList,
    ).some((it) => it.form === CardForm.PHYSICAL);
    if (isPhysicalCardAlreadyIssued) {
      setSelectedCardType([]);
    }
    setSelectedCardholderData(data);
  };

  const handleSubmit = async ({ values, validateForm }: IHandleSubmitParams) => {
    try {
      setIsSubmitting(true);
      const isPhysicalCardSelected = selectedCardType.includes(CardForm.PHYSICAL);
      const isVirtualCardSelected = selectedCardType.includes(CardForm.VIRTUAL);
      const isPhysicalCardAlreadyIssued = selectedCardholderExistingCardForms?.includes(
        CardForm.PHYSICAL,
      );

      if (isPhysicalCardSelected) {
        const errors = await validateForm(values);
        if (!isEmpty(errors)) return;
      }

      const isEmployee = currentUser?.lastActiveRole === UserRole.EMPLOYEE;
      const userId = isEmployee ? currentUser?.userId : selectedCardholderData?.userId;
      const role = isEmployee ? UserRole.EMPLOYEE : UserRole.ADMIN;

      if (isPhysicalCardSelected && !isPhysicalCardAlreadyIssued) {
        const payload = convertShippingFormDataToReqPhysicalCardPayload(userId || "", role, values);
        await mutationBankCardsControllerIssueBankCard()(payload);
      }
      if (isVirtualCardSelected) {
        const payload = convertShippingFormDataToReqVirtualCardPayload(userId || "", role);
        await mutationBankCardsControllerIssueBankCard()(payload);
      }

      await onSubmitSuccess?.(selectedCardType);

      if (isEmployee) {
        await getCurrentUser();
      }
    } catch (error) {
      setErrorMessage(getErrorMessageFromResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  };

  const potentialCardholdersList: Partial<UserResponseDto>[] = useMemo(
    () =>
      getPotentialCardholdersList(businessOwnersStructure || null).map((it) =>
        convertBusinessStructurePersonToUserResponseDto(it),
      ),
    [businessOwnersStructure],
  );

  const selectedCardholderExistingCardForms = useMemo(() => {
    const cardForms: CardForm[] = getSelectedCardholderIssueCards(
      selectedCardholderData?.userId || "",
      bankCardsList,
    )?.map((it) => it.form as CardForm);

    return cardForms;
  }, [selectedCardholderData, bankCardsList]);

  return {
    errorMessage,
    isSubmitting,
    getInitValues,
    handleSetCardType,
    handleSetCardholderData,
    handleSubmit,
    selectedCardType,
    selectedCardholderData,
    potentialCardholdersList,
    businessOwnersStructure,
    selectedCardholderExistingCardForms,
  };
};

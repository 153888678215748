import { FC } from "react";
import { Trans } from "react-i18next";

import { IFrame, StyledUIModal } from "./styles";

interface IProps {
  originTranslationPrefix: string;
  marqetaUrl: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ActivatePhysicalCardModal: FC<IProps> = ({
  originTranslationPrefix,
  isOpen,
  onClose,
  marqetaUrl,
}) => {
  const translationPrefix = `${originTranslationPrefix}.activate_physical_card_modal`;

  return (
    <StyledUIModal
      isOpen={isOpen}
      onClose={onClose}
      title={<Trans i18nKey={`${translationPrefix}.title`} />}
      message={<Trans i18nKey={`${translationPrefix}.message`} />}
    >
      <IFrame data-testid="activate-physical-card-modal-marqeta-frame" src={marqetaUrl} />
    </StyledUIModal>
  );
};

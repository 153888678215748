import styled from "styled-components";

import { Body2, Body3 } from "uikit";

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SwitchTextWrapper = styled.div``;

export const SwitchTextTitle = styled(Body2)``;

export const SwitchTextDescription = styled(Body3)`
  color: ${({ theme }) => theme.colors.textAlt};
`;

export enum BusinessStructureEntityCardState {
  EMPTY = "EMPTY",
  FILLED = "FILLED",
}

export enum BusinessStructureEntityType {
  BUSINESS = "BUSINESS",
  OWNER = "OWNER",
  KEY_MANAGER = "KEY_MANAGER",
}

export interface BusinessStructureEntityCardData {
  businessStructurePersonId?: string;
  businessStructureBusinessId?: string;
  name: string;
  ownershipPercentage?: number;
  entityType: BusinessStructureEntityType;
}

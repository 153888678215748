import { Trans } from "react-i18next";

import { IContextMenuOption } from "uikit";

export const getBankAccountInfoSectionContextMenuOptions = (
  onViewBusinessStructure: () => void,
): IContextMenuOption[] => {
  const translationPrefix = `banking_pages.shared.index.account_section.admin_context_menu`;
  return [
    {
      label: <Trans i18nKey={`${translationPrefix}.view_business_structure`} />,
      value: "viewBusinessStructure",
      onClick: () => onViewBusinessStructure(),
    },
  ];
};

import { KybStatus } from "types/BETypes";

import { GetCompanyByIdResponseDto } from "utils/swagger_react_query";

export const isKybAccepted = (company?: Partial<GetCompanyByIdResponseDto> | null) => {
  return company?.kybStatus === KybStatus.ACCEPTED;
};

export const isKybRejected = (company?: Partial<GetCompanyByIdResponseDto> | null) => {
  return company?.kybStatus === KybStatus.REJECTED;
};

export const isKybPending = (company?: Partial<GetCompanyByIdResponseDto> | null) => {
  return (
    company?.kybStatus === KybStatus.PENDING ||
    company?.kybStatus === KybStatus.REVIEW ||
    company?.kybStatus === KybStatus.PROVISIONAL
  );
};

import { FC } from "react";
import Modal from "react-modal";

import ModalContent from "./components/ModalContent";
import { IModalProps } from "./types";
import { contentStyle, overlayStyle } from "./styles";

import "./styles.scss";

const UIModal: FC<IModalProps> = (props) => {
  const {
    className,
    contentClassName,
    isOpen,
    type,
    onClose,
    canClose = true,
    shouldCloseOnOverlayClick = true,
    showCloseButton = true,
    customContentStyle,
    customOverlayStyle,
    dataTestId,
  } = props;

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => {
        onClose && onClose();
      }}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick && canClose}
      style={{
        content: Object.assign(contentStyle, customContentStyle || {}),
        overlay: Object.assign(overlayStyle, customOverlayStyle || {}),
      }}
      className={`${className || ""} modal-type-${type}`}
      data-testid={dataTestId}
    >
      {/* className is set specifically, because otherwise class is passed from parent to a child,
       and styled-components consider them as the same component*/}
      <ModalContent {...props} className={contentClassName} showCloseButton={showCloseButton} />
    </Modal>
  );
};

export default UIModal;

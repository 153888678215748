import { FC } from "react";

import IconProps from "./IconProps";

const CardWithPhone: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_12084_15460)">
      <path
        d="M15.5895 4.24303C15.4899 3.99941 15.3422 3.7755 15.1525 3.58579C14.7774 3.21071 14.2687 3 13.7383 3H5.73828C5.20785 3 4.69914 3.21071 4.32407 3.58579C3.94899 3.96086 3.73828 4.46957 3.73828 5V19C3.73828 19.5304 3.94899 20.0391 4.32407 20.4142C4.69914 20.7893 5.20785 21 5.73828 21H13.7383C14.2687 21 14.7774 20.7893 15.1525 20.4142C15.3422 20.2245 15.4899 20.0006 15.5895 19.757"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.39062 10.5141H23.0843M11.6559 14.2283H11.664M14.9211 14.2283H16.5538M8.39062 9.02836C8.39062 8.43731 8.64864 7.87047 9.1079 7.45253C9.56717 7.0346 10.1901 6.7998 10.8396 6.7998H20.6353C21.2848 6.7998 21.9077 7.0346 22.367 7.45253C22.8263 7.87047 23.0843 8.43731 23.0843 9.02836V14.9712C23.0843 15.5622 22.8263 16.129 22.367 16.547C21.9077 16.9649 21.2848 17.1997 20.6353 17.1997H10.8396C10.1901 17.1997 9.56717 16.9649 9.1079 16.547C8.64864 16.129 8.39062 15.5622 8.39062 14.9712V9.02836Z"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.73828 4H10.7383"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_12084_15460">
        <rect width="24" height="24" fill="white" transform="translate(0.8125)" />
      </clipPath>
    </defs>
  </svg>
);

export default CardWithPhone;

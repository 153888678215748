import { Trans } from "react-i18next";
import { ClockSmall, CloseIconThin, LogoOrangeRound, TooltipCloud } from "assets/svg";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { KybStatus, KycStatus, UserRole } from "types/BETypes";

import { IProps } from "./types";
import {
  CBACardContent,
  CBAMainContent,
  CBAMainContentPba,
  CBATCBackground,
  CBATCInner,
  CBATCLogoContainer,
  CBATCText,
  CBATextCloud,
  CBCEButton,
  CBCEErrorText,
  CBCEIconContainer,
  CBCError,
  CBCWarning,
  CBCWIconContainer,
  CBCWText,
} from "./styles";

export const useCBACards = (props: IProps) => {
  const { onButtonClick } = props;

  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const translationPrefix = `components.company_bank_account_promo_cards.shared`;

  const renderCompanyAccountWarningBelowContent = () => {
    return (
      <CBCWarning>
        <CBCWIconContainer>
          <ClockSmall />
        </CBCWIconContainer>
        <CBCWText>
          <Trans i18nKey={`${translationPrefix}.pending_verification_label`} />
        </CBCWText>
      </CBCWarning>
    );
  };

  const renderCompanyAccountErrorBelowContent = () => {
    return (
      <CBCError>
        <CBCEIconContainer>
          <CloseIconThin />
        </CBCEIconContainer>
        <CBCEErrorText>
          <Trans i18nKey={`${translationPrefix}.verification_failed_label`} />
        </CBCEErrorText>
        <CBCEButton onClick={onButtonClick}>
          <Trans i18nKey={`${translationPrefix}.restart_verification_label`} />
        </CBCEButton>
      </CBCError>
    );
  };

  const renderCompanyAccountBelowBlock = () => {
    if (currentUser?.lastActiveRole === UserRole.EMPLOYEE) {
      if (
        currentUser?.kycStatus === KycStatus.PENDING ||
        currentUser?.kycStatus === KycStatus.REVIEW ||
        currentUser?.kycStatus === KycStatus.PROVISIONAL
      ) {
        return renderCompanyAccountWarningBelowContent();
      } else if (currentUser?.kycStatus === KycStatus.REJECTED) {
        return renderCompanyAccountErrorBelowContent();
      } else {
        return undefined;
      }
    }

    if (
      currentCompany?.kybStatus === KybStatus.PENDING ||
      currentCompany?.kybStatus === KybStatus.REVIEW ||
      currentCompany?.kybStatus === KybStatus.PROVISIONAL
    ) {
      return renderCompanyAccountWarningBelowContent();
    } else if (currentUser?.kycStatus === KycStatus.REJECTED) {
      return renderCompanyAccountErrorBelowContent();
    } else {
      return undefined;
    }
  };

  const renderLargeCompanyBankAccountCardAdditionalContent = () => {
    return (
      <CBACardContent>
        <CBAMainContent>
          <CBATextCloud>
            <CBATCBackground>
              <TooltipCloud />
            </CBATCBackground>
            <CBATCInner>
              <CBATCLogoContainer>
                <LogoOrangeRound />
              </CBATCLogoContainer>
              <CBATCText>
                <Trans i18nKey={`${translationPrefix}.popup_cloud_text`} />
              </CBATCText>
            </CBATCInner>
          </CBATextCloud>
        </CBAMainContent>
      </CBACardContent>
    );
  };

  const renderLargeCompanyBankAccountCardAdditionalContentPba = () => {
    return (
      <CBACardContent>
        <CBAMainContentPba>
          <CBATextCloud>
            <CBATCBackground>
              <TooltipCloud />
            </CBATCBackground>
            <CBATCInner>
              <CBATCLogoContainer>
                <LogoOrangeRound />
              </CBATCLogoContainer>
              <CBATCText>
                <Trans i18nKey={`${translationPrefix}.popup_cloud_text_pba`} />
              </CBATCText>
            </CBATCInner>
          </CBATextCloud>
        </CBAMainContentPba>
      </CBACardContent>
    );
  };

  const baseConfig: IProps = {
    id: "company-bank-account-card",
    activeLabel: <Trans i18nKey={`${translationPrefix}.selected_label`} />,
    additionalContent: renderLargeCompanyBankAccountCardAdditionalContent(),
    belowContent: renderCompanyAccountBelowBlock(),
    buttonText: <Trans i18nKey={`${translationPrefix}.buttons.default`} />,
  };

  const basePbaConfig: IProps = {
    id: "company-business-account-card",
    activeLabel: <Trans i18nKey={`${translationPrefix}.selected_label`} />,
    additionalContent: renderLargeCompanyBankAccountCardAdditionalContentPba(),
    buttonText: <Trans i18nKey={`${translationPrefix}.buttons.default_pba`} />,
  };

  return {
    baseConfig,
    basePbaConfig,
    renderLargeCompanyBankAccountCardAdditionalContent,
    renderCompanyAccountBelowBlock,
  };
};

export default useCBACards;

import React, { FC } from "react";

import IconProps from "./IconProps";

const NoCreditCard: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
  >
    <g clip-path="url(#clip0_11912_164978)">
      <path
        d="M5.5 5L35.5 35"
        stroke="#627293"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5 8.33331H30.5C31.8261 8.33331 33.0979 8.8601 34.0355 9.79778C34.9732 10.7355 35.5 12.0072 35.5 13.3333V26.6666C35.5005 27.1578 35.4286 27.6464 35.2867 28.1167"
        stroke="#627293"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.95 31.4533C31.4798 31.5953 30.9912 31.6672 30.5 31.6667H10.5C9.17392 31.6667 7.90215 31.1399 6.96447 30.2022C6.02678 29.2645 5.5 27.9927 5.5 26.6667V13.3333C5.5 11.08 6.99 9.17499 9.04 8.54999"
        stroke="#627293"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.5 18.3333H18.8333"
        stroke="#627293"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.5 18.3333H35.5"
        stroke="#627293"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.1666 25H12.1833"
        stroke="#627293"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.8334 25H22.1667"
        stroke="#627293"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11912_164978">
        <rect width="40" height="40" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default NoCreditCard;

import React, { FC } from "react";

import UILoader from "./Loader";
import { FullSectionLoaderContainer } from "./styles";

const FullSectionLoader: FC = () => (
  <FullSectionLoaderContainer data-testid="section-loader">
    <UILoader />
  </FullSectionLoaderContainer>
);

export default FullSectionLoader;

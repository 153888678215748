import { FC, useState } from "react";
import { Trans } from "react-i18next";
import { LockIcon } from "assets/svg";
import { Formik } from "formik";

import { TERMS_AND_CONDITIONS } from "constants/shared";

import { Checkbox, Loader } from "uikit";

import { BackBtn, BtnsWrapper, SubmitBtn } from "../../CompanyBankAccountCreationModal/styles";
import { renderErrorMessage } from "../../components/renderElements";
import {
  CheckboxText,
  CheckboxTextLink,
  CheckboxWrapper,
  FormSection,
  HDescription,
  Header,
  HTitle,
  ScrollableContainer,
  StyledLoaderContainer,
} from "../../components/styles";
import {
  BusinessAccountCompanyInfoForm,
  BusinessAccountCompanyInfoFormType,
  BusinessAccountCompanyInfoValidationSchema,
} from "./components";
import { IBusinessInformationProps } from "./types";
import { useBusinessInformation } from "./useBusinessInformation";
import { Container, FormContainer } from "./styles";

const BusinessInformation: FC<IBusinessInformationProps> = ({
  className,
  onBackBtnClick,
  onSubmitSuccess,
}) => {
  const translationPrefixOrigin = `components.company_bank_account_components`;
  const translationPrefix = `${translationPrefixOrigin}.admin.business_information`;
  const [svoc, setSvoc] = useState<boolean>(false);
  const { errorMessage, isSubmitting, initValues, handleSubmit, isLoading } =
    useBusinessInformation({
      onSubmitSuccess,
    });

  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] = useState<boolean>(false);
  const formSectionClassName = `${isSubmitting ? "loading" : ""}`;

  const shouldDisableSubmitBtn = () => {
    if (!isSubmitting) return !isTermsAndConditionsAccepted;
    return isSubmitting;
  };

  const onChangeTermsAndConditions = () => {
    setIsTermsAndConditionsAccepted((prev) => !prev);
  };

  if (isLoading) return <Loader />;

  return (
    <Formik<BusinessAccountCompanyInfoFormType>
      onSubmit={() => {}}
      initialValues={initValues || ({} as BusinessAccountCompanyInfoFormType)}
      validationSchema={BusinessAccountCompanyInfoValidationSchema}
      validateOnChange={svoc}
    >
      {(formikProps) => (
        <Container
          className={className}
          onSubmit={(e) => {
            e.preventDefault();
            setSvoc(true);
            handleSubmit({
              values: formikProps.values,
              validateForm: formikProps.validateForm,
            });
          }}
        >
          <Header>
            <HTitle>
              <Trans i18nKey={`${translationPrefix}.title`} />
            </HTitle>
            <HDescription>
              <Trans i18nKey={`${translationPrefix}.protection_description`} />
              <LockIcon />
            </HDescription>
          </Header>

          {renderErrorMessage(errorMessage)}

          <FormContainer>
            <ScrollableContainer>
              <FormSection className={formSectionClassName}>
                {isSubmitting && (
                  <StyledLoaderContainer>
                    <Loader />
                  </StyledLoaderContainer>
                )}
                <BusinessAccountCompanyInfoForm />
              </FormSection>
            </ScrollableContainer>

            <CheckboxWrapper>
              <Checkbox
                checked={isTermsAndConditionsAccepted}
                disabled={isSubmitting}
                onCheck={onChangeTermsAndConditions}
                onUncheck={onChangeTermsAndConditions}
              >
                <CheckboxText>
                  <Trans
                    i18nKey={`${translationPrefix}.terms_and_conditions`}
                    components={{
                      1: <CheckboxTextLink href={TERMS_AND_CONDITIONS} target="_blank" />,
                    }}
                  />
                </CheckboxText>
              </Checkbox>
            </CheckboxWrapper>
          </FormContainer>

          <BtnsWrapper>
            <BackBtn
              onClick={onBackBtnClick}
              type={"button"}
              disabled={isSubmitting}
              id={"cbacm-business-info-form-back-button"}
              data-testid={"cbacm-business-info-form-back-button"}
            >
              <Trans i18nKey="buttons.cancel" />
            </BackBtn>

            <SubmitBtn
              type={"submit"}
              disabled={shouldDisableSubmitBtn()}
              id={"cbacm-business-info-form-submit-button"}
              data-testid={"cbacm-business-info-form-submit-button"}
            >
              <Trans i18nKey={`buttons.submit`} />
            </SubmitBtn>
          </BtnsWrapper>
        </Container>
      )}
    </Formik>
  );
};

export default BusinessInformation;

import { isKybAccepted } from "helpers/shared/kyb";
import { isKycAccepted } from "helpers/shared/kyc";

import {
  BankCardResDto,
  BusinessStructureKeyManagerResponseDto,
  BusinessStructureOwnerResponseDto,
  GetBusinessStructureResponseDto,
  GetCompanyByIdResponseDto,
  MfaFactorDto,
  UserResponseDto,
} from "utils/swagger_react_query";

import { isPhysicalCardActiveOrUnactivated } from "../CompanyCardShippingDetails/helpers";

export const hasAdminFinishedSetup = (
  companyDetails: GetCompanyByIdResponseDto | undefined | null,
  factors: MfaFactorDto[] | undefined,
): boolean => {
  const kybIsAccepted = isKybAccepted(companyDetails);
  const hasVerifiedFactor = !!factors?.some((factor) => factor.isVerified);

  return !kybIsAccepted || !hasVerifiedFactor;
};

export const hasEmployeeFinishedSetup = (
  userDetails: UserResponseDto | undefined | null,
  bankCards: BankCardResDto[] | undefined,
  factors: MfaFactorDto[] | undefined,
): boolean => {
  const kycIsAccepted = isKycAccepted(userDetails);
  const hasVerifiedFactor = !!factors?.some((factor) => factor.isVerified);
  const hasUserAlreadyOrderedPhysicalCard = !bankCards?.some(isPhysicalCardActiveOrUnactivated);

  return !kycIsAccepted || !hasVerifiedFactor || !hasUserAlreadyOrderedPhysicalCard;
};

export const getPotentialCardholdersList = (
  businessOwnersStructure: GetBusinessStructureResponseDto | null,
): Array<BusinessStructureKeyManagerResponseDto | BusinessStructureOwnerResponseDto> => {
  return [
    ...(businessOwnersStructure?.keyManagers || []),
    ...(businessOwnersStructure?.owners || []),
  ].filter(
    (it): it is BusinessStructureKeyManagerResponseDto | BusinessStructureOwnerResponseDto =>
      it !== undefined,
  );
};

import { FC } from "react";
import { Trans } from "react-i18next";

import { Dot, DotsBtnContainer } from "./Buttons";

interface IProps {
  onClick: () => void;
}

const DotsButton: FC<IProps> = ({ onClick }) => {
  return (
    <DotsBtnContainer onClick={onClick} data-testid={"dots-button"}>
      <Dot />
      <Dot />
      <Dot />
    </DotsBtnContainer>
  );
};

export default DotsButton;

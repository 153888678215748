import * as yup from "yup";

import {
  addressValidationRulesBase,
  commonValidationRules,
  userDetailsValidationRulesBase,
} from "constants/shared/validationRules";

export const IndividualOwnerFormValidationSchema = yup.object({
  ...addressValidationRulesBase,
  ...userDetailsValidationRulesBase,
  email: commonValidationRules.email,
  ownershipPercentage: yup.number().required("validationErrors.common.is_required"),
});

export type IndividualOwnerFormType = yup.InferType<typeof IndividualOwnerFormValidationSchema>;

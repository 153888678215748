import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import moment from "moment";

import SelectOption from "types/SelectOption";
import { TStates } from "types/States";
import { MAX_INPUT_LENGTH } from "constants/form";
import { StateSelectOptions, zipInputFormat } from "constants/shared";
import { getFieldError, getPhoneNumberOrNull } from "helpers";

import {
  DatePicker,
  FormLabelSmall,
  Input,
  InputContainer,
  InputGroupContainerHalved,
  NumberInput,
  PhoneInput,
  SsnInput,
} from "uikit";
import { renderExternalLabel } from "uikit/Input/helpers";
import CustomSelect from "uikit/Input/Select";

import { IndividualOwnerFormType } from "./validationSchema";
import { Container } from "./styles";

interface IProps {}

export const IndividualOwnerForm: FC<IProps> = () => {
  const { t } = useTranslation();
  const translationPrefix = `components.business_owner_structure.shared.inputs_person`;

  const formikProps = useFormikContext<IndividualOwnerFormType>();
  const { values, setFieldValue, handleChange } = formikProps;

  return (
    <Container>
      <InputGroupContainerHalved>
        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.email.label`} />
          </FormLabelSmall>
          <Input
            name="email"
            onChange={(e) => setFieldValue("email", e.currentTarget.value.split(" ").join(""))}
            value={values.email}
            error={getFieldError("email", formikProps, {
              field: t(`${translationPrefix}.email.label`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            disabled={true}
          />
        </InputContainer>

        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.ownership_percentage.label`} />
          </FormLabelSmall>
          <NumberInput
            name="ownershipPercentage"
            value={String(values.ownershipPercentage)}
            onChange={(e) => setFieldValue("ownershipPercentage", e)}
            error={getFieldError("ownership_percentage", formikProps, {
              field: t(`${translationPrefix}.ownership_percentage.label`),
            })}
            placeholder={t(`common.input.placeholder_base`, {
              field: t(`${translationPrefix}.ownership_percentage.label`),
            })}
            data-testid="ownership_percentage_input"
            units={"%"}
          />
        </InputContainer>
      </InputGroupContainerHalved>

      <InputGroupContainerHalved>
        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.first_name.label`} />
          </FormLabelSmall>
          <Input
            name="firstName"
            onChange={(e) => setFieldValue("firstName", e.currentTarget.value.trimStart())}
            onBlur={(e) => setFieldValue("firstName", e.currentTarget.value.trimEnd())}
            value={values.firstName}
            error={getFieldError("firstName", formikProps, {
              field: t(`${translationPrefix}.first_name.label`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            errorDataTestId="error-first-name"
          />
        </InputContainer>

        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.last_name.label`} />
          </FormLabelSmall>
          <Input
            name="lastName"
            onChange={(e) => setFieldValue("lastName", e.currentTarget.value.trimStart())}
            onBlur={(e) => setFieldValue("lastName", e.currentTarget.value.trimEnd())}
            value={values.lastName}
            error={getFieldError("lastName", formikProps, {
              field: t(`${translationPrefix}.last_name.label`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            errorDataTestId="error-last-name"
          />
        </InputContainer>
      </InputGroupContainerHalved>

      <InputGroupContainerHalved>
        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.phone.label`} />
          </FormLabelSmall>
          <PhoneInput
            name="phone"
            onChange={(value) => setFieldValue("phone", getPhoneNumberOrNull(value as string))}
            value={values.phone || undefined}
            error={getFieldError("phone", formikProps, {
              field: t(`${translationPrefix}.phone.label`),
            })}
            errorDataTestId="error-phone"
          />
        </InputContainer>

        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.social_security_number.label`} />
          </FormLabelSmall>
          <SsnInput
            onSetFormField={(value: string) => setFieldValue("socialSecurityNumber", value)}
            error={getFieldError("socialSecurityNumber", formikProps, {
              field: t(`${translationPrefix}.social_security_number.label`),
            })}
            value={values.socialSecurityNumber || ""}
            placeholder={t(`common.input.placeholder_base`, {
              field: t(`${translationPrefix}.social_security_number.label`),
            })}
          />
        </InputContainer>
      </InputGroupContainerHalved>

      <InputGroupContainerHalved>
        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.birth_date.label`} />
          </FormLabelSmall>
          <DatePicker
            useMothsYearsHeader={true}
            name="birthDate"
            selected={values.birthDate || null}
            onChange={(e) => setFieldValue("birthDate", e)}
            error={getFieldError("birthDate", formikProps, {
              field: t(`${translationPrefix}.birth_date.label`),
            })}
            maxDate={new Date()}
            minDate={moment().subtract(100, "year").toDate()}
            errorDataTestId="error-birthdate"
            placeholderText={t(`common.input.placeholder_base`, {
              field: t(`${translationPrefix}.birth_date.label`),
            })}
          />
        </InputContainer>

        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.address.label`} />
          </FormLabelSmall>
          <Input
            name="address"
            onChange={handleChange}
            value={values.address}
            error={getFieldError("address", formikProps, {
              field: t(`${translationPrefix}.address.label`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            errorDataTestId="error-address"
            placeholder={t(`common.input.placeholder_base`, {
              field: t(`${translationPrefix}.address.label`),
            })}
          />
        </InputContainer>
      </InputGroupContainerHalved>

      <InputGroupContainerHalved>
        <InputContainer>
          <FormLabelSmall>
            {renderExternalLabel(<Trans i18nKey={`${translationPrefix}.suite.label`} />, true)}
          </FormLabelSmall>
          <Input
            name="suite"
            optional
            onChange={handleChange}
            value={values.suite || ""}
            error={getFieldError("suite", formikProps, {
              field: t(`${translationPrefix}.suite.label`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            errorDataTestId="error-suit"
            placeholder={t(`common.input.placeholder_base`, {
              field: t(`${translationPrefix}.suite.label`),
            })}
          />
        </InputContainer>

        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.city.label`} />
          </FormLabelSmall>
          <Input
            name="city"
            onChange={handleChange}
            value={values.city || ""}
            error={getFieldError("city", formikProps, {
              field: t(`${translationPrefix}.city.label`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            errorDataTestId="error-city"
            placeholder={t(`common.input.placeholder_base`, {
              field: t(`${translationPrefix}.city.label`),
            })}
          />
        </InputContainer>
      </InputGroupContainerHalved>

      <InputGroupContainerHalved>
        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.state.label`} />
          </FormLabelSmall>
          <CustomSelect<SelectOption<TStates>>
            name="state"
            onChange={(option) => setFieldValue("state", option)}
            value={values.state as any}
            options={StateSelectOptions}
            error={getFieldError("state.value", formikProps, {
              field: t(`${translationPrefix}.state.label`),
            })}
            isSearchable={true}
            errorDataTestId="error-state"
            placeholder={t(`common.input.placeholder_base`, {
              field: t(`${translationPrefix}.state.label`),
            })}
          />
        </InputContainer>

        <InputContainer>
          <FormLabelSmall>
            <Trans i18nKey={`${translationPrefix}.zip.label`} />
          </FormLabelSmall>
          <NumberInput
            name="zip"
            onChange={(val) => setFieldValue("zip", val.toString().split(" ").join(""))}
            value={values.zip}
            error={getFieldError("zip", formikProps, {
              field: t(`${translationPrefix}.zip.label`),
            })}
            errorDataTestId="error-zip"
            format={zipInputFormat}
            placeholder={t(`common.input.placeholder_base`, {
              field: t(`${translationPrefix}.zip.label`),
            })}
          />
        </InputContainer>
      </InputGroupContainerHalved>
    </Container>
  );
};

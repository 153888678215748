import { FC } from "react";
import { Trans } from "react-i18next";
import PromoImage from "assets/png/promo_woman_alt_2.png";

import { UserRole } from "types/BETypes";
import PermissionContent from "components/PermissionContent";

import {
  Container,
  Content,
  ContentInnerLeft,
  ContentInnerRight,
  ContinueSetupBtn,
  Img,
  Message,
  Title,
} from "./styles";

interface IProps {
  onContinueSetupClick: () => void;
}

const FinishCompanyBankAccountSetupBanner: FC<IProps> = ({ onContinueSetupClick }) => {
  const translationPrefix = `components.company_bank_account_components.finish_setup_banner`;

  return (
    <Container>
      <Img src={PromoImage} />
      <Content>
        <ContentInnerLeft>
          <Title>
            <Trans i18nKey={`${translationPrefix}.title`} />
          </Title>
          <Message>
            <PermissionContent
              roles={[UserRole.EMPLOYEE]}
              elseContent={<Trans i18nKey={`${translationPrefix}.message.admin`} />}
            >
              <Trans i18nKey={`${translationPrefix}.message.employee`} />
            </PermissionContent>
          </Message>
        </ContentInnerLeft>

        <ContentInnerRight>
          <ContinueSetupBtn onClick={onContinueSetupClick} data-testid="continue-setup-btn">
            <Trans i18nKey={`${translationPrefix}.continue_setup_btn`} />
          </ContinueSetupBtn>
        </ContentInnerRight>
      </Content>
    </Container>
  );
};

export default FinishCompanyBankAccountSetupBanner;

import { BusinessKeyManagerTitle } from "types/BETypes";
import SelectOption from "types/SelectOption";

export const businessKeyManagerRoles: SelectOption<BusinessKeyManagerTitle>[] = [
  {
    value: BusinessKeyManagerTitle.DIRECTOR,
    label: `components.business_owner_structure.shared.key_manager_title.${BusinessKeyManagerTitle.DIRECTOR}`,
  },
  {
    value: BusinessKeyManagerTitle.OFFICER,
    label: `components.business_owner_structure.shared.key_manager_title.${BusinessKeyManagerTitle.OFFICER}`,
  },
  {
    value: BusinessKeyManagerTitle.FOUNDER,
    label: `components.business_owner_structure.shared.key_manager_title.${BusinessKeyManagerTitle.FOUNDER}`,
  },
];

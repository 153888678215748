import { FC } from "react";

import IconProps from "./IconProps";

const AttentionTriangleFilledIcon: FC<IconProps> = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.2236 15.0431C20.3345 17.0427 18.8886 19.5 16.6012 19.5L3.39921 19.5C1.11176 19.5 -0.334145 17.0427 0.776741 15.0431L7.37771 3.16133C8.52072 1.1039 11.4796 1.10389 12.6227 3.16132L19.2236 15.0431ZM9.00018 14.72C9.00018 15.2723 9.4479 15.72 10.0002 15.72C10.5525 15.72 11.0002 15.2723 11.0002 14.72V14.7105C11.0002 14.1582 10.5525 13.7105 10.0002 13.7105C9.4479 13.7105 9.00018 14.1582 9.00018 14.7105V14.72ZM10.0002 12.5C9.4479 12.5 9.00018 12.0523 9.00018 11.5V8.5C9.00018 7.94771 9.4479 7.5 10.0002 7.5C10.5525 7.5 11.0002 7.94771 11.0002 8.5V11.5C11.0002 12.0523 10.5525 12.5 10.0002 12.5Z"
      fill="#EDB012"
    />
  </svg>
);

export default AttentionTriangleFilledIcon;

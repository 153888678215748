export enum EBusinessInfoResponseType {
  SUCCESS = "success",
  VERIFICATION_IS_TAKING_TOO_LONG = "verification_is_taking_too_long",
  ERROR = "error",
  INCORRECT_INFO = "incorrect_info",
}

export interface IBusinessInformationProps {
  className?: string;
  onSubmitSuccess: () => void;
  onBackBtnClick: () => void;
}

export type IUseBusinessInformationParams = Pick<IBusinessInformationProps, "onSubmitSuccess">;

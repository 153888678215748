import styled, { css } from "styled-components";

import { UserRole } from "types/BETypes";

const employeeImageMixin = css`
  width: 392px;
  height: 336px;
`;
const adminImageMixin = css`
  width: 325px;
  height: 358px;
`;

export const Container = styled.div`
  width: 100%;
`;

export const InnerContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextContainer = styled.div``;

export const PromoImg = styled.img<{ userRole: UserRole }>`
  ${({ userRole }) => (userRole === UserRole.EMPLOYEE ? employeeImageMixin : adminImageMixin)}
`;

export const StepsList = styled.div`
  margin-bottom: 33px;
`;

import { FC, Fragment, ReactNode, useState } from "react";
import ReactTooltip from "react-tooltip";

import { useMainMenu } from "./useMainMenu";
import {
  CollapseBtn,
  CollapseBtnIcon,
  commonTransitionDuration,
  IconContainer,
  LabelContainer,
  List,
  LogoContainer,
  MBMainContainer,
  MBTopContainer,
  MenuBaseLink,
  MenuBlock,
  MenuLink,
  StyledLogo,
  StyledLogoFullAlt,
} from "./styles";

export interface MenuItem {
  id: string;
  icon: ReactNode;
  label: string;
  url?: string;
  href?: string;
  hide?: boolean;
}

export interface Props {
  needRenderMenuForResponsive?: boolean;
}

const MainMenu: FC<Props> = ({ needRenderMenuForResponsive }) => {
  const { metadata, state } = useMainMenu();
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const animationDuration = commonTransitionDuration;

  const animationClassName = shouldAnimate ? "animate" : "";
  const collapsedClassName = state.isCollapsed ? "collapsed" : "not-collapsed";
  const classNameByRole = `role-${metadata.currentUser?.lastActiveRole} ${collapsedClassName}`;

  const menu: MenuItem[] = metadata.getMenu();

  const renderMenuLink = (item: MenuItem) => (
    <Fragment>
      <IconContainer className={collapsedClassName}>{item.icon}</IconContainer>
      {<LabelContainer className={collapsedClassName}>{item.label}</LabelContainer>}
      <ReactTooltip
        id={item.id}
        place="right"
        type="dark"
        effect="solid"
        className={`tooltip ${collapsedClassName}`}
      />
    </Fragment>
  );

  const renderMenuLinkWrapper = (item: MenuItem) => {
    const commonProps = {
      className: classNameByRole,
      key: item.id,
      ["data-for"]: item.id,
      ["data-testid"]: `main-menu-item-${item.id}`,
      ["data-tip"]: item.label,
    };

    if (item.href)
      return (
        <MenuBaseLink href={item.href} target="_blank" {...commonProps}>
          {renderMenuLink(item)}
        </MenuBaseLink>
      );
    if (item.url)
      return (
        <MenuLink to={item.url} {...commonProps}>
          {renderMenuLink(item)}
        </MenuLink>
      );
  };

  const onCollapse = () => {
    state.handleCollapse();
    setShouldAnimate(true);
    setTimeout(() => {
      setShouldAnimate(false);
    }, animationDuration);
  };

  return (
    <MenuBlock
      className={classNameByRole}
      needRenderMenuForResponsive={!!needRenderMenuForResponsive}
    >
      <MBTopContainer className={collapsedClassName}>
        {!needRenderMenuForResponsive && (
          <LogoContainer className={collapsedClassName}>
            <StyledLogo className={`${collapsedClassName} ${animationClassName}`} />
            <StyledLogoFullAlt className={`${collapsedClassName} ${animationClassName}`} />
          </LogoContainer>
        )}
        {!needRenderMenuForResponsive && (
          <CollapseBtn
            className={`${collapsedClassName} ${animationClassName}`}
            onClick={onCollapse}
            data-testid="main-menu-collapse-button"
          >
            <CollapseBtnIcon className={collapsedClassName} />
          </CollapseBtn>
        )}
      </MBTopContainer>

      <MBMainContainer>
        <List>
          {menu.filter((it) => !it.hide).map((item: MenuItem) => renderMenuLinkWrapper(item))}
        </List>
      </MBMainContainer>
    </MenuBlock>
  );
};

export default MainMenu;

import styled from "styled-components";

import CopyText from "components/CopyText";

export const Container = styled.div`
  position: relative;
`;

export const Header = styled.div``;

export const StyledCopyText = styled(CopyText)``;

import { FC } from "react";
import { Trans } from "react-i18next";
import { useFormikContext } from "formik";

import { Switch } from "uikit";

import { EarlyPayFormType } from "./validationSchema";
import { SwitchTextDescription, SwitchTextTitle, SwitchTextWrapper, SwitchWrapper } from "./styles";

const EarlyPayForm: FC = () => {
  const originTranslationPrefix = "components.company_bank_account_components";
  const translationPrefix = `${originTranslationPrefix}.early_pay_form`;
  const { values, setFieldValue } = useFormikContext<EarlyPayFormType>();

  const onChange = () => {
    setFieldValue("earlyPayEnabled", !values.earlyPayEnabled);
  };

  return (
    <SwitchWrapper>
      <Switch
        checked={!!values.earlyPayEnabled}
        onChange={onChange}
        data-testid={"early-pay-switcher"}
      />
      <SwitchTextWrapper>
        <SwitchTextTitle>
          <Trans i18nKey={`${translationPrefix}.switch.title`} />
        </SwitchTextTitle>

        <SwitchTextDescription>
          <Trans i18nKey={`${translationPrefix}.switch.description`} />
        </SwitchTextDescription>
      </SwitchTextWrapper>
    </SwitchWrapper>
  );
};

export default EarlyPayForm;

import styled from "styled-components";

import { Loader, PrimaryButtonIconed } from "uikit";

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
`;

export const StyledLoader = styled(Loader)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const ContainerInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.hidden {
    visibility: hidden;
  }
`;

export const ContainerInnerLeft = styled.div`
  max-width: 480px;
  box-sizing: border-box;
  margin-right: 10px;
`;

export const ContainerInnerRight = styled.div``;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.size.titleLarge};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.25;
  margin-bottom: 12px;
`;

export const Message = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.adminSetupWelcomeMessageText};
  line-height: 1.5;
  margin-bottom: 32px;
`;

export const StepsListContainer = styled.div`
  margin-bottom: 32px;
`;

export const CIRImageContainer = styled.div``;

export const CIRImage = styled.img`
  height: 423px;
  width: auto;
`;

export const ContinueButton = styled(PrimaryButtonIconed)`
  width: auto;
`;

import { FC, useState } from "react";
import { Trans } from "react-i18next";

import { PayDistributionType } from "types/BETypes";
import { HELP_CENTER } from "constants/shared";

import { CustomLink } from "uikit";
import { EButtonsFlex } from "uikit/Modal";

import {
  ExternalBankIcon,
  MethodItem,
  MethodItemDescription,
  MethodItemRadioButton,
  MethodItemTitle,
  MethodsList,
  PaidIcon,
  StyledUIModal,
} from "./styles";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onContinue: (method: PayDistributionType) => void;
}

const BankAccountConnectionMethodModal: FC<IProps> = ({ isOpen, onClose, onContinue }) => {
  const commonTranslationPrefix = `components.bank_account_components`;
  const translationPrefix = `${commonTranslationPrefix}.bank_account_connection_method_modal`;
  const [selectedMethod, setSelectedMethod] = useState<PayDistributionType>(
    PayDistributionType.PARTNER_ACCOUNT,
  );

  const handleMethodSelect = (method: PayDistributionType) => {
    setSelectedMethod(method);
  };
  return (
    <StyledUIModal
      isOpen={isOpen}
      onClose={onClose}
      title={<Trans i18nKey={`${translationPrefix}.title`} />}
      mainButton={{
        text: <Trans i18nKey={`buttons.continue`} />,
        onClick: () => onContinue(selectedMethod),
      }}
      secondaryButton={{
        text: <Trans i18nKey={`buttons.cancel`} />,
        onClick: onClose,
      }}
      buttonsFlex={EButtonsFlex.ROW_REVERSE}
    >
      <MethodsList>
        <MethodItem
          onClick={() => handleMethodSelect(PayDistributionType.PARTNER_ACCOUNT)}
          className={selectedMethod === PayDistributionType.PARTNER_ACCOUNT ? "active" : ""}
          data-testid={`bank_account_connection_method_modal_method_item_${PayDistributionType.PARTNER_ACCOUNT}`}
        >
          <MethodItemRadioButton
            id={PayDistributionType.PARTNER_ACCOUNT}
            value={selectedMethod}
            name={"defaultBankAccount"}
            onChange={() => handleMethodSelect(PayDistributionType.PARTNER_ACCOUNT)}
            data-testid={`bank_account_connection_method_modal_method_item_${PayDistributionType.PARTNER_ACCOUNT}`}
          />
          <PaidIcon />

          <div>
            <MethodItemTitle>
              <Trans i18nKey={`${translationPrefix}.methods.paid.title`} />
            </MethodItemTitle>
            <MethodItemDescription>
              <Trans
                i18nKey={`${translationPrefix}.methods.paid.description`}
                components={{
                  1: <CustomLink href={HELP_CENTER} target="_blank" />,
                }}
              />
            </MethodItemDescription>
          </div>
        </MethodItem>
        <MethodItem
          onClick={() => handleMethodSelect(PayDistributionType.EXTERNAL_ACCOUNT)}
          className={selectedMethod === PayDistributionType.EXTERNAL_ACCOUNT ? "active" : ""}
          data-testid={`bank_account_connection_method_modal_method_item_${PayDistributionType.EXTERNAL_ACCOUNT}`}
        >
          <MethodItemRadioButton
            id={PayDistributionType.EXTERNAL_ACCOUNT}
            value={selectedMethod}
            name={"defaultBankAccount"}
            onChange={() => handleMethodSelect(PayDistributionType.EXTERNAL_ACCOUNT)}
          />
          <ExternalBankIcon />

          <div>
            <MethodItemTitle>
              <Trans i18nKey={`${translationPrefix}.methods.external.title`} />
            </MethodItemTitle>
            <MethodItemDescription>
              <Trans i18nKey={`${translationPrefix}.methods.external.description`} />
            </MethodItemDescription>
          </div>
        </MethodItem>
      </MethodsList>
    </StyledUIModal>
  );
};

export default BankAccountConnectionMethodModal;

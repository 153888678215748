import React, { FC } from "react";

import { Overlay, StyledLoader } from "./styles";

const FullScreenLoader: FC = () => (
  <Overlay data-testid="screen-loader">
    <StyledLoader />
  </Overlay>
);

export default FullScreenLoader;

import styled from "styled-components";

import { Checkmark } from "uikit/Checkbox/styles";

const optionHeight = 56;

export const Container = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;

  ${Checkmark} {
    margin-right: 0;
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  flex: 1;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.contentBg};
  border: 2px solid ${({ theme }) => theme.colors.contentBorder};
  transition: all 0.2s;
  height: ${optionHeight}px;

  path {
    stroke: ${({ theme }) => theme.colors.accentMain};
  }

  &.selected {
    border: 2px solid ${({ theme }) => theme.colors.primary};

    path {
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const IconContainer = styled.div`
  margin: 0 14px;
`;

export const Title = styled.span`
  font-size: ${({ theme }) => theme.size.subtitle};
  color: ${({ theme }) => theme.colors.text};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 24px;
`;

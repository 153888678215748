import { FC, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { EyeIcon } from "assets/svg";

import { EIN_LENGTH } from "constants/form";

import Input from "./Input";
import { BaseInputProps } from "./types";
import { MaskedInputWrapper, MaskedNumberInputIconWrapper } from "./styles";

export type Props = {
  error?: ReactNode;
  label?: ReactNode;
  value: string;
  onChange: (value: string) => void;
  needHide?: boolean;
  disabled?: boolean;
  placeholder?: string;
} & BaseInputProps;

const EinInput: FC<Props> = (props) => {
  const { error, label, onChange, value, disabled, ...rest } = props;

  const { t } = useTranslation();
  const [useMask, setUseMask] = useState<boolean>(true);
  const [number, setNumber] = useState(value);

  const visibleDigits = 4;

  const getMaskedValue = (str: string = "") => {
    const onlyNumbersString = str.replace(/-/g, "");
    const maskedPart = onlyNumbersString.slice(0, 5).replace(/\d/g, "*");
    const numbersPart = onlyNumbersString.slice(5);
    return maskedPart.concat(numbersPart);
  };

  const formatEinNumber = (str: string = "") => {
    const onlyNumbersString = str.replace(/-/g, "");
    const _separator = str.length >= 3 ? "-" : "";
    return `${onlyNumbersString.substring(0, 2)}${_separator}${onlyNumbersString.substring(2)}`;
  };

  const setValue = (_value: string) => {
    onChange(formatEinNumber(_value));
    setNumber(_value);
  };

  const handleChange = (_value: string) => {
    const serializedValue = _value.replace(/-/g, "");
    const serializedNumber = number.replace(/-/g, "");
    if (serializedValue.length > 9) return;

    let newNumber = serializedValue.slice(0, 9);

    if (useMask) {
      //This logic was inported from ssnInput, but it has a flaw:
      //If we paste a full number in input which already has another number, only last digits will be replaced
      //TODO: Use some third-party library for input masks

      if (serializedValue.length < serializedNumber.length) {
        const last = serializedNumber.length - 1;
        newNumber = serializedNumber.slice(0, last);
        setValue(newNumber);
        return;
      }

      const numValue = _value.replace(/\D/g, "").slice(0, visibleDigits);
      if (serializedNumber.length > EIN_LENGTH - visibleDigits) {
        newNumber = serializedNumber.slice(0, EIN_LENGTH - visibleDigits) + numValue;
      } else {
        newNumber = serializedNumber + numValue;
      }
      if (newNumber.length > EIN_LENGTH) return;
    }
    setValue(newNumber);
  };

  const inputValue = formatEinNumber(useMask ? getMaskedValue(value) : value);

  return (
    <>
      <MaskedInputWrapper>
        <MaskedNumberInputIconWrapper
          onClick={() => setUseMask(!useMask)}
          data-testid={"ein-visibility-button"}
        >
          <EyeIcon isCrossedOut={useMask} />
        </MaskedNumberInputIconWrapper>
        <Input
          {...rest}
          name="socialSecurityNumber"
          onChange={(e) => handleChange(e.currentTarget.value)}
          value={inputValue}
          label={label}
          error={error}
          disabled={disabled}
          errorDataTestId="error-social-security-number"
          type="number"
        />
      </MaskedInputWrapper>
    </>
  );
};

export default EinInput;

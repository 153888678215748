import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { ArrowIcon, PlusLarge } from "assets/svg";
import { isEmpty } from "lodash";
import { useAppSelector } from "store/hooks";
import { userMetadataSelector } from "store/selectors";

import { AppEvents } from "services/events";
import { ErrorConst, KycStatus, MFAFactorType } from "types/BETypes";
import { showErrorModal } from "helpers";
import { isBankAccountVerified } from "helpers/bankCards";
import { isKycAccepted, isKycPending } from "helpers/shared/kyc";
import { useBankAccountLink } from "hooks";
import { BankingDisclosureContainer } from "pages/Banking/shared/styles";
import {
  ExternalBankAccountsSection,
  PaidBankAccountSection,
} from "components/BankAccountComponents";
import BankingDisclosureComponent from "components/BankingDisclosureComponent";
import CompanyBankAccountCreationModal from "components/CompanyBankAccountComponents/CompanyBankAccountCreationModal";
import { useKycStatusModals } from "components/CompanyBankAccountComponents/employee/KYCVerification";
import { EKycResponseType } from "components/CompanyBankAccountComponents/employee/KYCVerification/types";
import useMFASMS from "components/MultiFactorAuthorization/components/sms/useMFASMS";
import { MFAWidgetType } from "components/MultiFactorAuthorization/types";
import { EWidgetType } from "components/PaymentMethods/types";

import { ButtonsContainer, Dud } from "uikit";
import { EButtonsFlex, EModalTypes } from "uikit/Modal";

import {
  queryBankCardsControllerGetSyncteraAccount,
  SyncteraAccountDto,
} from "utils/swagger_react_query";

import { ContinueButton } from "../styles";
import { IProps } from "./types";
import {
  AddExternalAccountButton,
  AddExternalAccountButtonText,
  ExternalBankAccountsButtonsContainer,
  ExternalBankAccountsContainer,
} from "./styles";

export const useBankAccountsStep = (props: IProps) => {
  const translationPrefix = `employee_setup_page.steps.bank_accounts`;
  const { onBack, onContinue } = props;

  const currentUser = useAppSelector(userMetadataSelector);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [companyBankAccount, setCompanyBankAccount] = useState<SyncteraAccountDto>();
  const [isCreationCompanyBankAccountModalOpen, setIsCreationCompanyBankAccountModalOpen] =
    useState<boolean>(false);
  const { showModal } = useKycStatusModals();

  const bankAccountLink = useBankAccountLink({
    loadingCallback: setIsLoading,
    shouldFetchBankAccountsListOnMount: false,
  });

  const { factorsData, fetchData } = useMFASMS({
    widgetType: MFAWidgetType.MISC,
  });
  const isMFAEnabled = !!factorsData?.some(
    (factor) => factor.type === MFAFactorType.SMS && factor.isVerified,
  );

  useEffect(() => {
    if (
      currentUser &&
      (isEmpty(bankAccountLink?.data?.bankAccounts) || isEmpty(companyBankAccount))
    ) {
      initFetch();
    }
  }, []);

  const initFetch = async () => {
    try {
      setIsLoading(true);
      await bankAccountLink.actions.refetchBankAccountsList(false);

      if (currentUser?.kycStatus === KycStatus.ACCEPTED) {
        const bankAccountRes = await queryBankCardsControllerGetSyncteraAccount();
        setCompanyBankAccount(bankAccountRes);
        await fetchData();
      }
    } catch (error: any) {
      if (error?.data?.error !== ErrorConst.USER_HAS_NO_PARTNER_BANK_ACCOUNT) {
        showErrorModal(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onPaidBankBannerBtnClick = () => {
    const _isKycPending = isKycPending(currentUser);
    if (_isKycPending) return showModal({ type: EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG });
    setIsCreationCompanyBankAccountModalOpen(true);
  };

  const showNoAccountsWarningModal = (callback?: () => void) => {
    const _prefix = `${translationPrefix}.modals.no_accounts_warning`;
    return AppEvents.emit("SetGlobalModal", {
      isOpen: true,
      type: EModalTypes.WARNING,
      title: <Trans i18nKey={`${_prefix}.title`} />,
      message: <Trans i18nKey={`${_prefix}.message`} />,
      mainButton: {
        text: <Trans i18nKey="buttons.continue" />,
        onClick: callback,
      },
      secondaryButton: {
        text: <Trans i18nKey="buttons.cancel" />,
      },
      buttonsFlex: EButtonsFlex.ROW_REVERSE,
    });
  };

  const handleContinue = () => {
    const validCompanyBankAccount = isKycAccepted(currentUser) ? companyBankAccount : undefined;
    const validExternalBankAccounts = (bankAccountLink?.data?.bankAccounts || []).filter((item) =>
      isBankAccountVerified(item),
    );

    if (!validCompanyBankAccount && !validExternalBankAccounts?.length) {
      showNoAccountsWarningModal(onContinue);
    } else {
      onContinue?.();
    }
  };

  const renderBankSections = () => {
    return (
      <>
        <PaidBankAccountSection
          currentUser={currentUser || undefined}
          bankAccounts={companyBankAccount?.id ? [companyBankAccount] : []}
          onBannerBtnClick={onPaidBankBannerBtnClick}
          widgetType={EWidgetType.ONBOARDING}
          setupCompleted={isMFAEnabled}
          onContinueSetupClick={onPaidBankBannerBtnClick}
        />

        <ExternalBankAccountsContainer>
          <ExternalBankAccountsSection
            userDetails={currentUser || undefined}
            bankAccounts={bankAccountLink.data.bankAccounts || []}
            onConnectBankAccount={bankAccountLink.actions.openModal}
            onRemoveBankAccount={bankAccountLink.actions.handleRemoveBankAccount}
            onVerifyBankAccount={bankAccountLink.actions.handleVerifyBankAccount}
          />

          {!!bankAccountLink.data.bankAccounts?.length && (
            <ExternalBankAccountsButtonsContainer>
              <AddExternalAccountButton
                onClick={bankAccountLink.actions.openModal}
                data-testid="employee-setup-bank-accounts-step-add-account-button"
              >
                <PlusLarge />
                <AddExternalAccountButtonText>
                  <Trans i18nKey={`${translationPrefix}.connect_bank_account_btn`} />
                </AddExternalAccountButtonText>
              </AddExternalAccountButton>
            </ExternalBankAccountsButtonsContainer>
          )}
        </ExternalBankAccountsContainer>

        <ButtonsContainer>
          <Dud />
          <ContinueButton
            onClick={handleContinue}
            data-testid="setup-bank-account-step-continue-button"
          >
            <Trans i18nKey={"buttons.continue"} />
            <ArrowIcon />
          </ContinueButton>
        </ButtonsContainer>

        <BankingDisclosureContainer>
          <BankingDisclosureComponent />
        </BankingDisclosureContainer>
      </>
    );
  };

  const renderCreateCompanyBankAccountWidget = () => {
    return (
      <>
        <CompanyBankAccountCreationModal
          isOpen={isCreationCompanyBankAccountModalOpen}
          setIsOpen={setIsCreationCompanyBankAccountModalOpen}
          onFinished={initFetch}
          refetchPageContentCallback={initFetch}
        />
      </>
    );
  };

  return {
    isLoading,
    renderBankSections,
    renderCreateCompanyBankAccountWidget,
  };
};

export default useBankAccountsStep;

import styled from "styled-components";

import { UserRole } from "types/BETypes";

const circleBorderSize = 37;
const circleBorderWidth = 2;
const circleSize = 27;

export const Container = styled.div``;

export const ListItem = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 14px;
  position: relative;
  height: auto;

  &::after {
    content: "";
    display: inline-block;
    border-left: 2px dotted ${({ theme }) => theme.colors.verticalStepsListItem};
    position: absolute;
    top: ${circleBorderSize}px;
    left: ${circleBorderSize / 2}px;
    transform: translateX(-50%);
    height: 80%;
    z-index: 0;

    ${Container}.${UserRole.EMPLOYEE} & {
      border-left: 2px dotted ${({ theme }) => theme.colors.verticalStepsListItemAlt};
    }
  }

  &:last-of-type {
    margin-bottom: 0;

    &::after {
      display: none;
    }

    div:nth-child(1)::after {
      display: none;
    }
  }
`;

export const ListItemNumberContainer = styled.div``;

export const ListItemNumberContainerInner = styled.div`
  display: block;
  border: ${circleBorderWidth}px solid ${({ theme }) => theme.colors.verticalStepsListItem};
  background-color: ${({ theme }) => theme.colors.contentBg};
  border-radius: 50%;
  width: ${circleBorderSize}px;
  height: ${circleBorderSize}px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: relative;

  ${Container}.${UserRole.EMPLOYEE} & {
    border: ${circleBorderWidth}px solid ${({ theme }) => theme.colors.verticalStepsListItemAlt};
  }
`;

export const ListItemNumber = styled.span`
  font-size: ${({ theme }) => theme.size.subtitle};
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: ${({ theme }) => theme.colors.verticalStepsListItem};
  border-radius: 50%;
  width: ${circleSize}px;
  height: ${circleSize}px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;

  ${Container}.${UserRole.EMPLOYEE} & {
    background-color: ${({ theme }) => theme.colors.verticalStepsListItemAlt};
  }
`;

export const ListItemTextContainer = styled.div`
  margin-left: 12px;
`;

export const ListItemTitle = styled.p`
  margin-bottom: 8px;
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 20.8px;
`;

export const ListItemDescription = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.textAlt};
  line-height: 18.2px;
`;

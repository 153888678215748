import styled, { css } from "styled-components";

import { breakpoint } from "helpers";

import { sectionAltRoundedMixin } from "uikit/Containers/Containers";

const emphasizedContainerMixin = css`
  ${sectionAltRoundedMixin}
`;

export const Content = styled.div<{ emphasizedContainer?: boolean }>`
  ${({ emphasizedContainer }) => emphasizedContainer && emphasizedContainerMixin};
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-bottom: 0;

  ${breakpoint("xs", "xlm")`
    margin-bottom: 0;
  `}
`;

export const KybResponseSection = styled(Content)`
  button.center {
    align-self: center;
  }
`;

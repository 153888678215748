import { isEmpty } from "lodash";
import moment from "moment";

import { BusinessKeyManagerTitle, CompanyEntityType, KybStatus } from "types/BETypes";
import { companyTypeSelectOptions } from "constants/shared/companyTypes";
import { getKeyManagerRole } from "helpers";
import { getStateObject } from "helpers/shared/states";
import { EKycResponseType } from "components/CompanyBankAccountComponents/employee/KYCVerification/types";

import {
  BusinessOwnerPersonData,
  BusinessStructureBusinessOwnersResponseDto,
  BusinessStructureKeyManagerResponseDto,
  BusinessStructureOwnerResponseDto,
  CreateBusinessOwnerBusinessDto,
  CreateKeyManager,
  UserResponseDto,
} from "utils/swagger_react_query";

import { BusinessInformationFormType } from "./components/BusinessInformationForm/validationSchema";
import { BusinessKeyManagerFormType } from "./components/BusinessKeyManagerForm/validationSchema";
import { IndividualOwnerFormType } from "./components/IndividualOwnerForm/validationSchema";

export const convertIndividualOwnerDataResToFormData = (
  userData?: BusinessStructureOwnerResponseDto,
): IndividualOwnerFormType | {} => {
  if (isEmpty(userData)) return {};

  return {
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
    phone: userData?.phoneNumber || "",
    socialSecurityNumber: userData?.ssn || "",
    birthDate: moment(userData?.dateOfBirth || undefined).toDate(),
    address: userData?.address || "",
    suite: userData?.suite || "",
    city: userData?.city || "",
    state: getStateObject(userData?.state),
    zip: userData?.zip || "",
    email: userData?.email || "",
    ownershipPercentage: userData?.ownershipPercentage || 0,
  };
};

export const convertKeyManagerDataResToFormData = (
  userData?: BusinessStructureKeyManagerResponseDto,
): BusinessKeyManagerFormType | {} => {
  if (isEmpty(userData)) return {};

  return {
    firstName: userData?.firstName || "",
    lastName: userData?.lastName || "",
    phone: userData?.phoneNumber || "",
    socialSecurityNumber: userData?.ssn || "",
    birthDate: moment(userData?.dateOfBirth || undefined).toDate(),
    address: userData?.address || "",
    suite: userData?.suite || "",
    city: userData?.city || "",
    state: getStateObject(userData?.state),
    zip: userData?.zip || "",
    email: userData?.email || "",
    role: getKeyManagerRole((userData?.title as BusinessKeyManagerTitle) || ""),
  };
};

export const convertBusinessDataResToFormData = (
  business?: BusinessStructureBusinessOwnersResponseDto,
): BusinessInformationFormType | {} => {
  if (isEmpty(business)) return {};

  const businessStructure = companyTypeSelectOptions.find(
    (companyType) => companyType.value === business?.businessStructure,
  ) || {
    label: CompanyEntityType.CORPORATION,
    value: CompanyEntityType.CORPORATION,
  };

  return {
    ownershipPercentage: business?.ownershipPercentage || 0,
    ein: business?.ein || "",
    companyName: business?.companyName || "",
    businessPhone: business?.phoneNumber || "",
    address: business?.address || "",
    city: business?.city || "",
    state: getStateObject(business?.state),
    zip: business?.zip || "",
    operatingName: "business.operatingName",
    businessFormationDate: moment(business?.dateOfFoundation || undefined).toDate(),
    stateOfIncorporation: getStateObject(business?.formationState as UserResponseDto["state"]),
    businessStructure: businessStructure,
  };
};

export const convertUserResponseToIndividualOwnerDto = (
  user: UserResponseDto,
): BusinessStructureOwnerResponseDto => {
  return {
    businessStructurePersonId: "",
    ownershipPercentage: 0,
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    phoneNumber: user?.phone || "",
    ssn: user?.socialSecurityNumber || "",
    dateOfBirth: moment(user?.birthDate).toISOString() || "",
    address: user?.address || "",
    suite: user?.suite || "",
    city: user?.city || "",
    state: user?.state as BusinessStructureOwnerResponseDto["state"],
    zip: user?.zip || "",
    email: user?.email || "",
  };
};

export const convertUserResponseToKeyManagerDto = (
  user: UserResponseDto,
): BusinessStructureKeyManagerResponseDto => {
  return {
    businessStructurePersonId: "",
    title: {} as any,
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    phoneNumber: user?.phone || "",
    ssn: user?.socialSecurityNumber || "",
    dateOfBirth: moment(user?.birthDate).toISOString() || "",
    address: user?.address || "",
    suite: user?.suite || "",
    city: user?.city || "",
    state: user?.state as BusinessStructureKeyManagerResponseDto["state"],
    zip: user?.zip || "",
    email: user?.email || "",
  };
};

export const convertIndividualOwnerFormToReqPayload = (
  values: IndividualOwnerFormType,
): BusinessOwnerPersonData => {
  return {
    phoneNumber: values?.phone || "",
    ssn: values?.socialSecurityNumber || "",
    dateOfBirth: moment(values?.birthDate).toISOString() || "",
    address: values?.address || "",
    suite: values?.suite || undefined,
    city: values?.city || "",
    state: values?.state.value || "",
    zip: values?.zip || "",
    firstName: values?.firstName || "",
    lastName: values?.lastName || "",
    email: values?.email || "",
    ownershipPercentage: +values?.ownershipPercentage || 0,
  };
};

export const convertKeyManagerFormToReqPayload = (
  values: BusinessKeyManagerFormType,
): CreateKeyManager => {
  return {
    phoneNumber: values?.phone || "",
    ssn: values?.socialSecurityNumber || "",
    dateOfBirth: moment(values?.birthDate).toISOString() || "",
    address: values?.address || "",
    suite: values?.suite || undefined,
    city: values?.city || "",
    state: values?.state.value || "",
    zip: values?.zip || "",
    firstName: values?.firstName || "",
    lastName: values?.lastName || "",
    email: values?.email || "",
    title: values?.role.value || "",
  };
};

export const convertBusinessFormToReqPayload = (
  values: BusinessInformationFormType,
): CreateBusinessOwnerBusinessDto => {
  return {
    companyName: values.companyName || "",
    dateOfFoundation: moment(values.businessFormationDate).toISOString() || "",
    formationState: values.stateOfIncorporation.value || "",
    businessStructure: values.businessStructure.value || "",
    phoneNumber: values.businessPhone || "",
    ein: values.ein || "",
    address: values.address || "",
    suite: values?.suite || undefined,
    city: values.city || "",
    state: values.state.value || "",
    zip: values.zip || "",
    ownershipPercentage: +values.ownershipPercentage || 0,
  };
};

export const getKybResponseType = (
  kybStatus?: KybStatus | undefined,
): EKycResponseType | undefined => {
  if (!kybStatus) return undefined;

  if (kybStatus === KybStatus.ACCEPTED) {
    return EKycResponseType.SUCCESS;
  }

  if (
    [
      KybStatus.PROVIDER_FAILURE,
      KybStatus.REJECTED,
      KybStatus.UNVERIFIED,
      KybStatus.VENDOR_ERROR,
    ].includes(kybStatus as KybStatus)
  ) {
    return EKycResponseType.ERROR;
  }

  if (
    [KybStatus.PENDING, KybStatus.PROVISIONAL, KybStatus.REVIEW].includes(kybStatus as KybStatus)
  ) {
    return EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG;
  }

  if (kybStatus === KybStatus.REJECTED) {
    return EKycResponseType.INCORRECT_INFO;
  }
};

import { FC } from "react";
import { Trans } from "react-i18next";
import { ChevronRight, Pencil, PhoneFilled } from "assets/svg";
import { useFormikContext } from "formik";

import { getHiddenNumber } from "helpers";
import { MFAWidgetType } from "components/MultiFactorAuthorization/types";

import {
  BottomButtonsContainer,
  CancelButton,
  Description,
  FormContainer,
  SubmitButton,
  Title,
  TitleContainer,
} from "../../../stepsStyles";
import { ESMSWidgetSteps } from "../../types";
import { IVerifyCodeStepPropsInner } from "../../types";
import { FormType } from "../../validationSchema";
import DefaultInput from "../DefaultInput/DefaultInput";
import {
  Container,
  EditPhoneButton,
  SelectedPhoneContainer,
  SPCIcon,
  SPCLabel,
  SPCText,
} from "./styles";

const DefaultForm: FC<IVerifyCodeStepPropsInner> = (props) => {
  const {
    className,
    title,
    description,
    mainButtonText,
    widgetType = MFAWidgetType.MISC,
    selectedMfaFactor,
    showButtons,
    maskPhoneNumber = true,
    showPhoneNumber = true,
    onSubmit,
    onCancel,
    formSubmitCallback,
    onEditPhone,
  } = props;
  const commonPrefix = `components.multi_factor_authorization.sms`;
  const translationPrefix = `${commonPrefix}.steps.${ESMSWidgetSteps.VERIFY_CODE}`;
  const formikContext = useFormikContext<FormType>();
  const phoneNumber = maskPhoneNumber
    ? getHiddenNumber(selectedMfaFactor?.phoneNumber || "", 4)
    : selectedMfaFactor?.phoneNumber || "";

  const handleSubmit = () => {
    formSubmitCallback?.();
    onSubmit?.(formikContext);
  };

  const handleCancel = () => {
    formSubmitCallback?.();
    onCancel?.();
  };

  return (
    <Container className={className}>
      <TitleContainer>
        <Title>{title || <Trans i18nKey={`${translationPrefix}.title`} />}</Title>
        <Description>
          {description || <Trans i18nKey={`${translationPrefix}.description`} />}
        </Description>
      </TitleContainer>

      {!!selectedMfaFactor && (
        <FormContainer>
          {!!showPhoneNumber && (
            <SelectedPhoneContainer>
              <SPCIcon>
                <PhoneFilled />
              </SPCIcon>
              <SPCLabel>
                <Trans i18nKey={`${translationPrefix}.phone_number_label`} />
              </SPCLabel>
              <SPCText>
                {phoneNumber}
                {(widgetType === MFAWidgetType.SETTINGS || widgetType === MFAWidgetType.SETUP) &&
                  onEditPhone && (
                    <EditPhoneButton
                      data-testid={"mfa-sms-verify-code-step-edit-phone-button"}
                      onClick={onEditPhone}
                    >
                      <Pencil />
                    </EditPhoneButton>
                  )}
              </SPCText>
            </SelectedPhoneContainer>
          )}

          <DefaultInput {...props} />

          {(!!showButtons || widgetType === MFAWidgetType.SETTINGS) && (
            <BottomButtonsContainer>
              <CancelButton
                data-testid={"mfa-sms-verify-code-step-cancel-button"}
                onClick={handleCancel}
              >
                <Trans i18nKey={`buttons.cancel`} />
              </CancelButton>
              <SubmitButton
                data-testid={"mfa-sms-verify-code-step-confirm-button"}
                onClick={handleSubmit}
                className={`${widgetType}`}
              >
                {mainButtonText || (
                  <Trans i18nKey={`${translationPrefix}.confirm_button.${widgetType}`} />
                )}
                <ChevronRight />
              </SubmitButton>
            </BottomButtonsContainer>
          )}
        </FormContainer>
      )}
    </Container>
  );
};

export default DefaultForm;

import { Form } from "formik";
import styled from "styled-components";

import AsyncUserSearchInput from "components/AsyncUserSearchInput";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const BackBtnWithArrow = styled.span`
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textInactiveAlt2};
  cursor: pointer;
  transition: opacity 0.3s;
  margin-bottom: 8px;
  user-select: none;

  svg {
    rotate: 180deg;
    margin-right: 8px;

    path {
      stroke: ${({ theme }) => theme.colors.textInactiveAlt2};
    }
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const StyledAsyncUserSearchInput = styled(AsyncUserSearchInput)`
  width: 50%;
  min-height: 250px;
`;

export const StyledFormikForm = styled(Form)`
  overflow: auto;
`;

export const FormContainerInner = styled.div``;

import styled from "styled-components";

import { SecondaryButtonIconed } from "uikit";

export const Container = styled.div``;

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  & > div {
    width: calc(50% - 8px);
  }
`;

export const StyledSecondaryButtonIconed = styled(SecondaryButtonIconed)`
  font-size: ${({ theme }) => theme.size.body2};
  padding: 0 12px;
  width: auto;
  white-space: nowrap;

  svg {
    width: 16px;
    height: 16px;

    &.user {
      width: 16px;
      height: 16px;

      path {
        fill: transparent;
        stroke: ${({ theme }) => theme.colors.primary};
      }
    }

    &.building {
      width: 16px;
      height: 16px;

      path {
        fill: transparent;
        stroke: ${({ theme }) => theme.colors.primary};
      }
    }

    &.user-star {
      path {
        fill: transparent;
        stroke: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

import { FC, ReactNode } from "react";
import { Trans } from "react-i18next";
import { OptionProps } from "react-select";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";

import { getName } from "helpers";
import {
  renderAsyncUserSearchHighlightedText,
  renderAsyncUserSearchNameWithEmail,
} from "components/AsyncUserSearchInput/renderElements";
import { OptionCell, StyledOption } from "components/AsyncUserSearchInput/styles";
import { BusinessStructureUserStatus } from "components/BusinessOwnersStructure/components";

import { Select } from "uikit";

import { UserResponseDto } from "utils/swagger_react_query";

import { FHDescription } from "../../styles";

interface IProps {
  potentialCardholdersList: Partial<UserResponseDto>[];
  onChange: (cardholder: Partial<UserResponseDto>) => void;
}

const CardholderSelect: FC<IProps> = ({ potentialCardholdersList, onChange }) => {
  const translationPrefix = `components.company_bank_account_components.company_bank_account_form`;

  const customFilterOption = (
    option: FilterOptionOption<Partial<UserResponseDto>>,
    inputValue: string,
  ) => {
    const searchTerm = inputValue.toLowerCase();
    const result =
      option.data?.firstName?.toLowerCase().includes(searchTerm) ||
      option.data?.lastName?.toLowerCase().includes(searchTerm) ||
      option.data?.email?.toLowerCase().includes(searchTerm);

    return !!result;
  };

  const renderSelectOption = (props: OptionProps<Partial<UserResponseDto>, false>) => {
    const { data, innerRef, innerProps } = props;
    const searchValue = props.selectProps.inputValue;

    return (
      <StyledOption
        ref={innerRef}
        {...innerProps}
        data-testid="async-user-search-result-option"
        onClick={(e) => {
          innerProps.onClick?.(e);
        }}
      >
        {renderAsyncUserSearchNameWithEmail(
          getName(data),
          renderAsyncUserSearchHighlightedText(data?.email, searchValue || ""),
        )}

        <OptionCell>
          <BusinessStructureUserStatus data={data} />
        </OptionCell>
      </StyledOption>
    );
  };

  return (
    <>
      <FHDescription>
        <Trans
          i18nKey={`${translationPrefix}.shipping_details.admin_form_header.user_select.description`}
        />
      </FHDescription>

      <Select
        name={"cardholder"}
        onChange={(e) => onChange(e as unknown as Partial<UserResponseDto>)}
        options={potentialCardholdersList}
        getOptionValue={(option) => option?.userId || ""}
        getOptionLabel={(option) =>
          getName({ firstName: option?.firstName || "", lastName: option?.lastName || "" }) || ""
        }
        filterOption={customFilterOption}
        inputMode="search"
        isSearchable={true}
        components={{
          Option: (props) => renderSelectOption({ ...props } as any),
        }}
      />
    </>
  );
};

export default CardholderSelect;

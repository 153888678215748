import { UserRole } from "types/BETypes";

import { UserProfileResponseDto } from "utils/swagger_react_query";

export const hasAdminRights = (user?: UserProfileResponseDto | null): boolean => {
  if (!user) {
    return false;
  }

  return [UserRole.ADMIN, UserRole.SUPERADMIN, UserRole.OWNER].includes(
    user?.lastActiveRole as UserRole,
  );
};

export const hasEmployeeRights = (user?: UserProfileResponseDto | null): boolean => {
  if (!user) {
    return false;
  }

  return [UserRole.EMPLOYEE].includes(user?.lastActiveRole as UserRole);
};

export const hasOwnerRights = (user?: UserProfileResponseDto | null): boolean => {
  if (!user) {
    return false;
  }

  return [UserRole.OWNER].includes(user?.lastActiveRole as UserRole);
};

import React, { FC } from "react";

import { Loader, LoaderContainer } from "./styles";

interface Props {
  className?: string;
  dataTestId?: string;
}

const UILoader: FC<Props> = ({ className, dataTestId }) => (
  <LoaderContainer className={className} data-testid={dataTestId}>
    <Loader>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Loader>
  </LoaderContainer>
);

export default UILoader;

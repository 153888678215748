import { FC, useState } from "react";
import { Trans } from "react-i18next";

import {
  ExternalBankAccountsSection,
  PaidBankAccountSection,
} from "components/BankAccountComponents";
import { IBankAccountSectionProps } from "components/BankAccountSection";
import CompanyBankAccountCreationModal from "components/CompanyBankAccountComponents/CompanyBankAccountCreationModal";
import { EWidgetType } from "components/PaymentMethods/types";

import { BCLeft, BCRight, ButtonsContainer, CancelButton } from "../styles";
import { useConnectBankStep } from "./useConnectBankStep";
import {
  AgreementBlock,
  AgreementBlockMessage,
  AgreementBlockTitle,
  AgreementCheckbox,
  Container,
  SubmitButton,
} from "./styles";

interface Props {
  className?: string;
  bankAccountSectionProps: Required<IBankAccountSectionProps>;
  onBack: () => void;
  onAgreementSubmit: () => void;
}

const ConnectBank: FC<Props> = ({
  className,
  bankAccountSectionProps,
  onBack,
  onAgreementSubmit,
}) => {
  const translationPrefix = `admin_setup_page.steps.connect_bank`;
  const defaultAccount = (bankAccountSectionProps.bankAccounts || [])?.find((it) => it.isDefault);
  const showAgreementBlock = !!defaultAccount;
  const { metadata, pageData, actions, modals } = useConnectBankStep();
  const { currentUser, currentCompany, isLoading } = metadata;

  const [isAgreementChecked, setAgreementChecked] = useState<boolean>(false);

  return (
    <Container className={className}>
      <PaidBankAccountSection
        currentUser={currentUser || undefined}
        bankAccounts={[]}
        onBannerBtnClick={actions.handleOpenSetupModal}
        widgetType={EWidgetType.ONBOARDING}
        setupCompleted={metadata.isMFAEnabled}
        onContinueSetupClick={actions.handleOpenSetupModal}
      />

      <ExternalBankAccountsSection
        userDetails={currentUser || undefined}
        bankAccounts={pageData.externalBankAccounts.bankAccounts || []}
        onConnectBankAccount={actions.externalBankAccounts.openModal}
        onRemoveBankAccount={actions.externalBankAccounts.handleRemoveBankAccount}
        onVerifyBankAccount={actions.externalBankAccounts.handleVerifyBankAccount}
      />

      {showAgreementBlock && (
        <AgreementBlock>
          <AgreementCheckbox
            checked={isAgreementChecked}
            onCheck={() => {
              setAgreementChecked(true);
            }}
            onUncheck={() => {
              setAgreementChecked(false);
            }}
            text={
              <Trans
                i18nKey={`${translationPrefix}.agreement_text`}
                components={{
                  1: <AgreementBlockTitle />,
                  2: <AgreementBlockMessage />,
                }}
                values={{
                  companyName: currentCompany?.name || "",
                }}
              />
            }
          />
        </AgreementBlock>
      )}

      <ButtonsContainer>
        <BCLeft>
          <CancelButton onClick={onBack} data-testid="back_button">
            <Trans i18nKey={`${translationPrefix}.back_button`} />
          </CancelButton>
        </BCLeft>

        {showAgreementBlock && (
          <BCRight>
            <SubmitButton
              onClick={onAgreementSubmit}
              disabled={!isAgreementChecked}
              data-testid="agreement_submit_button"
            >
              <Trans i18nKey={`${translationPrefix}.agreement_submit_button`} />
            </SubmitButton>
          </BCRight>
        )}
      </ButtonsContainer>

      <CompanyBankAccountCreationModal
        isOpen={modals.isCreationBusinessBankAccountModalOpen}
        setIsOpen={modals.setIsCreationBusinessBankAccountModalOpen}
        onFinished={actions.initFetch}
        refetchPageContentCallback={actions.initFetch}
      />
    </Container>
  );
};

export default ConnectBank;

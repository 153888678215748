import { FC } from "react";
import { Trans } from "react-i18next";
import { CheckmarkRoundFilled, LogoFull } from "assets/svg";

import { IProps } from "./types";
import useCBACards from "./useCBACards";
import {
  CBACardMainContent,
  CBCMATitleAlt,
  CBCMATop,
  CBCMATopLogo,
  CBCMATopTitle,
  CBCPromoCheckListCheck,
  CBCPromoCheckListCheckLabel,
  CBCPromoCheckListContainer,
  CBCPromoCheckListItem,
  CompanyEmphasized,
  ContainerAlt,
} from "./styles";

const CBACard2: FC<IProps> = (props) => {
  const { className } = props;

  const commonTranslationPrefix = `components.company_bank_account_promo_cards.shared`;
  const translationPrefix = `components.company_bank_account_promo_cards.card2`;

  const { baseConfig } = useCBACards(props);

  const renderLargeCompanyBankAccountCardContent = () => {
    return (
      <CBACardMainContent>
        <CBCMATop>
          <CBCMATopLogo>
            <LogoFull />
          </CBCMATopLogo>
          <CBCMATopTitle>
            <Trans i18nKey={`${commonTranslationPrefix}.title_common`} />
          </CBCMATopTitle>
        </CBCMATop>
        <CBCMATitleAlt>
          <Trans i18nKey={`${translationPrefix}.title`} />
        </CBCMATitleAlt>
        <CBCPromoCheckListContainer>
          <CBCPromoCheckListItem>
            <CBCPromoCheckListCheck>
              <CheckmarkRoundFilled />
            </CBCPromoCheckListCheck>
            <CBCPromoCheckListCheckLabel>
              <Trans
                i18nKey={`${commonTranslationPrefix}.promo_points.p1`}
                components={{ 1: <span />, 2: <span />, 3: <span /> }}
              />
            </CBCPromoCheckListCheckLabel>
          </CBCPromoCheckListItem>

          <CBCPromoCheckListItem>
            <CBCPromoCheckListCheck>
              <CheckmarkRoundFilled />
            </CBCPromoCheckListCheck>
            <CBCPromoCheckListCheckLabel>
              <Trans
                i18nKey={`${commonTranslationPrefix}.promo_points.p2`}
                components={{ 1: <span />, 2: <span />, 3: <span /> }}
              />
            </CBCPromoCheckListCheckLabel>
          </CBCPromoCheckListItem>

          <CBCPromoCheckListItem>
            <CBCPromoCheckListCheck>
              <CheckmarkRoundFilled />
            </CBCPromoCheckListCheck>
            <CBCPromoCheckListCheckLabel>
              <Trans
                i18nKey={`${commonTranslationPrefix}.promo_points.p3`}
                components={{ 1: <span />, 2: <span />, 3: <span /> }}
              />
            </CBCPromoCheckListCheckLabel>
          </CBCPromoCheckListItem>
        </CBCPromoCheckListContainer>
      </CBACardMainContent>
    );
  };

  const cardConfig = {
    title: (
      <Trans
        i18nKey={`${translationPrefix}.title`}
        components={{
          1: <CompanyEmphasized />,
        }}
      />
    ),
    description: (
      <Trans
        i18nKey={`${translationPrefix}.description`}
        components={{
          1: <CompanyEmphasized />,
        }}
      />
    ),
    largeContent: renderLargeCompanyBankAccountCardContent(),
  };

  const config: IProps = {
    ...baseConfig,
    ...cardConfig,
    ...props,
  };

  return <ContainerAlt className={className} {...config} />;
};

export default CBACard2;

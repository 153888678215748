import { EKycResponseType } from "components/CompanyBankAccountComponents/employee/KYCVerification/types";

export interface IBusinessOwnersStructureProps {
  widgetType: BusinessOwnersStructureWidgetType;
  emphasizedContainer?: boolean;
  className?: string;
  fetchDataOnMount?: boolean;
  onStatusSectionPrimaryBtnClick?: (status: EKycResponseType) => void;
  onKybSuccess?: () => void;
  onBackClick?: () => void;
}

export type IUseBusinessOwnerStructureParams = Pick<
  IBusinessOwnersStructureProps,
  "fetchDataOnMount"
>;

export enum BusinessOwnersStructureWidgetType {
  SETUP = "SETUP",
  VIEW = "VIEW",
}

export type IUseKybVerificationParams = Pick<
  IBusinessOwnersStructureProps,
  "onKybSuccess" | "onStatusSectionPrimaryBtnClick"
>;

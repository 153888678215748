import { FC } from "react";
import { Trans } from "react-i18next";
import { PlusThin } from "assets/svg";

import DashboardLayout from "layouts/DashboardLayout";
import { BankingDisclosureContainer } from "pages/Banking/shared/styles";
import {
  BankAccountConnectionMethodModal,
  ExternalBankAccountsSection,
  PaidBankAccountSection,
} from "components/BankAccountComponents";
import BankingDisclosureComponent from "components/BankingDisclosureComponent";
import CompanyBankAccountCreationModal from "components/CompanyBankAccountComponents/CompanyBankAccountCreationModal";

import { FullScreenLoader } from "uikit";

import { useEmployeeBankAccountsPage } from "./useEmployeeBankAccountsPage";
import { AddAccountButton, BackButton } from "./styles";

const EmployeeBankAccountsPage: FC = () => {
  const { metadata, pageData, actions, modals } = useEmployeeBankAccountsPage();
  const { translationPrefix } = metadata;

  return (
    <DashboardLayout
      showResponsiveMenu={false}
      onBackBtnClick={() => actions.handleBackBtnClick()}
      titleRightContent={
        <AddAccountButton
          data-testid={"add_bank_account_btn"}
          onClick={actions.handleOpenChooseMethodModal}
        >
          <PlusThin />
          <Trans i18nKey={`${translationPrefix}.connect_bank_account_btn`} />
        </AddAccountButton>
      }
    >
      {metadata.isLoading ? (
        <FullScreenLoader />
      ) : (
        <>
          <PaidBankAccountSection
            currentUser={metadata.currentUser || undefined}
            onBankInUseClick={actions.onBankInUseClick}
            bankAccounts={pageData.companyBankAccount?.id ? [pageData.companyBankAccount] : []}
            onBannerBtnClick={actions.onPaidBankBannerBtnClick}
            setupCompleted={metadata.isMFAEnabled}
            onContinueSetupClick={actions.onPaidBankBannerBtnClick}
          />

          <ExternalBankAccountsSection
            userDetails={metadata.currentUser || undefined}
            bankAccounts={pageData.externalBankAccounts.bankAccounts || []}
            onConnectBankAccount={actions.externalBankAccounts.openModal}
            onRemoveBankAccount={actions.externalBankAccounts.handleRemoveBankAccount}
            onVerifyBankAccount={actions.externalBankAccounts.handleVerifyBankAccount}
            onBankInUseClick={actions.onBankInUseClick}
          />

          <BackButton onClick={actions.handleBackBtnClick} data-testid={"back-button"}>
            <Trans i18nKey={`buttons.back`} />
          </BackButton>

          <BankingDisclosureContainer>
            <BankingDisclosureComponent />
          </BankingDisclosureContainer>
        </>
      )}

      {modals.bankAccountConnectionMethodModal.isBankAccountConnectionMethodModalOpen && (
        <BankAccountConnectionMethodModal
          isOpen={modals.bankAccountConnectionMethodModal.isBankAccountConnectionMethodModalOpen}
          onClose={() =>
            modals.bankAccountConnectionMethodModal.setBankAccountConnectionMethodModalOpen(false)
          }
          onContinue={actions.handleChooseMethod}
        />
      )}

      <CompanyBankAccountCreationModal
        isOpen={modals.companyBankAccountCreation.isCreationCompanyBankAccountModalOpen}
        setIsOpen={modals.companyBankAccountCreation.setIsCreationCompanyBankAccountModalOpen}
        onFinished={actions.initFetch}
        hideHeader={metadata.shouldUserOrderPhysicalCard && metadata.isMFAEnabled}
        isShippingStepOnly={metadata.shouldUserOrderPhysicalCard && metadata.isMFAEnabled}
        refetchPageContentCallback={actions.initFetch}
        data={{
          bankCardsList: pageData.bankCards,
        }}
      />
    </DashboardLayout>
  );
};

export default EmployeeBankAccountsPage;

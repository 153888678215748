import { FC, useState } from "react";
import { Trans } from "react-i18next";
import Select from "react-select";

import { UserRole } from "types/BETypes";
import { getName, getUserRoleName } from "helpers";
import { renderCopyButton } from "components/CompanyBankAccountComponents/components/renderElements";

import { FormLabelSmall, InputContainer } from "uikit";
import {
  Container as SelectContainer,
  SelectWrapper as SelectContainerInner,
} from "uikit/Input/styles";

import { UserResponseDto } from "utils/swagger_react_query";

import { renderAsyncUserSearchInputOptionWithRole } from "./renderElements";
import { IAsyncUserSearchInputProps } from "./types";
import {
  Container,
  OptionCell,
  OptionUserEmailText,
  OptionUserFullNameText,
  OptionUserRoleText,
  SelectedOptionContainer,
  StyledAvatar,
  WarningIcon,
  WarningSection,
  WSText,
} from "./styles";

const AsyncUserSearchInput: FC<IAsyncUserSearchInputProps> = ({
  label,
  searchValue,
  name,
  error,
  warning,
  placeholder,
  dataTestid,
  selectedOption,
  className,
  searchResult,
  onSelect,
  isLoading,
  emptyDataMessage,
  onSearch,
  disabled,
  showSelectedOptionBelow,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onOptionClick = (data: Partial<UserResponseDto>) => {
    setIsMenuOpen(false);
    onSelect(data);
  };

  return (
    <Container className={className}>
      <InputContainer>
        {label && <FormLabelSmall>{label}</FormLabelSmall>}
        <SelectContainer className={`custom-select`}>
          <SelectContainerInner
            isDisabled={!!disabled}
            data-testid={dataTestid || "async-user-search-input"}
            className={`${disabled ? "disabled" : ""}`}
          >
            <Select
              name={name}
              isSearchable={true}
              inputValue={searchValue}
              onInputChange={(val, action) => {
                if (action.action === "input-change") {
                  onSearch(val);
                }
              }}
              isLoading={isLoading}
              escapeClearsValue={false}
              options={searchResult}
              getOptionValue={(option) => option?.userId || ""}
              getOptionLabel={(option) => option?.email || ""}
              filterOption={() => true}
              placeholder={placeholder}
              isDisabled={disabled}
              menuIsOpen={isMenuOpen}
              onFocus={() => setIsMenuOpen(true)}
              onBlur={() => setIsMenuOpen(false)}
              closeMenuOnSelect={true}
              openMenuOnFocus={true}
              noOptionsMessage={() => emptyDataMessage}
              components={{
                DropdownIndicator: null,
                Option: (props) =>
                  renderAsyncUserSearchInputOptionWithRole({
                    ...props,
                    onOptionClick,
                    searchValue,
                  }),
              }}
            />
          </SelectContainerInner>
        </SelectContainer>
      </InputContainer>

      {warning && (
        <WarningSection>
          <WarningIcon />
          <WSText>
            <Trans
              i18nKey={`errors.${warning}`}
              components={{
                1: renderCopyButton(),
              }}
            />
          </WSText>
        </WarningSection>
      )}

      {showSelectedOptionBelow && selectedOption && !isLoading && (
        <SelectedOptionContainer>
          <OptionCell>
            <OptionUserRoleText>
              <StyledAvatar
                firstName={selectedOption?.firstName}
                lastName={selectedOption?.lastName}
              />
            </OptionUserRoleText>
          </OptionCell>

          <OptionCell className="column max_content">
            <OptionUserFullNameText>{getName(selectedOption)}</OptionUserFullNameText>
            <OptionUserEmailText>{selectedOption?.email}</OptionUserEmailText>
          </OptionCell>

          <OptionCell>
            <OptionUserRoleText className={`${selectedOption?.lastActiveRole}`}>
              {getUserRoleName(selectedOption?.lastActiveRole as UserRole)}
            </OptionUserRoleText>
          </OptionCell>
        </SelectedOptionContainer>
      )}
    </Container>
  );
};

export default AsyncUserSearchInput;

import { useState } from "react";
import { useSelector } from "react-redux";
import { FormikProps } from "formik";
import { isEmpty } from "lodash";
import { userMetadataSelector } from "store/selectors";

import { getErrorMessageFromResponse } from "helpers/shared/errors";
import useAuth from "hooks/useAuth";

import { mutationUsersControllerUpdateEarlyPay } from "utils/swagger_react_query";

import { EarlyPayFormType } from "./components";
import { getIsEarlyPayStepCompleted } from "./helpers";
import { IUseEarlyPayParams } from "./types";

type IOnSubmitParams = Pick<FormikProps<EarlyPayFormType>, "values" | "validateForm">;

export const useEarlyPay = ({ onSubmitSuccess }: IUseEarlyPayParams) => {
  const { getCurrentUser } = useAuth();
  const currentUser = useSelector(userMetadataSelector);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const initValues: EarlyPayFormType = {
    earlyPayEnabled: getIsEarlyPayStepCompleted(currentUser),
  };

  const handleSubmitEarlyPay = async ({ values, validateForm }: IOnSubmitParams) => {
    try {
      setIsSubmitting(true);
      const errors = await validateForm(values);
      if (!isEmpty(errors)) return;

      await mutationUsersControllerUpdateEarlyPay()({
        isEarlyPayEnabled: !!values.earlyPayEnabled,
      });
      await getCurrentUser();
      onSubmitSuccess?.();
    } catch (error) {
      setErrorMessage(getErrorMessageFromResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    errorMessage,
    initValues,
    handleSubmitEarlyPay,
  };
};

import React, { FC } from "react";
import { Trans } from "react-i18next";

import { COMPANY_ESIGN_CONSENT_DISCLOSURE } from "constants/assets";
import { HELP_CENTER, PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "constants/shared";

import { FullScreenLoader } from "uikit";

import {
  Description,
  ECLabel,
  EmailContainer,
  Footer,
  FooterLinkExternal,
  StyledEmailWithIcon,
  StyledPrimaryBtn,
  TermsAndPrivacyDisclaimer,
  Title,
} from "../_shared/components/styles";
import { employeeAuthLeftSectionParams } from "../_shared/constants";
import { useEmployeeSignUp } from "./useEmployeeSignUp";
import { Container, Section, StyledAuthLayout, TermsAndPrivacyDisclaimerSection } from "./styles";

const EmployeeSignUp: FC = () => {
  const { metadata, actions } = useEmployeeSignUp();

  const translationPrefix = "auth_pages.employee.sign_up_page";

  if (metadata.pageLoader) return <FullScreenLoader />;

  return (
    <StyledAuthLayout
      isLoading={metadata.isLoading}
      leftSectionParams={employeeAuthLeftSectionParams()}
    >
      <Container>
        <Title>
          <Trans i18nKey={`${translationPrefix}.title`} />
        </Title>
        <Description>
          <Trans i18nKey={`${translationPrefix}.description`} />
        </Description>

        <Section>
          <EmailContainer>
            <ECLabel>
              <Trans i18nKey={`${translationPrefix}.email_label`} />
            </ECLabel>
            <StyledEmailWithIcon email={metadata?.userInfo?.email || ""} />
          </EmailContainer>

          <TermsAndPrivacyDisclaimerSection>
            <TermsAndPrivacyDisclaimer style={{ margin: 0 }}>
              <Trans
                i18nKey={`auth_pages.shared.terms_and_policy_disclaimer`}
                components={{
                  1: (
                    <FooterLinkExternal
                      href={TERMS_AND_CONDITIONS}
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                  2: <FooterLinkExternal href={PRIVACY_POLICY} target="_blank" rel="noreferrer" />,
                  3: (
                    <FooterLinkExternal
                      href={`${COMPANY_ESIGN_CONSENT_DISCLOSURE}?t=${Date.now()}`}
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            </TermsAndPrivacyDisclaimer>
          </TermsAndPrivacyDisclaimerSection>
        </Section>

        <StyledPrimaryBtn type="button" onClick={actions.handleAcceptInvitation}>
          <Trans i18nKey={`${translationPrefix}.accept_invitation_btn`} />
        </StyledPrimaryBtn>

        <Footer>
          <FooterLinkExternal href={HELP_CENTER} target="_blank">
            <Trans i18nKey={`buttons.need_help_btn`} />
          </FooterLinkExternal>
        </Footer>
      </Container>
    </StyledAuthLayout>
  );
};

export default EmployeeSignUp;

import { useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { UserRole } from "types/BETypes";
import { isKybAccepted, isKybPending, isKybRejected } from "helpers/shared/kyb";
import {
  isKycPending as getIsKycPending,
  isKycRejected as getIsKycRejected,
} from "helpers/shared/kyc";
import { getIsEarlyPayStepCompleted } from "components/CompanyBankAccountComponents/employee/EarlyPay";

import { isPhysicalCardActiveOrUnactivated } from "../../CompanyCardShippingDetails/helpers";
import {
  COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS,
  COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS,
} from "../constants";
import {
  COMPANY_BANK_ACCOUNT_CREATION_CURRENT_STEP,
  IDetermineInitialStepParams,
  IUseStepController,
} from "../types";

export const useStepController = (params: IUseStepController) => {
  const { isShippingStepOnly } = params;
  const currentUser = useSelector(userMetadataSelector);
  const currentCompany = useSelector(companyMetadataSelector);

  const [currentStep, setCurrentStep] = useState<COMPANY_BANK_ACCOUNT_CREATION_CURRENT_STEP>(null);
  const isAdmin = [UserRole.ADMIN, UserRole.SUPERADMIN, UserRole.OWNER].includes(
    currentUser?.lastActiveRole as UserRole,
  );
  const isEarlyPayStepCompleted = getIsEarlyPayStepCompleted(currentUser);

  const isKycPending = getIsKycPending(currentUser);
  const isKycRejected = getIsKycRejected(currentUser);

  const determineInitialStep = (params: IDetermineInitialStepParams) => {
    const { bankCards, businessOwnershipStructure } = params;
    const isPhysicalBankCardOrdered = bankCards.some(isPhysicalCardActiveOrUnactivated);
    const showKycStep = isKycRejected || isKycPending || !currentUser?.kycStatus;
    const showEarlyPayStep = !isEarlyPayStepCompleted;
    const showShippingStep = isEarlyPayStepCompleted && !isPhysicalBankCardOrdered;

    if (currentUser?.lastActiveRole === UserRole.EMPLOYEE) {
      if (isShippingStepOnly) return COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS.SHIPPING_DETAILS;

      if (showKycStep) return COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS.KYC_VERIFICATION;
      if (showEarlyPayStep) return COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS.EARLY_PAY;
      if (showShippingStep) return COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS.SHIPPING_DETAILS;

      return COMPANY_BANK_ACCOUNT_CREATION_EMPLOYEE_STEPS.MFA_CONFIGURATION;
    }

    if (isAdmin) {
      const kybIsAccepted = isKybAccepted(currentCompany);
      const kybIsPending = isKybPending(currentCompany);
      const kybIsRejected = isKybRejected(currentCompany);

      const showBusinessInfoStep = isEmpty(businessOwnershipStructure?.business);
      const showCompanyStructureStep = !currentCompany?.kybStatus || kybIsPending || kybIsRejected;
      const showShippingDetailsStep =
        kybIsAccepted && bankCards.some(isPhysicalCardActiveOrUnactivated);

      if (showBusinessInfoStep) return COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS.BUSINESS_INFO;
      if (showCompanyStructureStep)
        return COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS.COMPANY_STRUCTURE;
      if (showShippingDetailsStep)
        return COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS.SHIPPING_DETAILS;

      return COMPANY_BANK_ACCOUNT_CREATION_ADMIN_STEPS.MFA_CONFIGURATION;
    }

    return null;
  };

  return {
    currentStep,
    setCurrentStep,
    determineInitialStep,
  };
};

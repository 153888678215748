import { useCallback, useEffect, useRef } from "react";
import { useMergeLink } from "@mergeapi/react-merge-link";

import { parseCsv, showErrorModal } from "helpers/index";

import {
  HrisLinkTokenResDto,
  mutationHrisIntegrationControllerDeleteHrisIntegration,
  mutationHrisIntegrationControllerExchangePublicToken,
  mutationHrisIntegrationControllerGenerateLinkToken,
  queryHrisIntegrationControllerGetErrorsReport,
} from "utils/swagger_react_query";

interface IParams {
  loadingCallback: (state: boolean) => void;
  refetchCallback: () => Promise<void>;
  handleOpenSuccessModal: () => void;
}

interface IIntegrationLinkDetails extends HrisLinkTokenResDto {
  publicToken?: string;
}

export const useHRISIntegration = (params: IParams) => {
  const integrationLinkDetails = useRef<IIntegrationLinkDetails>();

  const onSuccess = useCallback((publicToken: string) => _exchangePublicToken(publicToken), []);

  const onExit = useCallback(async () => {
    if (integrationLinkDetails.current?.integrationId) {
      await removeIntegration(integrationLinkDetails.current?.integrationId || "");
      return (integrationLinkDetails.current = undefined);
    }
    if (!integrationLinkDetails.current) return params.handleOpenSuccessModal();
  }, []);

  const { open, isReady } = useMergeLink({
    linkToken: integrationLinkDetails.current?.linkToken,
    onSuccess: onSuccess,
    onExit: onExit,
  });

  useEffect(() => {
    if (isReady && integrationLinkDetails.current?.linkToken) open();
  }, [isReady, integrationLinkDetails.current?.linkToken, open]);

  const _exchangePublicToken = async (publicToken: string) => {
    try {
      await mutationHrisIntegrationControllerExchangePublicToken({
        integrationId: integrationLinkDetails.current?.integrationId!,
      })({ publicToken });

      integrationLinkDetails.current = undefined;
    } catch (error) {
      showErrorModal(error);
    }
  };

  const openIntegrationModal = async (integrationId: string | null = null) => {
    try {
      params?.loadingCallback(true);
      const res = await mutationHrisIntegrationControllerGenerateLinkToken()({
        integrationId: integrationId,
      });

      integrationLinkDetails.current = res;
    } catch (error) {
      showErrorModal(error);
    } finally {
      params?.loadingCallback(false);
    }
  };

  const downloadErrorsFile = async (integrationId: string) => {
    try {
      const errorFileRes = await queryHrisIntegrationControllerGetErrorsReport({
        integrationId: integrationId,
      });
      const errorFileBlob = parseCsv(errorFileRes as any);
      const url = window.URL.createObjectURL(errorFileBlob);
      const link = document.createElement("a");
      link.href = url || "";
      link.setAttribute("download", "employees-csv-integration-errors-file");
      link.click();
    } catch (error) {
      showErrorModal(error);
    }
  };

  const removeIntegration = async (integrationId: string) => {
    try {
      await mutationHrisIntegrationControllerDeleteHrisIntegration({
        integrationId: integrationId,
      })();
    } catch (error) {
      showErrorModal(error);
    }
  };

  return {
    openIntegrationModal,
    downloadErrorsFile,
    removeIntegration,
  };
};

import { FC, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EyeIcon } from "assets/svg";
import { format as ssnFormat } from "parse-ssn";

import Input from "./Input";
import { BaseInputProps } from "./types";
import { MaskedInputWrapper, MaskedNumberInputIconWrapper } from "./styles";

export type Props = {
  error?: ReactNode;
  label?: ReactNode;
  value: string;
  onSetFormField?: (value: string) => void;
  needHide?: boolean;
  disabled?: boolean;
  placeholder?: string;
} & BaseInputProps;

const SsnInput: FC<Props> = ({ error, label, onSetFormField, value, disabled, ...rest }) => {
  const { t } = useTranslation();
  const [isHidden, setHidden] = useState<boolean>(true);
  const [ssn, setSsn] = useState(value);

  //TODO: Check if this hook is necessary
  useEffect(() => {
    setSsn(value);
  }, [value]);

  const handleChange = (_value: string) => {
    const serializedValue = _value.replace(/-/g, "");
    const serializedSsn = ssn.replace(/-/g, "");
    if (serializedValue.length > 9) return;

    let newSsn = serializedValue.slice(0, 9);

    if (isHidden && onSetFormField) {
      if (serializedValue.length < serializedSsn.length) {
        const last = serializedSsn.length - 1;
        newSsn = serializedSsn.slice(0, last);
        onSetFormField(ssnFormat(newSsn));
        setSsn(newSsn);
        return;
      }

      const numValue = _value.replace(/\D/g, "").slice(0, 4);
      if (serializedSsn.length > 5) {
        newSsn = serializedSsn.slice(0, 5) + numValue;
      } else {
        newSsn = serializedSsn + numValue;
      }
      if (newSsn.length > 9) return;
    }
    onSetFormField && onSetFormField(ssnFormat(newSsn));
    setSsn(newSsn);
  };

  const mask = (_value: string) => {
    const masked = _value.slice(0, 7).replace(/[0-9]/g, "*");
    return masked + _value.slice(7);
  };

  return (
    <>
      <MaskedInputWrapper>
        {onSetFormField && (
          <MaskedNumberInputIconWrapper
            onClick={() => setHidden(!isHidden)}
            data-testid={"ssn-visibility-btn"}
          >
            <EyeIcon isCrossedOut={isHidden} />
          </MaskedNumberInputIconWrapper>
        )}
        <Input
          name="socialSecurityNumber"
          onChange={(e) => handleChange(e.currentTarget.value)}
          value={isHidden ? mask(ssnFormat(ssn || "")) : ssnFormat(ssn || "")}
          label={label}
          error={error}
          disabled={disabled}
          errorDataTestId="error-social-security-number"
          {...rest}
        />
      </MaskedInputWrapper>
    </>
  );
};

export default SsnInput;

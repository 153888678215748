import { isEmail } from "class-validator";
import * as yup from "yup";

import { CompanyEntityType } from "types/BETypes";
import {
  EIN_LENGTH_WITH_MASK,
  MAX_INPUT_LENGTH,
  SSN_LENGTH_WITH_MASK,
  ZIP_CODE_LENGTH,
} from "constants/form";
import { getPhoneNumberOrNull, validatePhoneNumber } from "helpers";

import { userNameRegExp } from "./regExp";
import { EStates, EStatesShort } from "./states";

const inputBaseRules = {
  inputStringBase: yup.string().max(MAX_INPUT_LENGTH, "validationErrors.common.too_long"),
};

export const commonValidationRules = {
  ...inputBaseRules,
  phone: yup
    .string()
    .required("validationErrors.common.is_required")
    .test(
      "invalid phone",
      "validationErrors.common.wrong_format",
      (value) => !value || validatePhoneNumber(value),
    )
    .test(
      "required phone",
      "validationErrors.common.is_required",
      (value) => !!getPhoneNumberOrNull(value),
    ),
  date: yup
    .date()
    .nullable()
    .typeError("validationErrors.common.is_required")
    .required("validationErrors.common.is_required"),
  email: inputBaseRules.inputStringBase
    .test(
      "invalid email",
      "validationErrors.common.wrong_format",
      (value) => !value || isEmail(value),
    )
    .required("validationErrors.common.is_required"),
};

const commonAddressRules = {
  address: commonValidationRules.inputStringBase.required("validationErrors.common.is_required"),
  suite: commonValidationRules.inputStringBase.optional(),
  city: commonValidationRules.inputStringBase.required("validationErrors.common.is_required"),
  state: yup
    .object({
      label: yup
        .mixed<EStates>()
        .oneOf(Object.values(EStates) as EStates[])
        .required("validationErrors.common.is_required"),
      value: yup
        .mixed<EStatesShort>()
        .oneOf(Object.values(EStatesShort) as EStatesShort[])
        .required("validationErrors.common.is_required"),
    })
    .required("validationErrors.common.is_required"),
  zip: yup
    .string()
    .length(ZIP_CODE_LENGTH, "validationErrors.common.wrong_format")
    .required("validationErrors.common.is_required"),
};

export const addressValidationRulesBase = {
  ...commonAddressRules,
};

export const userDetailsValidationRulesBase = {
  firstName: commonValidationRules.inputStringBase
    .test("invalid first name", "validationErrors.common.invalid_symbols", function (value) {
      const val = value?.trim();
      return !val || userNameRegExp.test(value?.trim() || "");
    })
    .required("validationErrors.common.is_required"),
  lastName: commonValidationRules.inputStringBase
    .test("invalid last name", "validationErrors.common.invalid_symbols", function (value) {
      const val = value?.trim();
      return !val || userNameRegExp.test(value?.trim() || "");
    })
    .required("validationErrors.common.is_required"),
  phone: commonValidationRules.phone,
  socialSecurityNumber: yup
    .string()
    .length(SSN_LENGTH_WITH_MASK, "validationErrors.common.wrong_format")
    .required("validationErrors.common.is_required"),
  birthDate: commonValidationRules.date,
};

export const businessInformationValidationRulesBase = {
  companyName: commonValidationRules.inputStringBase.required(
    "validationErrors.common.is_required",
  ),
  operatingName: commonValidationRules.inputStringBase.required(
    "validationErrors.common.is_required",
  ),
  businessFormationDate: commonValidationRules.date,
  stateOfIncorporation: commonAddressRules.state,
  businessStructure: yup
    .object({
      label: yup.string().required("validationErrors.common.is_required"),
      value: yup
        .mixed<CompanyEntityType>()
        .oneOf(Object.values(CompanyEntityType) as CompanyEntityType[])
        .required("validationErrors.common.is_required"),
    })
    .required("validationErrors.common.is_required"),
  businessPhone: commonValidationRules.phone,
  ein: yup
    .string()
    .length(EIN_LENGTH_WITH_MASK, "validationErrors.common.wrong_format")
    .required("validationErrors.common.is_required"),
};

import { FC, ReactNode } from "react";
import { Trans } from "react-i18next";

import { getComprehensibleBusinessStructureUserStatus } from "helpers/employee/userStatusMap";

import { UserResponseDto } from "utils/swagger_react_query";

import { Label, Wrapper } from "./styles";

interface IProps {
  data: Partial<UserResponseDto>;
  className?: string;
  children?: ReactNode;
}

const BusinessStructureUserStatus: FC<IProps> = ({ data, className }) => {
  const transformedStatus = getComprehensibleBusinessStructureUserStatus(data);
  const translationPrefix = "common.business_owner_structure_user_status";
  return (
    <Wrapper className={className}>
      <Label className={transformedStatus}>
        {transformedStatus ? <Trans i18nKey={`${translationPrefix}.${transformedStatus}`} /> : "-"}
      </Label>
    </Wrapper>
  );
};

export default BusinessStructureUserStatus;

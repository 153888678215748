import * as yup from "yup";

import { BusinessKeyManagerTitle } from "types/BETypes";
import {
  addressValidationRulesBase,
  commonValidationRules,
  userDetailsValidationRulesBase,
} from "constants/shared/validationRules";

export const BusinessKeyManagerFormValidationSchema = yup.object({
  ...addressValidationRulesBase,
  ...userDetailsValidationRulesBase,
  email: commonValidationRules.email,
  role: yup
    .object({
      label: yup.string().required("validationErrors.common.is_required"),
      value: yup
        .mixed<BusinessKeyManagerTitle>()
        .oneOf(Object.values(BusinessKeyManagerTitle) as BusinessKeyManagerTitle[])
        .required("validationErrors.common.is_required"),
    })
    .required("validationErrors.common.is_required"),
});

export type BusinessKeyManagerFormType = yup.InferType<
  typeof BusinessKeyManagerFormValidationSchema
>;

import { FormikContextType } from "formik";

import { MFAWidgetType } from "../../types";
import { FormType as EnterPhoneFormType } from "./steps/EnterPhone/validationSchema";
import { FormType as VerifyCodeFormType } from "./steps/VerifyCode/validationSchema";

export interface IProps {
  className?: string;
  widgetType: MFAWidgetType;
  defaultPhone?: string | null;
  fetchDataOnMount?: boolean;
  onEnterPhoneSubmit?: (formikContext: FormikContextType<EnterPhoneFormType>) => void;
  onEnterPhoneCancel?: () => void;
  onVerifyCodeSubmit?: (formikContext: FormikContextType<VerifyCodeFormType>) => void;
  onVerifyCodeCancel?: () => void;
  onSubmitSuccess?: () => void;
}

export const enum ESMSWidgetSteps {
  ENTER_PHONE = 0,
  VERIFY_CODE = 1,
  SUCCESS = 2,
}

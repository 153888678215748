import { isNull } from "lodash";

import { UserResponseDto } from "utils/swagger_react_query";

export const getIsEarlyPayStepCompleted = (
  currentUser: UserResponseDto | null | undefined,
): boolean => {
  const isCompleted = !isNull(currentUser?.isEarlyPayEnabled);

  return isCompleted;
};

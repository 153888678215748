import {
  BusinessStructureBusinessOwnersResponseDto,
  BusinessStructureKeyManagerResponseDto,
  BusinessStructureOwnerResponseDto,
} from "utils/swagger_react_query";

import { BusinessStructureEntityCardData, BusinessStructureEntityType } from "./types";

const isCompanyData = (
  data:
    | BusinessStructureKeyManagerResponseDto
    | BusinessStructureOwnerResponseDto
    | BusinessStructureBusinessOwnersResponseDto,
): boolean => {
  const requiredProps = ["companyName", "businessStructure", "ownershipPercentage"];
  const missingProps = ["firstName", "lastName"];

  return (
    requiredProps.every((prop) => prop in data) && missingProps.every((prop) => !(prop in data))
  );
};

const isKeyManagerData = (
  data:
    | BusinessStructureKeyManagerResponseDto
    | BusinessStructureOwnerResponseDto
    | BusinessStructureBusinessOwnersResponseDto,
): boolean => {
  const requiredProps = ["firstName", "lastName", "title"];
  const missingProps = ["ownershipPercentage"];

  return (
    requiredProps.every((prop) => prop in data) && missingProps.every((prop) => !(prop in data))
  );
};
const isIndividualOwnerData = (
  data:
    | BusinessStructureKeyManagerResponseDto
    | BusinessStructureOwnerResponseDto
    | BusinessStructureBusinessOwnersResponseDto,
): boolean => {
  const requiredProps = ["firstName", "lastName", "ownershipPercentage"];

  return requiredProps.every((prop) => prop in data);
};

export const convertBusinessStructureInfoItemToCardData = (
  data?:
    | BusinessStructureKeyManagerResponseDto
    | BusinessStructureOwnerResponseDto
    | BusinessStructureBusinessOwnersResponseDto,
): BusinessStructureEntityCardData | undefined => {
  if (!data) return;

  if (isCompanyData(data)) {
    const companyData = data as BusinessStructureBusinessOwnersResponseDto;
    return {
      businessStructureBusinessId: companyData.businessStructureBusinessId,
      name: companyData.companyName,
      entityType: BusinessStructureEntityType.BUSINESS,
      ownershipPercentage: companyData?.ownershipPercentage || 0,
    };
  }

  if (isKeyManagerData(data)) {
    const keyManagerData = data as BusinessStructureKeyManagerResponseDto;
    return {
      businessStructurePersonId: keyManagerData.businessStructurePersonId,
      name: `${keyManagerData.firstName} ${keyManagerData.lastName}`,
      entityType: BusinessStructureEntityType.KEY_MANAGER,
    };
  }

  if (isIndividualOwnerData(data)) {
    const individualOwnerData = data as BusinessStructureOwnerResponseDto;
    return {
      businessStructurePersonId: individualOwnerData.businessStructurePersonId,
      name: `${individualOwnerData.firstName} ${individualOwnerData.lastName}`,
      entityType: BusinessStructureEntityType.OWNER,
      ownershipPercentage: individualOwnerData.ownershipPercentage,
    };
  }

  return undefined;
};

import React, { ButtonHTMLAttributes, CSSProperties, FunctionComponent, ReactNode } from "react";

export interface IContentAndButtonsWrapper {
  children?: ReactNode;
}

export interface IModalButton {
  text?: string | React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  type?: ButtonHTMLAttributes<unknown>["type"];
  autoWidth?: boolean;
  dataTestId?: string;
}

export interface IModalSharedProps {
  type?: EModalTypes;
  onClose?: () => void;
}
export interface IModalBottomButtons extends IModalSharedProps {
  mainButton?: IModalButton;
  secondaryButton?: IModalButton;
  buttonsFlex?: EButtonsFlex;
  buttonsAlign?: EButtonsAlign;
  buttonsMobileFlex?: EButtonsFlex;
  buttonsMobileAlign?: EButtonsAlign;
  onCloseOnButtons?: boolean;
}

export interface IModalProps extends IModalSharedProps, IModalBottomButtons {
  className?: string;
  contentClassName?: string;
  imageURL?: string;
  statusTitle?: string | React.ReactNode;
  title?: string | React.ReactNode;
  message?: string | React.ReactNode;
  secondaryMessage?: string | React.ReactNode;
  isOpen: boolean;
  showCloseButton?: boolean;
  canClose?: boolean;
  icon?: ReactNode;
  childrenBeforeButtons?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  customContentStyle?: CSSProperties;
  customOverlayStyle?: CSSProperties;
  children?: ReactNode;
  contentAndButtonsWrapper?: FunctionComponent<{ children?: ReactNode }>;
  dataTestId?: string;
  showBottomButtons?: boolean;
}

export enum EModalTypes {
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  CONFIRM = "CONFIRM",
  ERROR = "ERROR",
  //New types
  CARD = "CARD",
  DELETE = "DELETE",
  SHARE = "SHARE",
  REFRESH = "REFRESH", //Not sure if needed
  SWITCH = "SWITCH",
  PENDING = "PENDING",
  USER = "USER",
}

export enum EButtonsFlex {
  COLUMN = "COLUMN",
  ROW = "ROW",
  COLUMN_REVERSE = "COLUMN_REVERSE",
  ROW_REVERSE = "ROW_REVERSE",
}

export enum EButtonsAlign {
  START = "START",
  END = "END",
  CENTER = "CENTER",
  SPACE_BETWEEN = "SPACE_BETWEEN",
}

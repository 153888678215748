import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { AttentionCircleIcon } from "assets/svg";
import { Formik, FormikProps } from "formik";

import { MAX_INPUT_LENGTH } from "constants/form";
import { getFieldError } from "helpers";

import { Input } from "uikit";
import { EButtonsFlex } from "uikit/Modal";
import { ButtonsContainer, MainActionButton, SecondaryActionButton } from "uikit/Modal/styles";

import { GetPlaidBankAccountsResponseDto } from "utils/swagger_react_query";

import {
  ManualBankAccountAdditionalInfoFormType,
  manualBankAccountAdditionalInfoValidationSchema,
} from "./validationSchema";
import { FormContainer, FormItem, FormItemLabel, StyledUIModal } from "./styles";

interface IProps {
  originTranslationPrefix?: string;
  onClose: () => void;
  onSubmit: (values: ManualBankAccountAdditionalInfoFormType) => void;
  isOpen: boolean;
  bankAccountInfo?: GetPlaidBankAccountsResponseDto;
}

export const ManualBankAccountAdditionalInfoModal: FC<IProps> = ({
  originTranslationPrefix,
  onClose,
  onSubmit,
  isOpen,
  bankAccountInfo,
}) => {
  const [svoc, setSvoc] = useState<boolean>(false);
  const translationPrefix = `${originTranslationPrefix}.modals.manual_bank_account_additional_info_modal`;
  const { t } = useTranslation();
  return (
    <StyledUIModal
      isOpen={isOpen}
      onClose={onClose}
      title={<Trans i18nKey={`${translationPrefix}.title`} />}
      icon={<AttentionCircleIcon />}
    >
      <Formik<ManualBankAccountAdditionalInfoFormType>
        initialValues={{
          bankName: bankAccountInfo?.manualBankName || "",
          bankHolderName: bankAccountInfo?.manualOwnerName || "",
        }}
        validationSchema={manualBankAccountAdditionalInfoValidationSchema}
        onSubmit={onSubmit}
        validateOnChange={svoc}
      >
        {(formikProps: FormikProps<ManualBankAccountAdditionalInfoFormType>) => {
          const { values, handleChange, handleSubmit } = formikProps;
          return (
            <FormContainer
              onSubmit={(e) => {
                e.preventDefault();
                setSvoc(true);
                handleSubmit(e);
              }}
            >
              <FormItem>
                <FormItemLabel>
                  <Trans i18nKey={`${translationPrefix}.bank_name`} />
                </FormItemLabel>
                <Input
                  name={"bankName"}
                  value={values.bankName}
                  onChange={handleChange}
                  error={getFieldError("bankName", formikProps, {
                    field: t(`${translationPrefix}.bank_name`),
                    maxInputLength: MAX_INPUT_LENGTH,
                  })}
                />
              </FormItem>

              <FormItem>
                <FormItemLabel>
                  <Trans i18nKey={`${translationPrefix}.bank_holder_name`} />
                </FormItemLabel>
                <Input
                  name={"bankHolderName"}
                  value={values.bankHolderName}
                  onChange={handleChange}
                  error={getFieldError("bankHolderName", formikProps, {
                    field: t(`${translationPrefix}.bank_holder_name`),
                    maxInputLength: MAX_INPUT_LENGTH,
                  })}
                />
              </FormItem>

              <ButtonsContainer className={`flex-${EButtonsFlex.COLUMN}`}>
                <MainActionButton type={"submit"} data-testid={"main-action-btn"}>
                  <Trans i18nKey={`buttons.continue`} />
                </MainActionButton>

                <SecondaryActionButton
                  onClick={() => {
                    onClose();
                  }}
                  type={"button"}
                  data-testid={"secondary-action-btn"}
                >
                  <Trans i18nKey={`buttons.cancel`} />
                </SecondaryActionButton>
              </ButtonsContainer>
            </FormContainer>
          );
        }}
      </Formik>
    </StyledUIModal>
  );
};

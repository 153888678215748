import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { tryLoadAndStartRecorder } from "@alwaysmeticulous/recorder-loader";
import * as Sentry from "@sentry/react";
import routes from "routes/routes";

import { logout } from "services/auth";
import { ErrorConst } from "types/BETypes";
import { signInErrors } from "constants/auth";
import { Environment } from "constants/systemConstants";
import { showErrorModal } from "helpers/index";

import { extendApi } from "utils/swagger_react_query";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "localization/localization";
import "./index.css";

export const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as Environment;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.httpClientIntegration({
      failedRequestStatusCodes: [400, 599],
    }),
  ],
  environment: APP_ENVIRONMENT,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", process.env.REACT_APP_API_HOST || ""],
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  sendDefaultPii: true,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
    },
  },
});

extendApi({
  prefixUrl: process.env.REACT_APP_API_HOST,
  throwHttpErrors: false,
  retry: {
    statusCodes: [401],
    methods: ["get", "post", "put", "head", "delete", "options", "trace"],
  },
  hooks: {
    afterResponse: [
      async (request, options, response) => {
        if (
          response?.status === 401 &&
          ![routes.SIGN_IN_COMPLETE, routes.SIGN_UP_COMPLETE, routes.EMPLOYEE_SIGN_UP].includes(
            window.location.pathname as routes,
          )
        ) {
          logout(false);
        }
      },
      async (request, options, response) => {
        if (
          response?.status === 429 &&
          ![routes.SIGN_IN, routes.RECOVERY_ACCOUNT].includes(window.location.pathname as routes)
        ) {
          const error = ErrorConst.TOO_MANY_REQUESTS;
          showErrorModal(error);
        }
      },
      async (request, options, response) => {
        if (response.status === 400 && request.method === "GET") {
          const responseData = await response.json();
          if (
            [ErrorConst.INSUFFICIENT_PERMISSIONS, ErrorConst.USER_NOT_EMPLOYEE].includes(
              responseData?.error,
            )
          ) {
            window.location.href = window.location.origin + routes.INSUFFICIENT_PERMISSIONS;
          }
        }
      },
      async (request, options, response) => {
        const responseStatusIsNegative = [400, 500].includes(response.status);
        const isCompleteAuthPath = [
          routes.SIGN_UP_COMPLETE,
          routes.SIGN_IN_COMPLETE,
          routes.EMPLOYEE_SIGN_UP,
          routes.ADMIN_SIGN_UP,
        ];
        if (responseStatusIsNegative && !isCompleteAuthPath.includes(location.pathname as routes)) {
          const responseData = await response.json();
          const userIsNotFound = responseData?.error === ErrorConst.USER_NOT_FOUND;
          if (responseStatusIsNegative && userIsNotFound) {
            logout(true, true, signInErrors.ACCOUNT_IS_DISABLED);
          }
        }
      },
    ],
  },
});

const container = document.getElementById("root");

async function startApp() {
  const allowedEnvs = [Environment.TST, Environment.DEV];

  if (allowedEnvs.includes(APP_ENVIRONMENT)) {
    await tryLoadAndStartRecorder({
      recordingToken: "DDZM829XEgfFROwax35MMtvlGZA0nbXJFmFbadPb",
    });

    if (container) {
      const root = createRoot(container); // createRoot(container!) if you use TypeScript
      root.render(
        <React.StrictMode>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </React.StrictMode>,
      );
    }
  }
}

startApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { FC } from "react";
import { Trans } from "react-i18next";
import { CardWithPhone, CreditCard } from "assets/svg";

import { CardForm } from "types/CardShipping";

import { Checkbox } from "uikit";

import { Container, IconContainer, Option, Title } from "./styles";

interface IProps {
  onSelectedCardForm: (cardType: CardForm) => void;
  selectedCardForms: CardForm[];
  existingCardFormsList?: CardForm[];
  className?: string;
}

const SelectCardType: FC<IProps> = ({
  onSelectedCardForm,
  selectedCardForms,
  existingCardFormsList,
  className,
}) => {
  const translationPrefix = `common.bank_cards.card_titles`;
  const isPhysical = selectedCardForms.includes(CardForm.PHYSICAL);
  const isVirtual = selectedCardForms.includes(CardForm.VIRTUAL);
  const shouldDisablePhysical = existingCardFormsList?.includes(CardForm.PHYSICAL);

  const physicalCardClassName = `${isPhysical ? "selected" : ""} ${shouldDisablePhysical ? "disabled" : ""}`;
  const virtualCardClassName = `${isVirtual ? "selected" : ""}`;

  return (
    <Container className={className}>
      <Option
        key={CardForm.PHYSICAL}
        className={physicalCardClassName}
        onMouseDown={() => onSelectedCardForm(CardForm.PHYSICAL)}
        data-testid={`${CardForm.PHYSICAL}-card-checkbox`}
      >
        <Checkbox checked={isPhysical} />
        <IconContainer>
          <CreditCard />
        </IconContainer>
        <Title>
          <Trans i18nKey={`${translationPrefix}.${CardForm.PHYSICAL}`} />
        </Title>
      </Option>

      <Option
        key={CardForm.VIRTUAL}
        className={virtualCardClassName}
        onMouseDown={() => onSelectedCardForm(CardForm.VIRTUAL)}
        data-testid={`${CardForm.VIRTUAL}-card-radio-button`}
      >
        <Checkbox checked={isVirtual} />
        <IconContainer>
          <CardWithPhone />
        </IconContainer>
        <Title>
          <Trans i18nKey={`${translationPrefix}.${CardForm.VIRTUAL}`} />
        </Title>
      </Option>
    </Container>
  );
};

export default SelectCardType;

import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

import { ErrorConst } from "types/BETypes";
import { showErrorModal } from "helpers";
import { getErrorMessageFromResponse } from "helpers/shared/errors";

import {
  BusinessOwnerPersonData,
  BusinessStructureBusinessOwnersResponseDto,
  BusinessStructureKeyManagerResponseDto,
  BusinessStructureOwnerResponseDto,
  CreateBusinessOwnerBusiness,
  CreateKeyManager,
  GetBusinessStructureResponseDto,
  mutationBusinessBankAccountsControllerCreateBusinessKeyManager,
  mutationBusinessBankAccountsControllerCreateBusinessOwnerBusiness,
  mutationBusinessBankAccountsControllerCreateBusinessOwnerPerson,
  mutationBusinessBankAccountsControllerDeleteBusiness,
  mutationBusinessBankAccountsControllerDeletePerson,
  mutationBusinessBankAccountsControllerUpdateBusiness,
  mutationBusinessBankAccountsControllerUpdatePerson,
  queryBusinessBankAccountsControllerGetBusinessStructure,
  UserResponseDto,
} from "utils/swagger_react_query";

import { BusinessInformationFormType } from "./components/BusinessInformationForm/validationSchema";
import { BusinessKeyManagerFormType } from "./components/BusinessKeyManagerForm/validationSchema";
import { BusinessStructureEntityType } from "./components/BusinessStructureEntityCard/types";
import { IndividualOwnerFormType } from "./components/IndividualOwnerForm/validationSchema";
import {
  convertBusinessFormToReqPayload,
  convertIndividualOwnerFormToReqPayload,
  convertKeyManagerFormToReqPayload,
  convertUserResponseToIndividualOwnerDto,
  convertUserResponseToKeyManagerDto,
} from "./helpers";
import { IUseBusinessOwnerStructureParams } from "./types";

export const useBusinessOwnersStructure = ({
  fetchDataOnMount = true,
}: IUseBusinessOwnerStructureParams) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedEntityType, setSelectedEntityType] = useState<BusinessStructureEntityType | null>(
    null,
  );
  const [businessOwnersStructure, setBusinessOwnersStructure] =
    useState<GetBusinessStructureResponseDto | null>(null);
  const [isEntityFormSubmitting, setIsEntityFormSubmitting] = useState<boolean>(false);
  const [businessFormValues, setBusinessFormValues] = useState<BusinessInformationFormType | null>(
    null,
  );
  const [showEntityForm, setShowEntityForm] = useState<boolean>(false);
  const [entityDataInProcess, setEntityDataInProcess] = useState<
    | BusinessStructureKeyManagerResponseDto
    | BusinessStructureOwnerResponseDto
    | BusinessStructureBusinessOwnersResponseDto
    | null
  >(null);

  useEffect(() => {
    if (fetchDataOnMount && isEmpty(businessOwnersStructure)) {
      fetchData();
    }
  }, []);

  const _handleRequestError = (error: any) => {
    const _error = getErrorMessageFromResponse(error);
    setErrorMessage?.(_error);
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await queryBusinessBankAccountsControllerGetBusinessStructure();
      setBusinessOwnersStructure(response);

      return response;
    } catch (error) {
      const _error = getErrorMessageFromResponse(error);
      if (_error === ErrorConst.BUSINESS_NOT_FOUND) return;
      if (setErrorMessage) {
        setErrorMessage(_error);
      } else {
        showErrorModal(_error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectEntityClick = (entityType: BusinessStructureEntityType) => {
    if (entityType === BusinessStructureEntityType.BUSINESS) {
      handleOpenEntityForm(undefined);
    }
    setSelectedEntityType(entityType);
  };

  const handleSetupEntityBackClick = () => {
    setErrorMessage?.("");
    if (
      entityDataInProcess &&
      (("businessStructureBusinessId" in entityDataInProcess &&
        entityDataInProcess.businessStructureBusinessId.length) ||
        ("businessStructurePersonId" in entityDataInProcess &&
          entityDataInProcess.businessStructurePersonId.length))
    ) {
      // Returns to the search step
      setSelectedEntityType(null);
      setEntityDataInProcess(null);
      setShowEntityForm(false);
      return;
    }

    if (showEntityForm) {
      // Returns to the search step
      setShowEntityForm(false);
      setEntityDataInProcess(null);

      if (selectedEntityType === BusinessStructureEntityType.BUSINESS) {
        // Returns to the list view
        setSelectedEntityType(null);
      }
    } else {
      // Returns to the list view
      setSelectedEntityType(null);
    }
  };

  const handleOpenEditEntityForm = (entityId: string, entityType: BusinessStructureEntityType) => {
    if (entityType === BusinessStructureEntityType.BUSINESS) {
      const entityData = businessOwnersStructure?.businessOwners?.find(
        (owner) => owner.businessStructureBusinessId === entityId,
      );
      if (entityType === BusinessStructureEntityType.BUSINESS && entityData) {
        setSelectedEntityType(BusinessStructureEntityType.BUSINESS);
        handleOpenEntityForm(undefined);
        setEntityDataInProcess(entityData);
        setShowEntityForm(true);
      }
    }

    if (entityType === BusinessStructureEntityType.OWNER) {
      const entityData = businessOwnersStructure?.owners?.find(
        (owner) => owner.businessStructurePersonId === entityId,
      );
      if (entityType === BusinessStructureEntityType.OWNER && entityData) {
        setSelectedEntityType(BusinessStructureEntityType.OWNER);

        handleOpenEntityForm(undefined);
        setEntityDataInProcess(entityData);
        setShowEntityForm(true);
      }
    }

    if (entityType === BusinessStructureEntityType.KEY_MANAGER) {
      const entityData = businessOwnersStructure?.keyManagers?.find(
        (keyManager) => keyManager.businessStructurePersonId === entityId,
      );
      if (entityType === BusinessStructureEntityType.KEY_MANAGER && entityData) {
        setSelectedEntityType(BusinessStructureEntityType.KEY_MANAGER);
        handleOpenEntityForm(undefined);
        setEntityDataInProcess(entityData);
        setShowEntityForm(true);
      }
    }
  };

  const handleOpenEntityForm = (selectedUser: UserResponseDto | undefined) => {
    if (selectedEntityType === BusinessStructureEntityType.OWNER && selectedUser) {
      const convertedData = convertUserResponseToIndividualOwnerDto(selectedUser);
      setEntityDataInProcess(convertedData);
    }
    if (selectedEntityType === BusinessStructureEntityType.KEY_MANAGER && selectedUser) {
      const convertedData = convertUserResponseToKeyManagerDto(selectedUser);
      setEntityDataInProcess(convertedData);
    }

    if (selectedEntityType === BusinessStructureEntityType.BUSINESS) {
      setEntityDataInProcess(null);
    }

    setShowEntityForm(true);
  };

  const handleEditEntityFormSubmit = async (
    entityId: string,
    values: IndividualOwnerFormType | BusinessKeyManagerFormType | BusinessInformationFormType,
  ) => {
    try {
      setIsEntityFormSubmitting(true);
      if (selectedEntityType === BusinessStructureEntityType.BUSINESS) {
        const payload = convertBusinessFormToReqPayload(values as BusinessInformationFormType);
        await mutationBusinessBankAccountsControllerUpdateBusiness({
          businessStructureBusinessId: entityId || "",
        })(payload);
      }

      if (
        selectedEntityType &&
        [BusinessStructureEntityType.KEY_MANAGER, BusinessStructureEntityType.OWNER].includes(
          selectedEntityType,
        )
      ) {
        let payload: CreateKeyManager | BusinessOwnerPersonData | {} = {};
        if (selectedEntityType === BusinessStructureEntityType.OWNER) {
          payload = convertIndividualOwnerFormToReqPayload(values as IndividualOwnerFormType);
        }
        if (selectedEntityType === BusinessStructureEntityType.KEY_MANAGER) {
          payload = convertKeyManagerFormToReqPayload(values as BusinessKeyManagerFormType);
        }

        await mutationBusinessBankAccountsControllerUpdatePerson({
          businessStructurePersonId: entityId || "",
        })(payload);
      }

      setSelectedEntityType(null);
      setShowEntityForm(false);
      await fetchData();
    } catch (error) {
      _handleRequestError(error);
    } finally {
      setIsEntityFormSubmitting(false);
    }
  };

  const handleEntityFormSubmit = async (
    values: IndividualOwnerFormType | BusinessKeyManagerFormType | BusinessInformationFormType,
  ) => {
    try {
      setIsEntityFormSubmitting(true);
      if (
        selectedEntityType === BusinessStructureEntityType.BUSINESS ||
        !isEmpty(businessFormValues)
      ) {
        if (businessFormValues) {
          const payload: CreateBusinessOwnerBusiness = {
            business: convertBusinessFormToReqPayload(businessFormValues),
            keyManager: convertKeyManagerFormToReqPayload(values as BusinessKeyManagerFormType),
          };
          await mutationBusinessBankAccountsControllerCreateBusinessOwnerBusiness()(payload);
          setSelectedEntityType(null);
          setShowEntityForm(false);
          await fetchData();
        } else {
          setBusinessFormValues(values as BusinessInformationFormType);
          setSelectedEntityType(BusinessStructureEntityType.KEY_MANAGER);
          setShowEntityForm(false);
        }
        return;
      }
      if (selectedEntityType === BusinessStructureEntityType.KEY_MANAGER) {
        const payload = convertKeyManagerFormToReqPayload(values as BusinessKeyManagerFormType);
        await mutationBusinessBankAccountsControllerCreateBusinessKeyManager()(payload);
        setSelectedEntityType(null);
        setShowEntityForm(false);
        await fetchData();
        return;
      }
      if (selectedEntityType === BusinessStructureEntityType.OWNER) {
        const payload = convertIndividualOwnerFormToReqPayload(values as IndividualOwnerFormType);
        await mutationBusinessBankAccountsControllerCreateBusinessOwnerPerson()(payload);
        setSelectedEntityType(null);
        setShowEntityForm(false);
        await fetchData();
        return;
      }
    } catch (error) {
      _handleRequestError(error);
    } finally {
      setIsEntityFormSubmitting(false);
    }
  };

  const handleDeleteEntity = async (entityId: string, entityType: BusinessStructureEntityType) => {
    try {
      setIsLoading(true);
      if (entityType === BusinessStructureEntityType.BUSINESS) {
        await mutationBusinessBankAccountsControllerDeleteBusiness({
          businessStructureBusinessId: entityId || "",
        })();
      } else if (
        [BusinessStructureEntityType.KEY_MANAGER, BusinessStructureEntityType.OWNER].includes(
          entityType,
        )
      ) {
        await mutationBusinessBankAccountsControllerDeletePerson({
          businessStructurePersonId: entityId || "",
          personType: entityType as any,
        })();
      }
      await fetchData();
    } catch (error) {
      _handleRequestError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetchData,
    metadata: {
      isLoading,
      selectedEntityType,
      showEntityForm,
      isEntityFormSubmitting,
    },
    pagedData: {
      businessOwnersStructure,
      entityDataInProcess,
      errorMessage,
    },
    actions: {
      handleSelectEntityClick,
      handleSetupEntityBackClick,
      handleOpenEntityForm,
      handleEntityFormSubmit,
      handleOpenEditEntityForm,
      handleDeleteEntity,
      handleEditEntityFormSubmit,
    },
  };
};

import styled from "styled-components";

import { breakpoint } from "helpers";
import Avatar from "components/Avatar";
import CopyText from "components/CopyText";
import { StyledTooltipContainer } from "components/CopyText/styles";
import EmptyList from "components/EmptyList";
import TransactionsListBanking from "components/TransactionsListBanking";

import {
  LinkTextMixin,
  ModalContent,
  NoBorderButton,
  PrimaryButtonIconed,
  SecondaryButtonIconed,
  SectionInner,
  SectionInnerTitle,
  UIModal,
} from "uikit";
import { PrimaryButton } from "uikit";
import { iconedButtonMixin, SecondaryButton } from "uikit/Buttons/Buttons";
import {
  ButtonsContainer as ModalButtonsContainer,
  Message as ModalMessage,
} from "uikit/Modal/styles";

import BankCardStatus from "../../shared/components/BankCardStatus";

//Bank account section

export const BankAccountButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  height: 100%;
`;

export const MonthlyStatementsLinkButton = styled(NoBorderButton)`
  ${iconedButtonMixin};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.body2};
  display: flex;
  height: auto;
  gap: 8px;
`;

export const TransferFundsButton = styled(PrimaryButtonIconed)`
  width: auto;
  svg {
    path {
      fill: none;
      stroke: ${({ theme }) => theme.colors.primaryText};
    }
  }

  &:disabled {
    svg {
      path {
        fill: none;
        stroke: ${({ theme }) => theme.colors.primaryTextDisabled};
      }
    }
  }

  ${breakpoint("xs", "xlm")`
    width: 100%;
    max-width: 100%;
  `}
`;

//Cards section

export const AdminCardsSectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ACSHIssueCardButton = styled(PrimaryButton)`
  width: auto;
`;

export const CardsContainer = styled.div``;

export const CardsEmptyList = styled(EmptyList)`
  min-height: 0;
`;

export const CardItem = styled(SectionInner)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  ${breakpoint("xs", "md")`
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `}
`;

export const CardInfo = styled.div`
  ${breakpoint("xs", "md")`
    width: 100%;
  `}
`;

export const CardTitle = styled(SectionInnerTitle)`
  font-size: ${({ theme }) => theme.size.body1};
`;

export const StyledBankCardStatus = styled(BankCardStatus)`
  ${breakpoint("xs", "md")`
    width: 100%;
    justify-content: center;
    margin-top: 16px;
  `}
`;

export const CIBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${breakpoint("xs", "md")`
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;  
  `}
`;

export const CardNumber = styled.div`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.77;
  margin-right: 32px;

  ${breakpoint("xs", "md")`
    margin-right: 0;
    margin-top: 16px;
  `}
`;

//Card User Container
export const CardUserInfo = styled.div``;

export const CAITitle = styled.div``;

export const CAIAvatarContainer = styled.div``;

export const CAIAvatar = styled(Avatar)``;

export const CardButtonsContainer = styled.div`
  ${breakpoint("xs", "md")`
    width: 100%;
  `}
`;

export const CardButton = styled(SecondaryButtonIconed)`
  border-width: 1px;
  padding: 10px 12px;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.42;
  height: 40px;
  min-height: 40px;

  svg {
    margin-left: 8px;
    path {
      stroke: ${({ theme }) => theme.colors.secondaryButtonText};
    }
  }

  ${breakpoint("xs", "md")`
    margin-top: 16px;
    width: 100%;
    max-width: 100%;
  `}
`;

//Transactions section

export const TransactionsContainer = styled.div``;

export const StyledTransactionsList = styled(TransactionsListBanking)``;

export const AddAccountButton = styled(PrimaryButtonIconed)`
  width: auto;
  min-width: auto;

  ${breakpoint("xs", "md")`
    width: 100%;
    margin: 16px auto 0 auto;
  `}
`;

//KYB Verification Section
export const KYBInfoContainer = styled.div``;

export const KYBInfoContent = styled(ModalContent)`
  padding: 40px 0;

  ${ModalMessage} {
    margin-bottom: 0;
  }

  ${ModalButtonsContainer} {
    margin-top: 12px;
  }
`;

export const KYBInfoWarningContent = styled(KYBInfoContent)``;
export const KYBInfoErrorContent = styled(KYBInfoContent)``;

export const KYBInfoContentTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const KYBInfoContentTitleIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.kybWarningInfoTitleIconBg};

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const KYBInfoContentMessage = styled.div`
  line-height: 1;
`;

export const KYBInfoEmailContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 18px;
`;

export const KYBInfoContentEmail = styled.a`
  ${LinkTextMixin}
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const KYBInfoContentCopyButton = styled(CopyText)`
  font-family: ${({ theme }) => theme.fontFamily.alt};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.kybWarningInfoCopyText};

  ${StyledTooltipContainer} {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const IssueCardModal = styled(UIModal)`
  text-align: initial;
  min-width: 1100px;
`;

export const CompanyCardShippingDetailsContainer = styled.div`
  text-align: initial;
`;

export const IssuCardModalCancelButton = styled(SecondaryButton)`
  width: auto;
`;

export const IssuCardModalSubmitButton = styled(PrimaryButton)`
  width: auto;
`;

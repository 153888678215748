import React, { FC, ReactNode, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import DotsButton from "uikit/Buttons/DotsButton";

import { IContextMenuOption } from "./types";
import { MenuList, MenuListContainer, MenuListItem, Wrapper } from "./styles";

interface IProps {
  options: Array<IContextMenuOption | undefined>;
  /**
   * Wrapper class name
   */
  className?: string;
  component?: ReactNode;
  dataTestId?: string;
}

export const ContextMenu: FC<IProps> = ({ options, className, component, dataTestId }) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <Wrapper className={className} data-testid={dataTestId}>
        {component ? (
          <span
            onClick={() => setOpen((currentState) => !currentState)}
            data-testid={"context-menu-dots-button"}
          >
            {component}
          </span>
        ) : (
          <DotsButton
            onClick={() => setOpen((currentState) => !currentState)}
            data-testid={"context-menu-dots-button"}
          />
        )}

        {!!options.length && isOpen && (
          <MenuListContainer>
            <MenuList>
              {options.map((item) => {
                if (!item || item.hide) return;
                return (
                  <MenuListItem
                    className={`${item.disabled ? "disabled" : ""}`}
                    key={item.value}
                    data-testid={`context-menu-item-${item.value}`}
                    onClick={() => {
                      if (!item.disabled) {
                        item.onClick();
                        setOpen(false);
                      }
                    }}
                  >
                    {item.label}
                  </MenuListItem>
                );
              })}
            </MenuList>
          </MenuListContainer>
        )}
      </Wrapper>
    </OutsideClickHandler>
  );
};

import { FC } from "react";

import IconProps from "./IconProps";

const UserStarIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10817_95669)">
      <path
        d="M8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7Z"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 21V19C6 17.9391 6.42143 16.9217 7.17157 16.1716C7.92172 15.4214 8.93913 15 10 15H10.5"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.8002 20.8175L15.6282 21.9555C15.5638 21.989 15.4913 22.004 15.4188 21.9987C15.3463 21.9934 15.2767 21.9681 15.2178 21.9256C15.1589 21.8831 15.113 21.825 15.0851 21.7579C15.0573 21.6907 15.0487 21.6172 15.0602 21.5455L15.4752 19.1345L13.7182 17.4275C13.6658 17.3768 13.6287 17.3123 13.6111 17.2416C13.5935 17.1708 13.5962 17.0965 13.6188 17.0271C13.6414 16.9578 13.6831 16.8962 13.7391 16.8494C13.795 16.8026 13.863 16.7725 13.9352 16.7625L16.3632 16.4105L17.4492 14.2175C17.4817 14.1521 17.5318 14.0972 17.5938 14.0588C17.6558 14.0204 17.7273 14 17.8002 14C17.8732 14 17.9447 14.0204 18.0067 14.0588C18.0687 14.0972 18.1188 14.1521 18.1512 14.2175L19.2372 16.4105L21.6652 16.7625C21.7373 16.7728 21.805 16.8031 21.8607 16.85C21.9164 16.8968 21.9579 16.9583 21.9805 17.0275C22.003 17.0967 22.0058 17.1708 21.9885 17.2415C21.9711 17.3122 21.9343 17.3766 21.8822 17.4275L20.1252 19.1345L20.5392 21.5445C20.5517 21.6163 20.5437 21.6902 20.5162 21.7578C20.4887 21.8254 20.4429 21.8839 20.3838 21.9267C20.3248 21.9695 20.2549 21.9949 20.1822 22C20.1094 22.005 20.0367 21.9896 19.9722 21.9555L17.8002 20.8175Z"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10817_95669">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default UserStarIcon;

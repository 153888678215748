import { useState } from "react";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector } from "store/selectors";

import { KybStatus } from "types/BETypes";
import { getErrorMessageFromResponse } from "helpers/shared/errors";
import { isKybRejected } from "helpers/shared/kyb";
import useAuth from "hooks/useAuth";
import { EKycResponseType } from "components/CompanyBankAccountComponents/employee/KYCVerification/types";

import { mutationBusinessBankAccountsControllerRunKyb } from "utils/swagger_react_query";

import { getKybResponseType } from "./helpers";
import { IUseKybVerificationParams } from "./types";

export const useKybVerification = ({
  onKybSuccess,
  onStatusSectionPrimaryBtnClick,
}: IUseKybVerificationParams) => {
  const { getCompanyInfo } = useAuth();
  const currentCompany = useAppSelector(companyMetadataSelector);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [responseType, setResponseType] = useState<EKycResponseType | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleKybSubmit = async () => {
    try {
      setIsSubmitting(true);
      const kybResponseStatus = await mutationBusinessBankAccountsControllerRunKyb()();
      const status = getKybResponseType(kybResponseStatus.status as KybStatus);
      await getCompanyInfo(currentCompany?.companyId || "", true);
      if (!status) throw new Error();
      setResponseType(status);
      onKybSuccess?.();
    } catch (error) {
      const _error = getErrorMessageFromResponse(error);
      setErrorMessage(_error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleStatusSectionPrimaryBtnClick = () => {
    if (isKybRejected(currentCompany) && responseType) {
      setResponseType(null);
    } else {
      onStatusSectionPrimaryBtnClick?.(responseType as EKycResponseType);
    }
  };

  return {
    isSubmitting,
    responseType,
    errorMessage,
    handleKybSubmit,
    handleStatusSectionPrimaryBtnClick,
  };
};

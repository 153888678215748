import { FC } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import PromoImageEmployee from "assets/png/promo_woman_alt.png";
import PromoImageAdmin from "assets/png/promo_woman_alt_3.png";
import { userMetadataSelector } from "store/selectors";

import { UserRole } from "types/BETypes";
import { promoStepsDescriptionsList } from "components/CompanyBankAccountComponents/CompanyBankAccountCreationModal/constants";
import {
  BackBtn,
  BtnsWrapper,
  SubmitBtn,
} from "components/CompanyBankAccountComponents/CompanyBankAccountCreationModal/styles";
import VerticalStepsList from "components/VerticalStepsList";

import { HDescription, HTitle, TotalStepsNumber } from "../styles";
import { Container, InnerContent, PromoImg, StepsList, TextContainer } from "./styles";

interface IProps {
  onBackClick: () => void;
  onGetStartedClick: () => void;
}

export const PromoSection: FC<IProps> = ({ onBackClick, onGetStartedClick }) => {
  const currentUser = useSelector(userMetadataSelector);
  const role = UserRole[currentUser?.lastActiveRole as UserRole];
  const rolePrefix = role === UserRole.EMPLOYEE ? "employee" : "admin";

  const translationPrefixOrigin = `components.company_bank_account_components`;
  const translationPrefix = `${translationPrefixOrigin}.shared.promo_section.${rolePrefix}`;
  const _listPrefix = `${translationPrefix}.steps_descriptions_list`;

  const stepsDescriptionsList = promoStepsDescriptionsList(_listPrefix, role);
  const promoImageSrc = role === UserRole.EMPLOYEE ? PromoImageEmployee : PromoImageAdmin;

  return (
    <Container>
      <InnerContent>
        <TextContainer>
          <HTitle>
            <Trans i18nKey={`${translationPrefix}.title`} />
          </HTitle>
          <HDescription>
            <Trans i18nKey={`${translationPrefix}.description`} />
          </HDescription>
          <TotalStepsNumber>
            <Trans
              i18nKey={`${translationPrefix}.sub_title`}
              values={{ stepsNumber: stepsDescriptionsList?.length }}
            />
          </TotalStepsNumber>

          <StepsList>
            <VerticalStepsList items={stepsDescriptionsList} />
          </StepsList>
        </TextContainer>

        <PromoImg userRole={role} src={promoImageSrc} alt="Promo image" />
      </InnerContent>

      <BtnsWrapper>
        <BackBtn
          onClick={onBackClick}
          type={"button"}
          data-testid="company-bank-account-creation-modal-cancel-button"
        >
          <Trans i18nKey="buttons.cancel" />
        </BackBtn>

        <SubmitBtn
          onClick={onGetStartedClick}
          type={"button"}
          data-testid="company-bank-account-creation-modal-submit-button"
        >
          <Trans i18nKey="buttons.get_started" />
        </SubmitBtn>
      </BtnsWrapper>
    </Container>
  );
};

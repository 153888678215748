import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormikErrors } from "formik";
import { useRouteHelpers } from "routes/helpers";
import routes from "routes/routes";

import { EMagicLinkFlow } from "types/AuthFlowTypes";
import { ErrorConst } from "types/BETypes";
import { showErrorModal } from "helpers";
import { getBusinessDetails, getSuperAdminDetails } from "helpers/form/authForms";
import useAuth from "hooks/useAuth";

import {
  CreateCompanyAndSuperadminDto,
  CreateCompanyResponseDto,
  mutationUsersControllerAcceptTermsAndConditions,
  useMutationCompaniesControllerCreateCompany,
} from "utils/swagger_react_query";

import {
  BusinessDetailsFormType,
  ContactDetailsFormType,
  useCompleteSignIn,
  useSingIn,
} from "../_shared";
import { ParentFormType } from "./validationSchema";

interface IParams {
  errorModalsTranslationsPrefix: string;
}

interface IFormValues {
  companyInfo?: CreateCompanyAndSuperadminDto["companyInfo"];
  contactInfo?: CreateCompanyAndSuperadminDto["userInfo"];
}

export const useSignUpPage = ({ errorModalsTranslationsPrefix }: IParams) => {
  const navigate = useNavigate();
  const signInStep = useSingIn(EMagicLinkFlow.SIGN_UP);
  const { getCurrentUser, isAuthenticated } = useAuth();
  const { getDefaultRoute } = useRouteHelpers();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [contentLoader, setContentLoader] = useState<boolean>(false);
  const [svoc, setSvoc] = useState<boolean>(signInStep.metadata.svoc);
  const auth = isAuthenticated();

  useEffect(() => {
    if (auth) {
      setCurrentStep(1);
    }
  }, [auth]);

  const {
    metadata: { isLoading: pageLoader },
  } = useCompleteSignIn({ mode: EMagicLinkFlow.SIGN_UP });

  const [formValues, setFormValues] = useState<IFormValues>({
    companyInfo: undefined,
    contactInfo: undefined,
  });

  const businessInitialValues: BusinessDetailsFormType = {
    businessName: "",
  };

  const contactDetailsInitialValues: ContactDetailsFormType = {
    firstName: "",
    lastName: "",
    jobTitle: "",
    phone: "",
  };

  const getFormInitValues = () => {
    if (currentStep === 0) {
      return signInStep.metadata.formInitialValues;
    } else if (currentStep === 1) {
      return businessInitialValues;
    } else if (currentStep === 2) {
      return contactDetailsInitialValues;
    }
  };

  const { mutate: submitOnboarding } = useMutationCompaniesControllerCreateCompany({
    onSuccess: async (data: CreateCompanyResponseDto) => {
      await mutationUsersControllerAcceptTermsAndConditions()();

      if (!data) throw new Error(ErrorConst.INTERNAL_ERROR);

      setContentLoader(false);
      navigate(getDefaultRoute());
    },
    onError: (error: any) => {
      setContentLoader(false);
      showErrorModal(error);
    },
  });

  const handleSubmitBusinessDetails = (values: BusinessDetailsFormType) => {
    setSvoc(true);
    setFormValues((prev) => ({ ...prev, companyInfo: getBusinessDetails(values) }));
    setCurrentStep(currentStep + 1);
    setSvoc(false);
  };

  const handleSubmitContactDetails = (
    values: ParentFormType,
    validateForm: (values?: ParentFormType) => Promise<FormikErrors<ParentFormType>>,
  ) => {
    setSvoc(true);
    validateForm(values).then((errors) => {
      setContentLoader(true);
      const errorList = Object.values(errors);
      const hasErrors = !!errorList.length;
      if (!hasErrors) {
        const _values: IFormValues = {
          ...formValues,
          contactInfo: getSuperAdminDetails(values.contactDetails),
        };
        setFormValues(_values);
        submitOnboarding({
          userInfo: _values.contactInfo,
          companyInfo: _values.companyInfo,
        });
      } else {
        setContentLoader(false);
      }
    });
  };

  const submitHandler = (values: ParentFormType, validateForm: any) => {
    if (currentStep === 0) {
      signInStep.actions.handleSubmit(values.signIn);
    } else if (currentStep === 1) {
      handleSubmitBusinessDetails(values.businessDetails);
    } else if (currentStep === 2) {
      handleSubmitContactDetails(values, validateForm);
    }
  };

  const onBackClick = () => (currentStep > 0 ? setCurrentStep(currentStep - 1) : undefined);

  return {
    metadata: {
      svoc,
      currentStep,
      getFormInitValues,
      pageLoader: pageLoader,
      contentLoader: contentLoader || signInStep.metadata.isLoading,
    },
    actions: {
      submitHandler,
      handleGoogleAuth: signInStep.actions.handleGoogleAuth,
      onBackClick,
    },
    formData: {
      signInInitValues: signInStep.metadata.formInitialValues,
      businessInitialValues,
      contactDetailsInitialValues,
    },
    modals: {
      ...signInStep.modals,
    },
  };
};

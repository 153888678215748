import { FC } from "react";
import { Trans } from "react-i18next";

import {
  renderErrorMessage,
  renderFooterButtonsSection,
  renderKycResponseSection,
} from "components/CompanyBankAccountComponents/components/renderElements";

import { FullSectionLoader } from "uikit";

import { FormSectionContainer, StructureListSection } from "./components";
import { BusinessStructureEntityType } from "./components/BusinessStructureEntityCard/types";
import { BusinessOwnersStructureWidgetType, IBusinessOwnersStructureProps } from "./types";
import { useBusinessOwnersStructure } from "./useBusinessOwnersStructure";
import { useKybVerification } from "./useKybVerification";
import { Content } from "./styles";

const BusinessOwnersStructure: FC<IBusinessOwnersStructureProps> = ({
  widgetType,
  emphasizedContainer,
  className,
  onStatusSectionPrimaryBtnClick,
  onKybSuccess,
  onBackClick,
  ...rest
}) => {
  const kybResponseStatusTranslationPrefix = `components.company_bank_account_components.shared.kyb_kyc_response_section`;
  const { metadata, pagedData, actions } = useBusinessOwnersStructure({
    ...rest,
  });
  const {
    isSubmitting,
    responseType: kybResponseType,
    errorMessage: kybErrorMessage,
    handleKybSubmit,
    handleStatusSectionPrimaryBtnClick,
  } = useKybVerification({ onKybSuccess, onStatusSectionPrimaryBtnClick });

  const { businessOwnersStructure } = pagedData;
  const errorMessage = kybErrorMessage || pagedData?.errorMessage;
  const isLoading = metadata.isLoading || isSubmitting;
  const shouldDisableSubmitKybBtn = () => {
    const ownersCount = businessOwnersStructure?.owners?.length || 0;
    const keyManagersCount = businessOwnersStructure?.keyManagers?.length || 0;
    if (!ownersCount && !keyManagersCount) return true;

    return false;
  };

  const showCardsList =
    !metadata.selectedEntityType && !metadata.showEntityForm && !kybResponseType;
  const showFormSection =
    !!metadata.selectedEntityType || (!!metadata.showEntityForm && !kybResponseType);

  return (
    <>
      {isLoading && <FullSectionLoader />}
      {renderErrorMessage(errorMessage)}

      {(showCardsList || kybResponseType) && (
        <>
          <Content className={className} emphasizedContainer={emphasizedContainer}>
            {kybResponseType ? (
              renderKycResponseSection(kybResponseType, kybResponseStatusTranslationPrefix)
            ) : (
              <StructureListSection
                widgetType={widgetType}
                businessOwnersStructure={businessOwnersStructure || undefined}
                onSelectEntity={actions.handleSelectEntityClick}
                onDelete={actions.handleDeleteEntity}
                onEdit={actions.handleOpenEditEntityForm}
              />
            )}
          </Content>

          {widgetType === BusinessOwnersStructureWidgetType.SETUP && (
            <>
              {renderFooterButtonsSection({
                mainButton: {
                  text: <Trans i18nKey={`buttons.continue`} />,
                  disabled: shouldDisableSubmitKybBtn(),
                  onClick: kybResponseType ? handleStatusSectionPrimaryBtnClick : handleKybSubmit,
                  type: "button",
                  dataTestId: "cbacm-business-structure-submit-button",
                },
                secondaryButton: {
                  text: <Trans i18nKey="buttons.cancel" />,
                  onClick: onBackClick,
                  type: "button",
                  dataTestId: "cbacm-business-structure-form-cancel-button",
                },
              })}
            </>
          )}
        </>
      )}

      {showFormSection && (
        <Content className={className} emphasizedContainer={emphasizedContainer}>
          <FormSectionContainer
            selectedEntityType={metadata.selectedEntityType as BusinessStructureEntityType}
            widgetType={widgetType}
            onBackClick={actions.handleSetupEntityBackClick}
            onSubmitEntity={actions.handleEntityFormSubmit}
            onSubmitEditedEntity={actions.handleEditEntityFormSubmit}
            showEntityForm={metadata.showEntityForm}
            onOpenEntityForm={actions.handleOpenEntityForm}
            entityData={pagedData.entityDataInProcess}
            isFormSubmitting={metadata.isEntityFormSubmitting}
          />
        </Content>
      )}
    </>
  );
};

export default BusinessOwnersStructure;

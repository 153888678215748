import { FC, useEffect, useRef, useState } from "react";
import { Trans } from "react-i18next";
import WelcomeImage from "assets/png/company_welcome_message_image.png";
import { ArrowIconSmall } from "assets/svg";

import VerticalStepsList from "components/VerticalStepsList";
import { IVerticalStepListItem } from "components/VerticalStepsList/types";

import {
  CIRImage,
  CIRImageContainer,
  Container,
  ContainerInner,
  ContainerInnerLeft,
  ContainerInnerRight,
  ContinueButton,
  Message,
  StepsListContainer,
  StyledLoader,
  Title,
} from "./styles";

interface IProps {
  className?: string;
  onContinue?: () => void;
  onImageLoad?: () => void;
}

const WelcomeMessage: FC<IProps> = ({ className, onContinue, onImageLoad }) => {
  const translationPrefix = `admin_setup_page.setup_states.welcome_message`;
  const [imageIsLoading, setImageIsLoading] = useState(true);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const steps: IVerticalStepListItem[] = [
    {
      index: 1,
      title: <Trans i18nKey={`${translationPrefix}.steps_list.p1.title`} />,
      description: <Trans i18nKey={`${translationPrefix}.steps_list.p1.message`} />,
    },
    {
      index: 2,
      title: <Trans i18nKey={`${translationPrefix}.steps_list.p2.title`} />,
      description: <Trans i18nKey={`${translationPrefix}.steps_list.p2.message`} />,
    },
    {
      index: 3,
      title: <Trans i18nKey={`${translationPrefix}.steps_list.p3.title`} />,
      description: <Trans i18nKey={`${translationPrefix}.steps_list.p3.message`} />,
    },
  ];

  const handleImageLoad = () => {
    setImageIsLoading(false);
    onImageLoad?.();
  };

  useEffect(() => {
    if (imageRef.current?.complete) {
      handleImageLoad();
    }
  }, []);

  return (
    <Container className={className}>
      {imageIsLoading && <StyledLoader />}
      <ContainerInner className={imageIsLoading ? "hidden" : ""}>
        <ContainerInnerLeft>
          <Title>
            <Trans i18nKey={`${translationPrefix}.title`} />
          </Title>
          <Message>
            <Trans i18nKey={`${translationPrefix}.message`} />
          </Message>
          <StepsListContainer>
            <VerticalStepsList items={steps} />
          </StepsListContainer>
          <ContinueButton data-testid="welcome-message-continue-button" onClick={onContinue}>
            <Trans i18nKey={`${translationPrefix}.button`} />
            <ArrowIconSmall />
          </ContinueButton>
        </ContainerInnerLeft>
        <ContainerInnerRight>
          <CIRImageContainer>
            <CIRImage ref={imageRef} onLoad={handleImageLoad} src={WelcomeImage} />
          </CIRImageContainer>
        </ContainerInnerRight>
      </ContainerInner>
    </Container>
  );
};

export default WelcomeMessage;

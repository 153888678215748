import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useAppSelector } from "store/hooks";
import { companyMetadataSelector, userMetadataSelector } from "store/selectors";

import { MFAFactorType } from "types/BETypes";
import { useBankAccountLink } from "hooks";
import useMFASMS from "components/MultiFactorAuthorization/components/sms/useMFASMS";
import { MFAWidgetType } from "components/MultiFactorAuthorization/types";

export const useConnectBankStep = () => {
  const currentUser = useAppSelector(userMetadataSelector);
  const currentCompany = useAppSelector(companyMetadataSelector);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCreationBusinessBankAccountModalOpen, setIsCreationBusinessBankAccountModalOpen] =
    useState<boolean>(false);
  const bankAccountLink = useBankAccountLink({
    loadingCallback: setIsLoading,
    shouldFetchBankAccountsListOnMount: false,
  });

  const { factorsData, fetchData } = useMFASMS({
    widgetType: MFAWidgetType.MISC,
  });

  const isMFAEnabled = !!factorsData?.some(
    (factor) => factor.type === MFAFactorType.SMS && factor.isVerified,
  );

  useEffect(() => {
    if (currentUser && isEmpty(bankAccountLink?.data?.bankAccounts)) {
      initFetch();
    }
  }, []);

  const initFetch = async () => {
    try {
      setIsLoading(true);
      await bankAccountLink.actions.refetchBankAccountsList(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleOpenSetupModal = () => {
    setIsCreationBusinessBankAccountModalOpen(true);
  };

  return {
    metadata: {
      currentUser,
      currentCompany,
      isLoading,
      isMFAEnabled,
    },
    pageData: {
      externalBankAccounts: bankAccountLink.data,
    },
    actions: {
      externalBankAccounts: { ...bankAccountLink.actions },
      handleOpenSetupModal,
      initFetch,
    },
    modals: {
      isCreationBusinessBankAccountModalOpen,
      setIsCreationBusinessBankAccountModalOpen,
    },
  };
};

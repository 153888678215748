import { Form } from "formik";
import styled from "styled-components";

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

import styled from "styled-components";

import { Body2, H3 } from "uikit";

export const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const SHeaderTextContainer = styled.div``;

export const STitle = styled(H3)`
  font-size: ${({ theme }) => theme.size.subtitleLarge};
  margin-bottom: 8px;
`;

export const SDescription = styled(Body2)`
  color: ${({ theme }) => theme.colors.textAlt};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-bottom: 16px;

  span {
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const SHeaderButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

import * as yup from "yup";

import { CompanyEntityType } from "types/BETypes";
import { EIN_LENGTH_WITH_MASK, MAX_INPUT_LENGTH } from "constants/form";
import { EStates, EStatesShort } from "constants/shared";
import { addressValidationRulesBase } from "constants/shared/validationRules";
import { getPhoneNumberOrNull, validatePhoneNumber } from "helpers";

export const BusinessAccountCompanyInfoValidationSchema = yup.object({
  companyName: yup
    .string()
    .max(MAX_INPUT_LENGTH, "validationErrors.common.too_long")
    .required("validationErrors.common.is_required"),
  operatingName: yup
    .string()
    .max(MAX_INPUT_LENGTH, "validationErrors.common.too_long")
    .required("validationErrors.common.is_required"),
  businessFormationDate: yup
    .date()
    .nullable()
    .typeError("validationErrors.common.is_required")
    .required("validationErrors.common.is_required"),
  stateOfIncorporation: yup
    .object({
      label: yup
        .mixed<EStates>()
        .oneOf(Object.values(EStates) as EStates[])
        .required("validationErrors.common.is_required"),
      value: yup
        .mixed<EStatesShort>()
        .oneOf(Object.values(EStatesShort) as EStatesShort[])
        .required("validationErrors.common.is_required"),
    })
    .required("validationErrors.common.is_required"),
  businessStructure: yup
    .object({
      label: yup.string().required("validationErrors.common.is_required"),
      value: yup
        .mixed<CompanyEntityType>()
        .oneOf(Object.values(CompanyEntityType) as CompanyEntityType[])
        .required("validationErrors.common.is_required"),
    })
    .required("validationErrors.common.is_required"),
  businessPhone: yup
    .string()
    .required("validationErrors.common.is_required")
    .test(
      "invalid phone",
      "validationErrors.common.wrong_format",
      (value) => !value || validatePhoneNumber(value),
    )
    .test(
      "required phone",
      "validationErrors.common.is_required",
      (value) => !!getPhoneNumberOrNull(value),
    ),
  ein: yup
    .string()
    .length(EIN_LENGTH_WITH_MASK, "validationErrors.common.wrong_format")
    .required("validationErrors.common.is_required"),
  address: yup
    .string()
    .max(MAX_INPUT_LENGTH, "validationErrors.common.too_long")
    .required("validationErrors.common.is_required"),
  city: yup
    .string()
    .max(MAX_INPUT_LENGTH, "validationErrors.common.too_long")
    .required("validationErrors.common.is_required"),
  suite: addressValidationRulesBase.suite,
  state: yup
    .object({
      label: yup
        .mixed<EStates>()
        .oneOf(Object.values(EStates) as EStates[])
        .required("validationErrors.common.is_required"),
      value: yup
        .mixed<EStatesShort>()
        .oneOf(Object.values(EStatesShort) as EStatesShort[])
        .required("validationErrors.common.is_required"),
    })
    .required("validationErrors.common.is_required"),
  zip: yup
    .string()
    .length(5, "validationErrors.common.wrong_format")
    .required("validationErrors.common.is_required"),
});

export type BusinessAccountCompanyInfoFormType = yup.InferType<
  typeof BusinessAccountCompanyInfoValidationSchema
>;

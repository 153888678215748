import { MouseEventHandler, ReactNode } from "react";
import { Trans } from "react-i18next";
import { CopyIcon } from "assets/svg";

import { HELP_EMAIL } from "constants/shared";
import { getTranslatedErrorMessageFromResponse } from "helpers/shared/errors";

import { EModalTypes } from "uikit/Modal";
import ModalStatusIcon from "uikit/Modal/components/ModalStatus";

import { BackBtn, BtnsWrapper, SubmitBtn } from "../CompanyBankAccountCreationModal/styles";
import { EKycResponseType } from "../employee/KYCVerification/types";
import {
  EMIcon,
  EMText,
  ErrorMessageBox,
  LinkText,
  ResponseStatusContainer,
  ResponseStatusMessage,
  ResponseStatusTitle,
  StyledCopyText,
} from "./styles";

export const renderErrorMessage = (errorMessage?: string | undefined | null) => {
  if (!errorMessage) return;

  return (
    <ErrorMessageBox>
      <EMIcon />
      <EMText>{getTranslatedErrorMessageFromResponse(errorMessage)}</EMText>
    </ErrorMessageBox>
  );
};

export const renderCopyButton = () => (
  <StyledCopyText id={HELP_EMAIL} copyContent={HELP_EMAIL}>
    <LinkText>{HELP_EMAIL}</LinkText>
    <CopyIcon />
    <Trans i18nKey={"buttons.copy"} />
  </StyledCopyText>
);

export const renderKycResponseSection = (
  kycResponseType: EKycResponseType,
  translationPrefix: string,
) => {
  if (!kycResponseType) return;
  const _prefix = `${translationPrefix}.verification_statuses.${kycResponseType}`;
  const iconType = {
    [EKycResponseType.SUCCESS]: EModalTypes.SUCCESS,
    [EKycResponseType.ERROR]: EModalTypes.ERROR,
    [EKycResponseType.VERIFICATION_IS_TAKING_TOO_LONG]: EModalTypes.PENDING,
    [EKycResponseType.INCORRECT_INFO]: EModalTypes.USER,
  };

  return renderResponseSection({
    title: <Trans i18nKey={`${_prefix}.title`} />,
    message: (
      <Trans
        i18nKey={`${_prefix}.message`}
        components={{
          1: renderCopyButton(),
        }}
      />
    ),
    iconType: iconType[kycResponseType],
    dataTestId: `kyc-verification-response-${kycResponseType}`,
  });
};

interface IRenderResponseSection {
  title: string | ReactNode;
  message?: string | ReactNode;
  iconType: EModalTypes;
  dataTestId?: string;
}

export const renderResponseSection = ({
  title,
  message,
  iconType,
  dataTestId,
}: IRenderResponseSection) => {
  return (
    <ResponseStatusContainer data-testid={dataTestId || ""}>
      <ModalStatusIcon type={iconType} />
      <ResponseStatusTitle>{title}</ResponseStatusTitle>
      {message && <ResponseStatusMessage>{message}</ResponseStatusMessage>}
    </ResponseStatusContainer>
  );
};

interface IRenderFooterButtonsSection {
  containerClassName?: string;
  mainButton: {
    text: ReactNode;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    type?: "button" | "submit";
    dataTestId?: string;
    className?: string;
  };
  secondaryButton?: {
    text: ReactNode;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    type?: "button" | "submit";
    dataTestId?: string;
    className?: string;
  };
}

export const renderFooterButtonsSection = ({
  mainButton,
  secondaryButton,
}: IRenderFooterButtonsSection) => {
  return (
    <BtnsWrapper>
      {secondaryButton && (
        <BackBtn
          type={secondaryButton?.type || "button"}
          id={secondaryButton?.dataTestId || ""}
          data-testid={secondaryButton?.dataTestId || ""}
          disabled={secondaryButton?.disabled}
          onClick={secondaryButton?.onClick}
          className={secondaryButton?.className || ""}
        >
          {secondaryButton?.text}
        </BackBtn>
      )}

      {mainButton && (
        <SubmitBtn
          type={mainButton.type || "button"}
          id={mainButton.dataTestId || ""}
          data-testid={mainButton.dataTestId || ""}
          disabled={mainButton.disabled}
          onClick={mainButton.onClick}
          className={mainButton?.className || ""}
        >
          {mainButton.text}
        </SubmitBtn>
      )}
    </BtnsWrapper>
  );
};

import { useEffect, useState } from "react";
import moment from "moment";

import SelectOption from "types/SelectOption";
import { openBlobAsLink, showErrorModal } from "helpers";

import {
  MonthlyStatementControllerGetAllMonthlyStatementsQueryParams,
  MonthlyStatementResponse,
  queryMonthlyStatementControllerDownloadPdf,
  queryMonthlyStatementControllerGetAllMonthlyStatements,
} from "utils/swagger_react_query";

import { getFormattedYearsSelectOptions } from "./helpers";

export const useMonthlyStatements = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [monthlyStatements, setMonthlyStatements] = useState<MonthlyStatementResponse[]>([]);
  const [availableYears, setAvailableYears] = useState<SelectOption<string>[]>([]);
  const [selectedYear, setSelectedYear] = useState<string>(moment().format("YYYY").toString());

  useEffect(() => {
    fetchMonthlyStatements();
  }, []);

  const fetchMonthlyStatements = async (year?: string) => {
    try {
      setIsLoading(true);
      const payload: MonthlyStatementControllerGetAllMonthlyStatementsQueryParams = {};
      if (year) {
        payload.year = parseInt(year);
      }
      const response = await queryMonthlyStatementControllerGetAllMonthlyStatements(payload);
      setMonthlyStatements(response.statements);
      const _availableYears = getFormattedYearsSelectOptions(response.years);
      setAvailableYears(_availableYears);
    } catch (error) {
      showErrorModal(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadClick = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await queryMonthlyStatementControllerDownloadPdf({ monthlyStatementId: id });
      openBlobAsLink(response as unknown as Blob);
    } catch (error) {
      console.log(error);
      showErrorModal(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleYearChange = (option: SelectOption<string>) => {
    setSelectedYear(option.value);
    fetchMonthlyStatements(option.value);
  };

  return {
    metadata: {
      isLoading,
    },
    data: {
      monthlyStatements,
      availableYears,
      selectedYear,
    },
    actions: {
      handleDownloadClick,
      handleYearChange,
    },
  };
};

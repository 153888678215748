import { CompanyEntityType } from "types/BETypes";

export const companyTypeSelectOptions: { label: string; value: CompanyEntityType }[] = [
  {
    label: `common.company_types.${CompanyEntityType.CORPORATION}`,
    value: CompanyEntityType.CORPORATION,
  },
  {
    label: `common.company_types.${CompanyEntityType.LLC}`,
    value: CompanyEntityType.LLC,
  },
  {
    label: `common.company_types.${CompanyEntityType.PARTNERSHIP}`,
    value: CompanyEntityType.PARTNERSHIP,
  },
  {
    label: `common.company_types.${CompanyEntityType.SOLE_PROPRIETORSHIP}`,
    value: CompanyEntityType.SOLE_PROPRIETORSHIP,
  },
  {
    label: `common.company_types.${CompanyEntityType.S_CORPORATION}`,
    value: CompanyEntityType.S_CORPORATION,
  },
];

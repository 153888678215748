import { FC } from "react";

import IconProps from "./IconProps";

const UsersGroup: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <g clip-path="url(#clip0_11533_83848)">
      <path
        d="M10 13.5C10 14.0304 10.2107 14.5391 10.5858 14.9142C10.9609 15.2893 11.4696 15.5 12 15.5C12.5304 15.5 13.0391 15.2893 13.4142 14.9142C13.7893 14.5391 14 14.0304 14 13.5C14 12.9696 13.7893 12.4609 13.4142 12.0858C13.0391 11.7107 12.5304 11.5 12 11.5C11.4696 11.5 10.9609 11.7107 10.5858 12.0858C10.2107 12.4609 10 12.9696 10 13.5Z"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 21.5V20.5C8 19.9696 8.21071 19.4609 8.58579 19.0858C8.96086 18.7107 9.46957 18.5 10 18.5H14C14.5304 18.5 15.0391 18.7107 15.4142 19.0858C15.7893 19.4609 16 19.9696 16 20.5V21.5"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 5.5C15 6.03043 15.2107 6.53914 15.5858 6.91421C15.9609 7.28929 16.4696 7.5 17 7.5C17.5304 7.5 18.0391 7.28929 18.4142 6.91421C18.7893 6.53914 19 6.03043 19 5.5C19 4.96957 18.7893 4.46086 18.4142 4.08579C18.0391 3.71071 17.5304 3.5 17 3.5C16.4696 3.5 15.9609 3.71071 15.5858 4.08579C15.2107 4.46086 15 4.96957 15 5.5Z"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17 10.5H19C19.5304 10.5 20.0391 10.7107 20.4142 11.0858C20.7893 11.4609 21 11.9696 21 12.5V13.5"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 5.5C5 6.03043 5.21071 6.53914 5.58579 6.91421C5.96086 7.28929 6.46957 7.5 7 7.5C7.53043 7.5 8.03914 7.28929 8.41421 6.91421C8.78929 6.53914 9 6.03043 9 5.5C9 4.96957 8.78929 4.46086 8.41421 4.08579C8.03914 3.71071 7.53043 3.5 7 3.5C6.46957 3.5 5.96086 3.71071 5.58579 4.08579C5.21071 4.46086 5 4.96957 5 5.5Z"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 13.5V12.5C3 11.9696 3.21071 11.4609 3.58579 11.0858C3.96086 10.7107 4.46957 10.5 5 10.5H7"
        stroke="#17AACF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11533_83848">
        <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default UsersGroup;

import moment from "moment";

import { companyTypeSelectOptions } from "constants/shared/companyTypes";
import { getStateObject } from "helpers/shared/states";

import { BusinessAccountData, BusinessInfoResDto } from "utils/swagger_react_query";

import { BusinessAccountCompanyInfoFormType } from "./components/BusinessAccountCompanyInfoForm/validationSchema";

export const convertBusinessFormDataToBusinessAccountData = (
  formData: BusinessAccountCompanyInfoFormType,
): BusinessAccountData => {
  return {
    companyName: formData.companyName,
    dateOfFoundation: moment(formData.businessFormationDate)?.toISOString(),
    formationState: formData.stateOfIncorporation.value,
    businessStructure: formData.businessStructure.value,
    phoneNumber: formData.businessPhone,
    ein: formData.ein,
    address: formData.address,
    suite: formData.suite || undefined,
    city: formData.city,
    state: formData.state.value,
    zip: formData.zip,
  };
};

export const getBusinessInfoFormDataInitValues = (
  data: BusinessInfoResDto | null,
): BusinessAccountCompanyInfoFormType => {
  const businessStructure = companyTypeSelectOptions[0];

  return {
    companyName: data?.name || "",
    operatingName: "",
    businessFormationDate: moment().toDate(),
    stateOfIncorporation: getStateObject(),
    businessStructure: businessStructure,
    businessPhone: "",
    ein: data?.ein || "",
    address: data?.address || "",
    city: data?.city || "",
    state: getStateObject(data?.state),
    zip: data?.zip || "",
  };
};

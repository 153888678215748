import { FC } from "react";
import { Trans } from "react-i18next";
import { PlusThin } from "assets/svg";

import { UserRole } from "types/BETypes";
import DashboardLayout from "layouts/DashboardLayout";
import { BankingDisclosureContainer } from "pages/Banking/shared/styles";
import BankingDisclosureComponent from "components/BankingDisclosureComponent";
import CompanyBankAccountCreationModal, {
  hasAdminFinishedSetup,
  hasEmployeeFinishedSetup,
} from "components/CompanyBankAccountComponents/CompanyBankAccountCreationModal";
import FinishCompanyBankAccountSetupBanner from "components/CompanyBankAccountComponents/FinishCompanyBankAccountSetupBanner";

import { FullScreenLoader } from "uikit";

import useBankingPage from "./useBankingPage";
import { AddAccountButton } from "./styles";

interface IProps {}

const BankingPage: FC<IProps> = () => {
  const noStateTranslationPrefix = `components.payment_methods.no_account_state`;

  const {
    isLoading,
    requestsLoading,
    currentUser,
    currentCompany,
    isCreationCompanyBankAccountModalOpen,
    cardsList,
    factorsData,
    userHasCompanyAccount,
    userIsEmployee,
    handleAddAccount,
    renderContent,
    setIsCreationCompanyBankAccountModalOpen,
    refetchData,
  } = useBankingPage();

  const globalLoading = isLoading || requestsLoading;

  const shouldShowFinishSetupBanner = () => {
    if (currentUser?.lastActiveRole === UserRole.EMPLOYEE) {
      return !globalLoading && hasEmployeeFinishedSetup(currentUser, cardsList, factorsData);
    } else {
      return !globalLoading && hasAdminFinishedSetup(currentCompany, factorsData);
    }
  };
  return (
    <DashboardLayout
      titleRightContent={
        <>
          {!requestsLoading && !userHasCompanyAccount && userIsEmployee && (
            <AddAccountButton
              onClick={handleAddAccount}
              data-testid="banking-page-create-company-account-button"
            >
              <PlusThin />
              <Trans i18nKey={`${noStateTranslationPrefix}.create_account_button`} />
            </AddAccountButton>
          )}
        </>
      }
    >
      <>
        {globalLoading && <FullScreenLoader />}

        {shouldShowFinishSetupBanner() && (
          <FinishCompanyBankAccountSetupBanner onContinueSetupClick={handleAddAccount} />
        )}

        {renderContent()}

        {!requestsLoading && (
          <BankingDisclosureContainer>
            <BankingDisclosureComponent />
          </BankingDisclosureContainer>
        )}
      </>

      <CompanyBankAccountCreationModal
        isOpen={isCreationCompanyBankAccountModalOpen}
        setIsOpen={setIsCreationCompanyBankAccountModalOpen}
        onFinished={refetchData}
        refetchPageContentCallback={refetchData}
        data={{
          bankCardsList: cardsList || [],
        }}
      />
    </DashboardLayout>
  );
};

export default BankingPage;

import styled from "styled-components";

import { breakpoint } from "helpers";

import { ButtonsContainer, NoBorderPrimaryButton, SectionAlt, StepIndicator, UIModal } from "uikit";
import { PrimaryButtonIconed, SecondaryButton } from "uikit";
import { Container as ModalContentContainer } from "uikit/Modal/styles";

import { ResponseStatusContainer } from "../components/styles";

export const StyledUIModal = styled(UIModal)`
  width: 1100px !important;
  text-align: left;
  overflow: visible !important;

  &.small {
    width: 862px !important;
  }

  ${ModalContentContainer} {
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const MFAWidgetContainer = styled(SectionAlt)`
  ${ModalContentContainer} {
    background: transparent;
    text-align: center;
    padding-bottom: 0;
  }
`;

export const ModalHeader = styled.div`
  margin-bottom: 10px;
`;

export const StyleStepIndicator = styled(StepIndicator)`
  margin-bottom: 32px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

export const BtnsWrapper = styled(ButtonsContainer)`
  margin-top: 32px;

  &.center {
    justify-content: center;
  }

  ${breakpoint("xs", "md")`
    flex-direction: column-reverse;
		justify-content: flex-start;
  `}
`;

export const ButtonsGroup = styled.div`
  display: flex;
`;

export const SubmitBtn = styled(PrimaryButtonIconed)`
  width: auto;

  ${breakpoint("xs", "md")`
    width: 100%;
    max-width: 100%;
  `}
`;

export const SkipBtn = styled(NoBorderPrimaryButton)``;

export const BackBtn = styled(SecondaryButton)`
  width: 90px;
  margin-right: 24px;

  ${breakpoint("xs", "md")`
    margin-top: 16px;
    width: 100%;
    max-width: 100%;
  `}
`;

export const BankAccountSetupCompletedSection = styled.div`
  ${ResponseStatusContainer} {
    background-color: ${({ theme }) => theme.colors.contentBg};
  }
`;

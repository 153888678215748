import { useCallback, useState } from "react";
import { debounce, isEmpty } from "lodash";

import { ErrorConst } from "types/BETypes";
import { getErrorMessageFromResponse } from "helpers/shared/errors";
import {
  convertGetUserIdsByEmailResDtoToUserResponseDto,
  convertUserPersonalInfoResDtoToUserResponseDto,
} from "helpers/shared/userData";

import {
  mutationUsersControllerGetUserIdsByEmail,
  queryUsersControllerGetPersonalInfo,
  UserResponseDto,
} from "utils/swagger_react_query";

export const useAsyncUserSearchInput = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState<Partial<UserResponseDto>[]>([]);
  const [selectedUser, setSelectedUser] = useState<Partial<UserResponseDto> | null>(null);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [warningMessage, setWarningMessage] = useState<string | null>(null);

  const handleFetchData = useCallback(
    debounce(async (value: string) => {
      try {
        setIsLoading(true);
        if (!value) {
          setSearchResult([]);
          setErrorMessage(null);
          return;
        }
        const response = await mutationUsersControllerGetUserIdsByEmail()({ email: value || "" });

        if (isEmpty(response) && searchValue) {
          setWarningMessage(ErrorConst.COMPANY_STRUCTURE_SEARCH_USER_NOT_VERIFIED);
          return;
        }
        const serializedResponse = response.map((item) =>
          convertGetUserIdsByEmailResDtoToUserResponseDto(item),
        );
        setSearchResult(serializedResponse);
      } catch (error) {
        setErrorMessage(getErrorMessageFromResponse(error));
      } finally {
        setIsLoading(false);
      }
    }, 500),
    [],
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);

    handleFetchData(value);
  };

  const handleSelectOption = async (option: Partial<UserResponseDto>) => {
    try {
      setIsLoading(true);
      const res = await queryUsersControllerGetPersonalInfo({ userId: option?.userId || "" });
      const serializedRes = convertUserPersonalInfoResDtoToUserResponseDto(res);
      setSelectedUser(serializedRes);
      setSearchValue(option?.email || "");
    } catch (error) {
      setErrorMessage(getErrorMessageFromResponse(error));
    } finally {
      setIsLoading(false);
    }
  };

  const cleanUpStates = () => {
    setSearchValue("");
    setSearchResult([]);
    setSelectedUser(null);
    setErrorMessage(null);
    setWarningMessage(null);
  };

  return {
    metadata: {
      isLoading,
      errorMessage,
      warningMessage,
      cleanUpStates,
    },
    actions: {
      handleSearch,
      handleSelectOption,
    },
    data: {
      searchValue,
      searchResult,
      selectedUser,
    },
  };
};

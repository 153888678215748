import { hasAdminRights } from "permissions/helpers/shared";

import { UserProfileResponseDto } from "utils/swagger_react_query";

export const hasBankingManagerRights = (user?: UserProfileResponseDto | null): boolean => {
  if (!user) {
    return false;
  }
  return hasAdminRights(user) && !!user.businessStructurePerson?.isKeyManager;
};

export const hasIndividualOwnerRights = (user?: UserProfileResponseDto | null): boolean => {
  if (!user) {
    return false;
  }

  return hasAdminRights(user) && !!user.businessStructurePerson?.isOwner;
};

export const hasBusinessOwnerRights = (user?: UserProfileResponseDto | null): boolean => {
  if (!user) {
    return false;
  }

  return hasAdminRights(user) && !!user.businessStructurePerson?.isKeyManagerHoldingCompany;
};

export const hasAdvancedAdminBankingRights = (user?: UserProfileResponseDto | null): boolean => {
  if (!user) {
    return false;
  }

  return (
    hasAdminRights(user) &&
    (hasBankingManagerRights(user) ||
      hasIndividualOwnerRights(user) ||
      hasBusinessOwnerRights(user))
  );
};
